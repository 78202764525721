import { computed, ref, unref } from 'vue'
import download from 'downloadjs'

export default function useAttachments(srcRef: string | Ref<string>) {
  const nuxtApp = useNuxtApp()

  const downloading = ref(false)

  const internalSrc = computed(() => {
    const src = unref(srcRef)
    return src?.toLowerCase() || ''
  })

  const isImage = computed(() => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(internalSrc.value)
  })

  const isPDF = computed(() => {
    return internalSrc.value.endsWith('pdf')
  })

  const internalDownload = async (name: string) => {
    downloading.value = true
    try {
      const file = await fetch(unref(srcRef), { cache: 'no-cache' }).then(response => response.blob())

      download(file, name)
    }
    catch (error) {
      nuxtApp.$error.report(error)
      nuxtApp.$toast.error('Failed to download file')
    }
    downloading.value = false
  }

  return {
    download: internalDownload,
    downloading,
    isImage,
    isPDF,
  }
}
