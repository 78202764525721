<template>
  <order-summary-card
    v-bind="$attrs"
    v-model:freight-cost-code="revision.freight_cost_code"
    :attachments="invoice.attachments"
    :comments="invoice.comments"
    comments-title="Invoice comments"
    :contact="invoice.contact"
    :delivery-price="deliveryPrice"
    :freight-tax="revision.freight_tax"
    :items="items"
    :ordered-by="order.owner"
    :pickup-price="pickupPrice"
    :project="order.jobsite"
    :readonly="isApproved"
    :readonly-items="readonlyItems"
    :reference-name="order.reference_name"
    :summary-items="summaryItems"
    :supplier="invoice.vendor"
    :title="`Invoice ${invoice.invoice_number}`"
    :total="totalPrice"
    :total-tax="totalTax"
    @preview="$emit('preview', $event)"
  >
    <template v-slot:title-append>
      <invoice-status v-if="!order.date_cancelled" :invoice="invoice" />
    </template>
    <template v-slot:reference-name>
      <nuxt-link class="text-decoration-none" :to="{ name: 'orders-id', params: { id: order.id } }">
        {{ order.reference_name }}
      </nuxt-link>
    </template>
    <template v-if="!invoice.date_cancelled" v-slot:actions>
      <invoice-accounting-status v-if="isIntegrated" :invoice="invoice" />
      <slot name="actions-prepend" />
      <v-tooltip
        v-if="canApprove && !order.date_cancelled && !isApproved"
        class="text-center"
        :disabled="blockingErrors.length === 0"
        location="bottom"
        max-width="350"
      >
        <template v-slot:activator="{ props: activator }">
          <span v-bind="activator">
            <qtm-btn
              :disabled="blockingErrors.length > 0"
              :loading="loading"
              @click="approve"
            >
              Approve
            </qtm-btn>
          </span>
        </template>
        {{ blockingErrors[0]?.msg }}
      </v-tooltip>
      <v-menu v-if="canCancel" content-class="qtm-border">
        <template v-slot:activator="{ props }">
          <qtm-icon-btn
            v-bind="props"
            class="ml-1"
            color="primary"
            data-test="invoice-menu"
            icon="mdi-dots-horizontal"
            rounded="0"
            variant="outlined"
          />
        </template>

        <v-list class="py-0">
          <v-list-item class="py-0 px-6" :to="{ name: 'invoices-id-edit', params: { id: invoice.id } }">
            <v-list-item-title class="text-dark-grey">
              Edit
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="py-0 px-6" @click="showCancelConfirmation = true">
            <v-list-item-title class="text-dark-grey" data-test="cancel-button">
              Cancel Invoice
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:summary-item-po>
      <span v-if="!poIssued" class="text-mid-grey">
        No PO Issued
      </span>
      <span v-else class="cursor-pointer text-interactive" @click="$emit('po-click')">
        {{ po.po_number }}
      </span>
    </template>
    <template v-slot:supplier-append>
      <qtm-icon-btn
        v-if="canEmailSupplier"
        class="ml-3"
        icon="mdi-email-edit-outline"
        title="Email Supplier"
        @click="sendSupplierEmail = true"
      />
    </template>
    <template v-slot:bottom-append>
      <div class="w-100">
        <comment-container
          class="mb-2"
          content-type="invoice"
          :content-id="invoice.id"
          :mentionable-users="projectUsers"
          @preview="$emit('preview', $event)"
        />
      </div>
    </template>
  </order-summary-card>
  <send-supplier-email v-model="sendSupplierEmail" :invoice="invoice" :order="order" :po="po" />
  <confirmation-dialog
    v-model="showCancelConfirmation"
    cancel-button-text="Close"
    :loading="loading"
    ok-button-text="Cancel Invoice"
    title="Cancel Invoice"
    @confirm="cancel"
  >
    Are you sure you want to cancel this invoice?
  </confirmation-dialog>
</template>

<script>
import { mapState } from 'pinia'
import { dateYear } from '~/models/filters'
import CommentContainer from '@/components/comments/comment-container.vue'
import ConfirmationDialog from '@/components/confirmation-dialog.vue'
import InvoiceAccountingStatus from '@/components/invoices/invoice-accounting-status.vue'
import InvoiceStatus from '@/components/invoices/invoice-status.vue'
import OrderSummaryCard from '@/components/orders/order-summary-card.vue'
import SendSupplierEmail from '@/components/invoices/send-supplier-email.vue'

export default {
  name: 'invoice-card',
  components: {
    CommentContainer,
    ConfirmationDialog,
    InvoiceAccountingStatus,
    InvoiceStatus,
    OrderSummaryCard,
    SendSupplierEmail,
  },
  props: {
    blockingErrors: {
      type: Array,
      default: () => [],
    },
    canApprove: {
      type: Boolean,
      default: false,
    },
    invoice: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    po: {
      type: Object,
      default: undefined
    },
  },
  emits: ['approved', 'cancelled', 'po-click', 'preview'],
  data() {
    return {
      items: [],
      loading: false,
      projectUsers: [],
      sendSupplierEmail: false,
      showCancelConfirmation: false,
    }
  },
  computed: {
    ...mapState(useAuthStore, ['isStaff']),
    canCancel() {
      return (this.canApprove || this.isStaff) && !this.invoice?.date_approved
    },
    canEmailSupplier() {
      return !this.isApproved && this.invoice?.contact?.email
    },
    deliveryPrice() {
      return this.revision.delivery_charge
    },
    isApproved() {
      return !!this.invoice?.date_approved
    },
    isIntegrated() {
      return this.invoice.accounting_success || this.invoice.accounting_success === false
    },
    pickupPrice() {
      return this.revision.pickup_charge
    },
    poIssued() {
      return this.po && !this.order.requiresAdjustments && !this.order.requiresInvoiceApproval
    },
    readItemsFromInvoice() {
      return !!this.invoice.line_items.length
    },
    readonlyItems() {
      return this.poIssued ? this.items.filter(item => item.reference_identifier).map(item => item.id) : []
    },
    revision() {
      return this.readItemsFromInvoice ? this.invoice.revisions[0] : (this.po?.latest_change_order || {})
    },
    summaryItems() {
      let dateItem = {
        label: 'Due Date',
        value: dateYear(this.invoice.date_due),
      }

      if (this.invoice.date_cancelled) {
        dateItem = {
          label: 'Date Cancelled',
          value: dateYear(this.invoice.date_cancelled),
        }
      }

      return [
        {
          label: 'Sent Date',
          value: dateYear(this.invoice.date_issued),
        },
        dateItem,
        {
          label: 'PO',
        }
      ]
    },
    totalPrice() {
      if (this.readItemsFromInvoice) {
        return this.invoice.total_price
      }

      return this.po?.total_price
    },
    totalTax() {
      if (this.readItemsFromInvoice) {
        return this.invoice.total_tax
      }

      return this.po?.total_tax
    },
  },
  mounted() {
    this.fetchTeam()

    if (this.readItemsFromInvoice) {
      this.items = this.invoice.line_items
    }
    else {
      this.items = this.po?.poskus || []
    }
  },
  methods: {
    async approve() {
      if (this.order.state.can_approve_invoice) {
        this.loading = true
        try {
          await this.$api.v1.invoices.patch(
            this.invoice.id,
            {
              revision: {
                freight_cost_code: this.freightCostCode,
                poskus: this.items
              }
            }
          )
          await this.$api.v1.invoices.approve(this.invoice.id)
          this.$emit('approved', this.order)
          this.$toast.success('Invoice approved')
        }
        catch (error) {
          this.$error.report(error)
        }
        this.loading = false
      }
      else {
        if (this.items.some(item => !item.cost_code) || this.freightCostCodeRequired) {
          this.$toast.error('Cost codes are required')
          return
        }

        this.loading = true
        try {
          await this.$api.v1.invoices.patch(this.invoice.id, {
            revision: {
              freight_cost_code: this.freightCostCode,
              poskus: this.items
            }
          })

          if (this.po) {
            await this.$api.v1.purchaseOrders.update(
              this.po.id,
              {
                latest_change_order: {
                  freight_cost_code: this.freightCostCode,
                  poskus: this.items.map(item => ({
                    ...item,
                    id: this.po?.poskus.find(i => i.internal_identifier === item.reference_identifier)?.id,
                  }))
                }
              }
            )
          }

          const order = await this.$api.v1.rfqs.transition({ id: this.order.id }, 'invoice_approved')
          this.$emit('approved', order)
          this.$toast.success('Invoice approved')
        }
        catch (error) {
          this.$error.report(error)
        }
        this.loading = false
      }
    },
    async cancel() {
      this.loading = true
      try {
        await this.$api.v1.invoices.cancel(this.invoice.id)
        this.$emit('cancelled')
        this.$toast.success('Invoice cancelled')
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    async fetchTeam() {
      try {
        this.projectUsers = await this.$api.v1.jobsites.team(this.order.jobsite.id)
      }
      catch (error) {
        this.$error.report(error)
      }
    },
  }
}
</script>
