<template>
  <qtm-dialog-card v-bind="$attrs" max-width="675" :title="title" width="675">
    <div class="mb-6 qtm-body text-secondary">
      Fill in the information below and click add. Your new supplier will be added.
    </div>
    <vendor-form
      ref="vendorForm"
      :country-code="countryCode"
      :details-required="vendorDetailsRequired"
      :vendor="vendor"
    />
    <template v-if="withContact">
      <v-divider class="mb-4 mt-6" />
      <div class="qtm-h2 text-secondary">
        Contact Info
      </div>
      <contact-form
        ref="contactForm"
        :contact="contact"
        :email-required="emailRequired"
        :phone-required="phoneRequired"
        :vendor="vendor"
      />
    </template>
    <template v-slot:actions>
      <v-spacer />
      <qtm-btn :loading="loading" size="x-large" tertiary @click="$emit('update:model-value', false)">
        Cancel
      </qtm-btn>
      <qtm-btn class="my-3" :loading="loading" size="x-large" @click="saveAndClose">
        Add
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script>
import ContactForm from '@/components/vendors/contact-form.vue'
import VendorForm from '@/components/vendors/vendor-form.vue'

export default {
  name: 'create-vendor-dialog',
  components: { ContactForm, VendorForm },
  props: {
    countryCode: {
      type: String,
      required: false,
      default: 'CA',
    },
    emailRequired: {
      type: Boolean,
      default: false
    },
    phoneRequired: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Add a New Supplier'
    },
    vendorDetailsRequired: {
      type: Boolean,
      default: false
    },
    withContact: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:model-value', 'vendor-created'],
  data() {
    return {
      contact: {
        first_name: '',
        last_name: '',
        email: '',
        phone_extension: '',
        phone_number: '',
      },
      loading: false,
      vendor: {
        name: '',
        address: '',
        city: '',
        province: '',
        postal_code: '',
        country: this.countryCode,
      },
    }
  },
  methods: {
    async clean() {
      this.contact = {
        first_name: '',
        last_name: '',
        email: '',
        phone_extension: '',
        phone_number: '',
      }
      this.vendor = {
        name: '',
        address: '',
        city: '',
        province: '',
        postal_code: '',
        country: this.countryCode,
      }

      // Wait for the cleared objects to propagate to the forms before resetting validation
      await this.$nextTick()
      this.$refs.vendorForm.resetValidation()

      if (this.$refs.contactForm) {
        this.$refs.contactForm.resetValidation()
      }
    },
    isValid() {
      let valid = this.$refs.vendorForm.isValid()

      if (this.$refs.contactForm) {
        const contactValid = this.$refs.contactForm.isValid()
        valid = valid && contactValid
      }

      return valid
    },
    async saveAndClose() {
      if (!this.isValid()) {
        return
      }
      const data = { ...this.vendor }

      if (this.withContact) {
        data.contacts = [this.contact]
      }

      this.loading = true
      try {
        const vendor = await this.$api.v1.vendors.create(data)

        vendor.isNew = true
        this.$emit('vendor-created', vendor)
        this.clean()
        this.$emit('update:model-value', false)
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
  },
}
</script>
