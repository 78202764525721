<template>
  <v-combobox
    ref="input"
    v-bind="$attrs"
    :bg-color="backgroundColor"
    clear-icon="mdi-close"
    color="interactive"
    item-color="interactive"
    :item-title="itemTitle"
    :menu-icon="dropdownIcon"
    :menu-props="menuProps"
    single-line
    :variant="variant"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
      <slot v-bind="slotData" :key="name" :name="name" />
    </template>
  </v-combobox>
</template>

<script setup lang="ts">
export interface Props {
  backgroundColor?: string
  itemTitle?: string | ((item: any) => any)
  variant?: 'outlined' | 'plain'
}
withDefaults(defineProps<Props>(), {
  backgroundColor: 'white',
  itemTitle: 'text',
  variant: 'outlined',
})

const $attrs: { items?: any[], 'menu-props'?: object } = useAttrs()
const dropdownIcon = computed(() => ($attrs.items?.length ? 'mdi-chevron-down' : ''))
const menuProps = computed(() => ({ contentClass: 'qtm-border', maxHeight: 300, ...$attrs['menu-props'] }))

const input = ref<any>(null)
const focus = () => input.value?.focus()

defineExpose({ focus })
</script>
