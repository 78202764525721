<template>
  <qtm-autocomplete
    v-bind="$attrs"
    v-model="contactProxy"
    clearable
    data-test="contact-select"
    hide-details="auto"
    :items="items"
    placeholder="Search Existing Contacts"
    return-object
  >
    <template v-slot:item="{ item, props: itemProps}">
      <v-list-item
        v-bind="itemProps"
        :class="{ 'internal-item': item?.raw?.internalDistribution }"
        :data-test="item?.raw?.internalDistribution ? 'internal-distribution' : undefined"
      />
    </template>
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
      <slot v-bind="slotData" :key="name" :name="name" />
    </template>
  </qtm-autocomplete>
</template>

<script setup lang="ts">
interface Contact {
  id: number | string
  first_name?: string
  email?: string
  internalDistribution?: boolean
  last_name?: string
  text?: string
}

export interface Props {
  allowInternal?: boolean
  contact?: Contact | null
  contacts?: Contact[]
  internalDistribution?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  allowInternal: false,
  contact: null,
  contacts: () => [],
  internalDistribution: false,
})
const emit = defineEmits(['update:contact', 'update:internal-distribution'])

const internalDistributionItem: Contact = {
  id: 'internal-distribution',
  text: 'Internal PO Distribution Only',
  internalDistribution: true,
}

const contactProxy = computed<Contact | null>({
  get() {
    if (props.internalDistribution) {
      return internalDistributionItem
    }

    if (props.contact) {
      return {
        ...props.contact,
        text: `${props.contact.first_name} ${props.contact.last_name} (${props.contact.email})`
      }
    }

    return null
  },
  set(value: Contact | null) {
    if (value?.internalDistribution) {
      emit('update:contact', null)
      emit('update:internal-distribution', true)
    }
    else {
      emit('update:contact', value)
      emit('update:internal-distribution', false)
    }
  },
})
const items = computed<Contact[]>(() => {
  const itemsWithText = props.contacts.map((c) => ({ ...c, text: `${c.first_name} ${c.last_name} (${c.email})` }))

  if (props.allowInternal) {
    return itemsWithText.concat([internalDistributionItem as any])
  }

  return itemsWithText
})
</script>

<style lang="scss">
.internal-item .v-list-item-title {
  font-weight: bold !important;
}
</style>
