<template>
  <div v-if="config" class="d-flex pa-2 bg-background rounded-sm" :class="{ fit }">
    <v-icon class="mr-2" :color="config.color" :icon="config.icon" />
    <div class="text-mid-dark-grey" v-text="config.text" />
  </div>
</template>

<script setup lang="ts">
import type { Quote } from '@quotetome/materials-api'

export interface Props {
  fit?: boolean
  quote: Quote
}

const props = defineProps<Props>()

const config = computed(() => {
  if (!props.quote.delivery_available) {
    return { color: 'error', icon: 'mdi-close-circle', text: 'Delivery Unavailable' }
  }

  if (props.quote.delivery_date) {
    return { color: 'primary', icon: 'mdi-alert-circle', text: "Supplier's delivery time differs from requested" }
  }

  return null
})
</script>

<style lang="scss" scoped>
.fit {
  width: fit-content;
}
</style>
