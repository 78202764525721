<template>
  <v-dialog
    v-bind="$attrs"
    fullscreen
    hide-overlay
    no-click-animation
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card v-if="quote" class="non-scrollable-container">
      <v-toolbar color="secondary" dark>
        <v-btn dark icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ quote.vendor.name }}</v-toolbar-title>
      </v-toolbar>

      <div class="d-flex non-scrollable-container">
        <div v-if="showAttachment" class="attachmet-viewer">
          <attachment-viewer
            ref="pdfViewer"
            class="pa-2 mr-2"
            select-text-layer
            :src="documentToPreviewSrc"
            :name="documentToPreviewName"
            @close="showAttachment = false"
          />
        </div>
        <project-information v-else class="project-information" :order="order" />
        <v-card-text class="scrollable-container">
          <quote-form
            ref="quoteForm"
            change-supplier
            class="quote-form"
            :order="order"
            :quote="quote"
            @preview="showDocumentPreview($event)"
          >
            <v-row class="pa-5" justify="end">
              <qtm-btn :loading="loading" tertiary size="x-large" @click="close">
                Cancel
              </qtm-btn>
              <qtm-btn class="mx-3" :loading="loading" secondary size="x-large" @click="save">
                Save
              </qtm-btn>
              <qtm-btn :loading="loading" size="x-large" @click="save({ close: true })">
                Save and Close
              </qtm-btn>
            </v-row>
          </quote-form>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import AttachmentViewer from '@/components/attachments/attachment-viewer.vue'
import ProjectInformation from '@/components/admin/project-information.vue'
import QuoteForm from '@/components/quotes/quote-form.vue'

export default {
  name: 'enter-quote-form',
  components: { AttachmentViewer, ProjectInformation, QuoteForm },
  props: {
    quote: {
      type: Object,
      default: undefined
    },
    order: {
      type: Object,
      default: undefined
    },
  },
  emits: ['update:model-value'],
  setup() {
    const adminStore = useAdminStore()

    return {
      adminStore,
    }
  },
  data() {
    return {
      documentToPreviewSrc: null,
      documentToPreviewName: null,
      loading: false,
      showAttachment: false,
    }
  },
  methods: {
    close() {
      this.showAttachment = false
      this.$emit('update:model-value', false)
    },
    async isValid() {
      this.$refs.quoteForm.clear()
      await this.$nextTick()

      const valid = this.$refs.quoteForm.isValid()

      if (!valid) {
        this.$toast.error('Please correct the errors')
      }

      return valid
    },
    async save({ close = false } = {}) {
      if (!(await this.isValid())) {
        return
      }

      const quoteData = { ...this.quote.getSubmitData(), outdated: this.quote.outdated }

      this.loading = true
      try {
        await this.$api.v1.quotes.patch(this.quote.id, quoteData)
        const updatedOrder = await this.$api.v1.rfqs.get(this.order.id)
        this.$toast.success('Quote saved')
        this.adminStore.updateOrder(updatedOrder)

        if (close) {
          this.close()
        }
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    showDocumentPreview(attachment) {
      this.documentToPreviewName = attachment.name
      this.documentToPreviewSrc = attachment.url
      this.showAttachment = true
    },
  }
}
</script>

<style lang="scss" scoped>
.attachmet-viewer {
  flex-shrink: 0;
  margin-top: 30px;
  overflow-y: auto;
  width:48%;
}

.non-scrollable-container {
  height: 100%;
  overflow-y: hidden;
}

.project-information {
  flex-shrink: 0;
  margin: 1rem 0 0 0;
  max-height: calc(100vh - 5rem);
  max-width: 20rem;
  overflow-y: auto;
  padding: 5px 10px;
}

.quote-form {
  max-width: calc(100vw - 22rem);
  overflow-y: auto;
}

.scrollable-container {
  overflow-y: auto;
}
</style>
