export default function useTaxes(companyId?: number) {
  const nuxtApp = useNuxtApp()
  const taxes = ref<any[]>([])
  const fetchTaxes = (id: number) => nuxtApp.$api.v1.companies.taxes(id)
    .then(data => { taxes.value = data })
    .catch(nuxtApp.$error.report)

  if (companyId) {
    onMounted(() => { fetchTaxes(companyId) })
  }

  return { fetchTaxes, taxes }
}
