<template>
  <div>
    <order-attachments
      v-model="quote.attachments"
      content-type="rfqs.quote"
      :object-id="quote.id"
      remove-title
    >
      <template v-slot:action="{ attachment }">
        <slot name="action" :attachment="attachment" />
      </template>
    </order-attachments>
  </div>
</template>

<script>
import OrderAttachments from '@/components/orders/order-attachments.vue'

export default {
  name: 'quote-attachments',
  components: { OrderAttachments },
  props: {
    quote: {
      type: Object,
      required: true
    },
  },
}
</script>

<style lang="scss" scoped>
.attachment-table {
  width: 100%;
}

.attachment-table thead {
  background-color: rgb(var(--v-theme-background));
  margin: 0;
}
</style>
