<template>
  <div class="bg-white">
    <v-alert v-if="loading" color="white" height="58" variant="outlined" />
    <v-alert v-else-if="projectAuthorities.length" type="success" variant="outlined">
      <div class="text-dark-grey">
        <b>Project Authority:</b> {{ projectAuthorities }}
      </div>
    </v-alert>
    <v-alert v-else-if="project" type="error" variant="outlined">
      <div class="text-dark-grey">
        <b>No project authority:</b> Go to
        <nuxt-link :to="{ name: 'projects-id', params: { id: project.id } }">
          project page
        </nuxt-link>
        to set a project authority
      </div>
    </v-alert>
  </div>
</template>

<script setup lang="ts">
import type { Jobsite } from '@quotetome/materials-api'

export interface Props {
  project?: Jobsite | null
}

const props = defineProps<Props>()

const loading = ref(false)
const team = ref<any[]>([])

const projectAuthorities = computed(() => team.value.filter(entry => entry.role === 3)
  .map(entry => `${entry.user.first_name} ${entry.user.last_name}`).join(', '))

const { $api, $error } = useNuxtApp()
const fetchTeam = async () => {
  loading.value = true
  try {
    team.value = await $api.v1.jobsites.team(props.project?.id as number)
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

watchEffect(() => {
  if (props.project) {
    fetchTeam()
  }
})
</script>
