<template>
  <form>
    <qtm-input-label label="BoM Name">
      <qtm-text-field hide-details :model-value="title" @update:model-value="$emit('update:title', $event)" />
    </qtm-input-label>
    <qtm-input-label class="mt-4" label="Project">
      <jobsite-select
        hide-details
        :model-value="project"
        :user="user"
        @update:model-value="$emit('update:project', $event)"
      />
    </qtm-input-label>
  </form>
</template>

<script setup lang="ts">
import type { Jobsite } from '@quotetome/materials-api'
import JobsiteSelect from '@/components/jobsites/jobsite-select.vue'

export interface Props {
  title: string
  project?: Jobsite
}
defineProps<Props>()
defineEmits(['update:project', 'update:title'])

const authStore = useAuthStore()
const user = authStore.user
</script>
