<template>
  <v-radio-group v-model="localValue" class="mt-0" hide-details>
    <qtm-radio
      v-for="(option, i) in deliveryRequiredOptions"
      :key="i"
      :data-test="option.dataTest"
      :label="option.label"
      :true-value="option.value"
    />
  </v-radio-group>
</template>

<script>
import { DELIVERY_REQUIRED_VALUES } from '~/constants'

export default {
  name: 'order-delivery-required-radio-group',
  props: {
    date: {
      type: Object,
      default: undefined
    },
    pickup: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String,
      required: true
    }
  },
  emits: ['update:date', 'update:model-value', 'update:pickup'],
  computed: {
    deliveryRequiredOptions() {
      return [
        {
          label: 'We need it delivered',
          value: 'delivery',
        },
        {
          label: "We'll pick it up",
          value: 'pickup',
        },
        {
          dataTest: 'order-delivery-required-na',
          label: 'Not applicable',
          value: 'na',
        },
      ]
    },
    dateRequiredValue() {
      return DELIVERY_REQUIRED_VALUES.find(value => value === this.modelValue) || '-'
    },
    localValue: {
      get() {
        if (this.modelValue === 'na' || (this.modelValue === 'cp' && !this.date)) {
          return 'na'
        }

        if (this.pickup) {
          return 'pickup'
        }

        return 'delivery'
      },
      set(value) {
        if (value === 'na') {
          this.$emit('update:model-value', value)
          this.$emit('update:date', null)
        }
        else {
          this.$emit('update:model-value', this.dateRequiredValue)
        }

        this.$emit('update:pickup', value === 'pickup')
      }
    },
  }
}
</script>
