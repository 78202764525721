<template>
  <qtm-content-block collapsible title="Supplier">
    <slot name="prepend" />
    <v-row no-gutters>
      <v-col cols="12" sm="8" lg="6">
        <qtm-input-label class="mb-4" label="Supplier Name" required>
          <supplier-search
            :append-items="appendVendors"
            clearable
            :country="country"
            :disabled="disabled || readonlySupplier"
            :error-messages="vendorError"
            menu-icon=""
            :model-value="supplier"
            :user="user"
            @update:model-value="selectVendor"
          >
            <template v-slot:append-item="{ search }">
              <v-list-item v-if="search" class="qtm-label text-interactive" @click="createVendor = true">
                <v-icon class="mt-n1 mr-2">
                  mdi-plus
                </v-icon>
                Add New
              </v-list-item>
            </template>
          </supplier-search>
        </qtm-input-label>
      </v-col>
      <v-col v-if="supplier" cols="12" sm="4" lg="6">
        <qtm-input-label class="mb-4 mb-sm-0 ml-sm-4" label="Address">
          <div v-text="supplier.address" />
          <div>
            <qtm-address :city="supplier.city" :postal-code="supplier.postal_code" :province="supplier.province" />
          </div>
        </qtm-input-label>
      </v-col>
    </v-row>

    <v-row v-if="!noContact" no-gutters>
      <v-col cols="12" sm="8" lg="6">
        <qtm-input-label label="Supplier Contact" required>
          <purchase-order-contact-select
            :allow-internal="allowInternal"
            :contact="contact"
            :contacts="allContacts"
            :disabled="disabled"
            :error-messages="contactError"
            :internal-distribution="internalDistribution"
            :loading="loading"
            @update:contact="$emit('update:contact', $event)"
            @update:internal-distribution="$emit('update:internal-distribution', $event)"
          >
            <template v-slot:append-item>
              <v-list-item class="qtm-label text-interactive" @click="createContact = true">
                <v-icon class="mt-n1 mr-2">
                  mdi-plus
                </v-icon>
                Add New
              </v-list-item>
            </template>
          </purchase-order-contact-select>
        </qtm-input-label>
      </v-col>
      <v-col cols="12" sm="4" lg="6">
        <slot />
      </v-col>
    </v-row>
    <create-vendor-dialog
      v-model="createVendor"
      :country="country"
      vendor-details-required
      @vendor-created="vendorCreated"
    />
    <create-contact-dialog v-model="createContact" email-required emit-only @new-contact="addContact" />
  </qtm-content-block>
</template>

<script setup lang="ts">
import { required, requiredIf } from '@vuelidate/validators'
import type { Contact, Vendor } from '@quotetome/materials-api'
import useValidation from '@/composables/validation'
import CreateContactDialog from '@/components/vendors/create-contact-dialog.vue'
import CreateVendorDialog from '@/components/vendors/create-vendor-dialog.vue'
import PurchaseOrderContactSelect from '@/components/purchase-orders/purchase-order-contact-select.vue'
import SupplierSearch from '@/components/search/supplier-search.vue'

export interface Props {
  allowInternal?: boolean
  contact?: Contact | null
  contactRequired?: boolean
  country?: string
  disabled?: boolean
  internalDistribution?: boolean
  noContact?: boolean
  readonlySupplier?: boolean
  supplier?: Vendor | null
  user?: number
}

const props = withDefaults(defineProps<Props>(), {
  allowInternal: false,
  contact: null,
  contactRequired: true,
  country: undefined,
  disabled: false,
  internalDistribution: false,
  readonlySupplier: false,
  supplier: null,
  user: undefined,
})

const emit = defineEmits(['supplier-created', 'update:contact', 'update:internal-distribution', 'update:supplier'])

const { errors, v$ } = useValidation({
  state: toRefs(props),
  rules: {
    contact: { required: requiredIf(() => props.contactRequired && !props.internalDistribution && !props.noContact) },
    supplier: { required },
  },
})

const contacts = ref<Contact[]>([])
const createContact = ref(false)
const createVendor = ref(false)
const appendVendors = ref<Vendor[]>([])
const loading = ref(false)
const newContacts = ref<Contact[]>([])

const allContacts = computed(() => contacts.value.concat(newContacts.value))
const contactError = computed(() => (typeof errors.value.contact === 'string' ? errors.value.contact : undefined))
const vendorError = computed(() => {
  if (!props.supplier && v$.value.supplier.$dirty && v$.value.supplier.$invalid) {
    return 'A supplier is required'
  }

  return undefined
})

onMounted(() => {
  if (props.supplier) {
    appendVendors.value = [{ ...props.supplier }]
    fetchContacts()
  }
})

const addContact = (contact: Contact) => {
  newContacts.value.push(contact)
  emit('update:contact', contact)
}

const clearVendor = () => {
  emit('update:supplier', null)
  contacts.value = []
  emit('update:contact', null)
}

const { $api, $error } = useNuxtApp()

const fetchContacts = async () => {
  if (props.disabled || props.noContact) {
    return
  }

  loading.value = true

  try {
    const fetchedContacts = await $api.v1.vendors.availableContacts(props.supplier!.id)

    fetchedContacts.forEach((c: Contact) => {
      if (c.email) {
        contacts.value.push(c)
      }
    })
  }
  catch (error) {
    $error.report(error)
  }

  loading.value = false
}

const selectVendor = (vendor: Vendor | null) => {
  if (!vendor) {
    clearVendor()
    return
  }

  emit('update:supplier', vendor)
  emit('update:contact', null)
  contacts.value = []
  nextTick(fetchContacts)
}

const vendorCreated = (vendor: Vendor) => {
  appendVendors.value.push(vendor)
  selectVendor(vendor)
  emit('supplier-created', vendor)
}

const isValid = () => {
  v$.value.$touch()

  return !v$.value.$invalid
}

defineExpose({ isValid })
</script>
