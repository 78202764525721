<template>
  <v-list-item
    class="pl-0"
    :class="{ final }"
  >
    <v-list-item-title class="pa-0">
      <div>
        <v-icon :class="iconClass">
          mdi-check
        </v-icon>
        <span
          class="item-title ml-1 qtm-h3"
          :class="final && approval.entry_type === 'REQ' ? 'pending' : ''"
        >
          {{ approval.title }}
        </span>
      </div>
      <div>
        <div class="connector" />
        <div class="item-subtitle ml-1">
          {{ approval.tag }} {{ approval.actionUser }}
          <span v-if="approval.entry_type!=='REQ'">
            on {{ dateCreated }}
          </span>
        </div>
      </div>
    </v-list-item-title>
  </v-list-item>
</template>

<script setup lang="ts">
import { dateYear } from '~/models/filters'

interface Approval {
  title: string
  tag: string
  actionUser: string
  entry_type: string
  date_created: number
}

export interface Props {
  approval: Approval
  final?: boolean
}

const props = defineProps<Props>()

const dateCreated = dateYear(props.approval.date_created)
const iconClass = computed(() => {
  if (props.final && props.approval.entry_type === 'REQ') {
    return 'unchecked check-icon'
  }

  return 'check-icon'
})
</script>

<style>
  .check-icon {
    color: white !important;
    background-color: rgb(var(--v-theme-secondary-lighten-1));
  }
  .unchecked.check-icon {
      color: rgb(var(--v-theme-light-grey)) !important;
      background-color: rgb(var(--v-theme-light-grey));
  }
  .connector {
    width: 0.15em;
    height: 1.4em;
    display: inline-block;
    margin: 0.5em 0.8em 0em 0.7em;
    background-color: var(--v-theme-secondary-lighten1)
  }
  .final .connector {
    background-color: white;
  }
  .item-subtitle {
    display: inline-block;
    vertical-align: top;
    color: rgb(var(--v-theme-mid-grey));
  }
  .final .item-subtitle {
    color: rgb(var(--v-theme-dark-grey));
  }
  .item-title {
    color: rgb(var(--v-theme-secondary-lighten-1));
  }
  .item-title.pending {
    color: rgb(var(--v-theme-secondary));
  }
</style>
