<template>
  <qtm-dialog-card
    v-bind="$attrs"
    title="Create Bill of Materials"
    width="457"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <bom-details-form v-model:project="project" v-model:title.trim="title" class="mt-4" />

    <template v-slot:actions>
      <v-spacer />
      <qtm-btn :loading="loading" size="x-large" tertiary @click="$emit('update:model-value', false)">
        Cancel
      </qtm-btn>
      <qtm-btn :disabled="disabled" :loading="loading" size="x-large" @click="createBom">
        Create
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import type { Jobsite, Quote } from '@quotetome/materials-api'
import BomDetailsForm from '@/components/boms/bom-details-form.vue'

export interface Props {
  attachments?: { id: number }[],
  items: any[]
  project?: Jobsite
  quote?: Quote
}

const props = withDefaults(defineProps<Props>(), {
  attachments: () => [],
  project: undefined,
  quote: undefined,
})

defineEmits(['update:model-value'])

const project = ref(props.project)
const title = ref('')
const disabled = computed(() => !title.value || !project.value)
const loading = ref(false)

const router = useRouter()
const { $api, $error, $toast, $tracker } = useNuxtApp()
const createBom = async () => {
  loading.value = true
  try {
    const bom = await $api.v1.boms.create({
      attachments_to_copy: props.attachments.map(a => a.id),
      items: props.items.map(item => ({
        comment: item.comment,
        description: item.description,
        quantity: item.quantity,
        unit_price: item.unit_price,
      })),
      preferred_quote: props.quote?.id,
      project: project.value?.id,
      title: title.value,
    })
    $toast.success('Bill of materials created')
    $tracker.track('BOM - Created', {
      company_id: project.value.company,
      jobsite_id: project.value.id,
      from_quote: true,
      item_count: props.items.length,
    })
    router.push({ name: 'projects-id-boms-bomId', params: { id: project.value?.id, bomId: bom.id } })
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}
</script>
