import { useAuthStore } from '~/stores/auth'

export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore()
  if (
    to.meta.layout === 'logged-in-app'
    && authStore.inSetupMode
    && !authStore.canManageCompany
    && to.name !== 'setup-mode'
  ) {
    return navigateTo({ name: 'setup-mode' })
  }

  return null
})
