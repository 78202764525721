<template>
  <qtm-number-field
    ref="input"
    v-bind="$attrs"
    v-model="value"
    :density="density"
    :disabled="disabled || !quantity"
    hide-details
    @change="valueFromTotal"
  />
</template>

<script>
import { UNIT_PRICE_DECIMAL_PLACES } from '@/constants'

export default {
  name: 'total-price-helper',
  props: {
    decimalPlaces: {
      type: [Number, String],
      default: UNIT_PRICE_DECIMAL_PLACES
    },
    density: {
      type: String,
      default: 'compact'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    quantity: {
      type: [Number, String],
      default: undefined
    },
    modelValue: {
      type: [Number, String],
      default: undefined
    },
  },
  emits: ['update:model-value'],
  data() {
    return {
      value: this.getValue()
    }
  },
  watch: {
    modelValue() {
      this.value = this.getValue()
    },
    quantity() {
      this.value = this.getValue()
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    getValue() {
      if (this.quantity && this.modelValue) {
        return Number(Number.parseFloat(this.quantity * this.modelValue).toFixed(this.decimalPlaces))
      }
      return 0
    },
    async valueFromTotal() {
      if (this.value && this.quantity) {
        const value = Number(Number.parseFloat(this.value / this.quantity).toFixed(this.decimalPlaces))

        if (Number.isFinite(Number(value))) {
          this.$emit('update:model-value', value)
        }
      }
      else {
        this.$emit('update:model-value', 0)
      }

      await this.$nextTick()
      this.value = this.getValue()
    }
  }
}
</script>
