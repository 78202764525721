<template>
  <div class="position-relative" @dragover="expandFileDropper" @dragleave="shrinkFileDropper" @drop="shrinkFileDropper">
    <div class="mb-2 d-flex flex-nowrap align-center justify-space-between qtm-body text-dark-grey">
      <div>
        <span class="qtm-label">
          {{ user!.first_name }} {{ user!.last_name }}
        </span>
      </div>
    </div>

    <div ref="tippyParent">
      <editor-content
        class="text-black"
        :editor="editor"
        :class="textareaRows"
        @paste="onPaste"
      />
    </div>
    <order-attachments
      v-if="uploadedFileList.length || fileInputList.length"
      v-model="uploadedFileList"
      :uploading-files="fileInputList"
      dense
      no-upload
      class="bg-transparent"
      remove-title
    />
    <div class="mt-2">
      <div class="d-flex">
        <qtm-icon-btn
          icon="mdi-at"
          @click="editor.chain().focus().insertContent(' @').run()"
        />
        <file-dropper
          v-model="fileInputList"
          icon-only
          multiple
          :expanded="fileDropperExpanded"
          @change="uploadFile"
        />
        <v-spacer />
        <template v-if="showAddCommentBtn">
          <qtm-btn
            tertiary
            :disabled="disableBtns"
            :loading="loading"
            @click="cancel"
          >
            Cancel
          </qtm-btn>
          <qtm-btn
            :disabled="disabled"
            :loading="loading"
            @click="saveComment"
          >
            Save
          </qtm-btn>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import tippy from 'tippy.js'
import { Editor, EditorContent, VueRenderer } from '@tiptap/vue-3'
import Mention from '@tiptap/extension-mention'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'

import MentionList from '@/components/comments/mention-list.vue'
import OrderAttachments from '@/components/orders/order-attachments.vue'

// By default the editor adds a <p></p> tag
const DEFAULT_LENGTH = 7

const emit = defineEmits(['add-comment', 'preview'])

const authStore = useAuthStore()
const nuxtApp = useNuxtApp()
const user = authStore.user

export interface Props {
  autofocus?: boolean
  contentId: number
  contentType: string
  mentionableUsers?: any[]
}

const props = withDefaults(defineProps<Props>(), {
  mentionableUsers: () => []
})

const textAreaFocused = ref(false)
const loading = ref(false)
const disableBtns = ref(false)
const newComment = ref('')
const fileInputList = ref<any[]>([])
const fileDropperExpanded = ref(false)
let fileDropperExpandedTimeout: any
const uploadedFileList = ref<any[]>([])
const editor = ref<any | null>(null)
const tippyParent = ref<any>(null)

const disabled = computed(() => {
  return disableBtns.value || (newComment.value.length <= DEFAULT_LENGTH && uploadedFileList.value.length === 0)
})

const processedMentionableUsers = computed(() => {
  return props.mentionableUsers.map(u => {
    return {
      id: u.user.id,
      label: `${u.user.first_name} ${u.user.last_name}`,
      first_name: u.user.first_name,
      last_name: u.user.last_name,
      email: u.user.email
    }
  })
})

const suggestion = {
  items: ({ query }: { query: string }) => {
    return processedMentionableUsers.value.filter(item => item.label.toLowerCase().includes(query.toLowerCase()))
  },

  render: () => {
    let component: VueRenderer | undefined
    let popup: any[] | undefined[]

    return {
      onStart: (onStartProps: any) => {
        component = new VueRenderer(MentionList, {
          props: onStartProps,
          editor: onStartProps.editor,
        })

        if (!onStartProps.clientRect) {
          return
        }

        popup = tippy(tippyParent.value, {
          getReferenceClientRect: onStartProps.clientRect,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(onUpdateProps: any) {
        component?.updateProps(onUpdateProps)

        if (!onUpdateProps.clientRect) {
          return
        }

        popup[0].setProps({
          getReferenceClientRect: onUpdateProps.clientRect,
        })
      },

      onKeyDown(onKeyDownProps: any) {
        if (onKeyDownProps.event.key === 'Escape') {
          popup[0]?.hide()

          return true
        }

        return component?.ref?.onKeyDown(onKeyDownProps)
      },

      onExit() {
        popup[0]?.destroy()
        component?.destroy()
      },
    }
  },
}

onMounted(() => {
  editor.value = new Editor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Add a comment...',
      }),
      Mention.configure({
        renderHTML(renderProps) {
          const { node } = renderProps
          return [
            'span',
            {
              'class': 'mention',
              'data-user-id': node.attrs.id.id,
              'data-user-name': node.attrs.id.label,
            },
            `@${node.attrs.id.label}`
          ]
        },
        suggestion,
      }),
    ],
    autofocus: props.autofocus,
    content: newComment.value,
    onFocus() {
      textAreaFocused.value = true
    },
    onBlur() {
      setTimeout(() => { textAreaFocused.value = false }, 100)
    },
    onUpdate: ({ editor: onUpdateEditor }) => {
      newComment.value = onUpdateEditor.getHTML()
    },
  })
})

const showAddCommentBtn = computed(() => {
  return textAreaFocused.value
    || newComment.value.length > DEFAULT_LENGTH
    || uploadedFileList.value.length > 0
    || fileInputList.value.length > 0
})

const textareaRows = computed(() => {
  return showAddCommentBtn.value ? 'expanded' : 'collapsed'
})

const expandFileDropper = () => {
  clearTimeout(fileDropperExpandedTimeout)
  fileDropperExpanded.value = true
}

const cancel = () => {
  uploadedFileList.value.forEach((attachment) => {
    nuxtApp.$api.v1.attachments.delete(attachment.id)
  })
  editor.value.commands.clearContent(true).run?.()
  editor.value.commands.blur().run?.()
  newComment.value = ''
  fileInputList.value = []
  uploadedFileList.value = []
  textAreaFocused.value = false
}

const saveComment = async () => {
  try {
    if (newComment.value || uploadedFileList.value.length) {
      loading.value = true
      const data = { text: newComment.value, attachments: uploadedFileList.value.map((attachment) => attachment.id) }
      const createdComment = await nuxtApp.$api.v1.comments.create(props.contentId, props.contentType, data)
      emit('add-comment', createdComment)
      editor.value.commands.clearContent(true)
      editor.value.commands.blur()
      newComment.value = ''
      uploadedFileList.value = []
      loading.value = false
    }
  }
  catch (error) {
    nuxtApp.$error.report(error)
    loading.value = false
  }
}

const shrinkFileDropper = () => {
  fileDropperExpandedTimeout = setTimeout(() => {
    fileDropperExpanded.value = false
  }, 300)
}

const uploadFile = () => {
  disableBtns.value = true
  const promises = fileInputList.value.map(f => {
    const formData = new FormData()
    formData.append('file', f, f.fileName)
    return nuxtApp.$api.v1.attachments.create(formData).then(response => {
      fileInputList.value = fileInputList.value.filter(item => item !== f)
      uploadedFileList.value.push(response)
    }).catch(e => {
      nuxtApp.$toast.error(e)
      fileInputList.value = fileInputList.value.filter(item => item !== f)
    })
  })
  Promise.all(promises).catch(nuxtApp.$error.report).finally(() => { disableBtns.value = false })
}
const onPaste = async (e: any) => {
  if (e.clipboardData.files.length) {
    e.preventDefault()
    fileInputList.value.push(...Array.from(e.clipboardData.files))
    await uploadFile()
  }
}
</script>

<style lang="scss">
/* Basic editor styles */
.ProseMirror {
  background-color: white;
  padding: 10px;
  border: 1px solid rgb(var(--v-theme-mid-light-grey));
  outline-color: rgb(var(--v-theme-interactive));
  overflow: auto;

  &:focus {
    outline-style: solid;
    outline-width: 2px;
  }

  > * + * {
    margin-top: 0.75em;
  }
}
.collapsed .ProseMirror {
  max-height: 50px !important;
  min-height: 50px !important;
}
.expanded .ProseMirror {
  max-height: 200px !important;
  min-height: 200px !important;
}
.mention {
  color: rgb(var(--v-theme-interactive))
}
</style>
