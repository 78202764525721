<template>
  <contact-menu-card
    content-class="ml-16"
    :email="user.email"
    :ext="user.phone_extension"
    :mobile="user.mobile_phone"
    :name="name"
    :phone="user.phone"
  >
    <template v-slot:activator="{ props }">
      <div v-bind="props" class="d-flex align-center">
        <user-avatar color="mid-light-grey" size="xl" :user="user" />
        <div class="ml-4">
          <div class="qtm-overline text-mid-grey text-uppercase" v-text="overline" />
          <div class="text-dark-grey qtm-body" v-text="name" />
        </div>
      </div>
    </template>
  </contact-menu-card>
</template>

<script setup>
import { computed } from 'vue'
import UserAvatar from '@/components/users/user-avatar.vue'

const componentProps = defineProps({
  overline: {
    type: String,
    default: '',
  },
  user: {
    type: Object,
    required: true,
  },
})

const name = computed(() => `${componentProps.user.first_name} ${componentProps.user.last_name}`)
</script>
