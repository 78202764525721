<template>
  <v-card>
    <div class="mb-1 text-right" :class="{ 'position-absolute': scrolled }">
      <qtm-icon-btn color="mid-light-grey" icon="mdi-close" @click="$emit('close')" />
    </div>
    <v-card-title
      class="d-flex align-start justify-space-between pb-0 pt-1 px-8"
      :class="{ 'pb-2': scrolled, 'qtm-border-bottom': scrolled }"
    >
      <div class="d-flex align-center flex-wrap">
        <div class="qtm-title-medium mr-3 text-secondary text-wrap" v-text="title" />
        <slot name="title-append" />
      </div>
      <div class="pt-2">
        <slot name="actions" />
      </div>
    </v-card-title>
    <v-card-text class="pb-12 pt-0 px-8" @scroll="onScroll">
      <v-row dense>
        <v-col cols="7">
          <slot name="subtitle">
            <div class="qtm-body-large text-mid-grey" v-text="subtitle" />
          </slot>
        </v-col>
      </v-row>
      <slot />
    </v-card-text>
    <div class="smooth-bottom" />
  </v-card>
</template>

<script setup lang="ts">
export interface Props {
  subtitle?: string
  title?: string
}

withDefaults(defineProps<Props>(), {
  subtitle: '',
  title: '',
})
defineEmits(['close'])

const scrolled = ref(false)
const onScroll = (e: any) => {
  scrolled.value = e.target?.scrollTop > 0
}
</script>

<style lang="scss" scoped>
.position-absolute {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.smooth-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}
</style>
