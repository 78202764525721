<template>
  <qtm-btn v-if="items.length" v-bind="$attrs" :append-icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'">
    {{ label }}
    <slot />
    <v-menu
      v-model="expanded"
      activator="parent"
      :close-on-content-click="closeOnContentClick"
      content-class="qtm-border"
    >
      <v-list>
        <v-list-item v-for="item in items" :key="item.value" v-bind="item" :data-test="item.dataTest">
          <div class="d-flex align-center">
            <slot :name="`item-icon.${item.value}`">
              <v-icon v-if="item.icon" class="mr-2" :icon="item.icon" />
            </slot>
            {{ item.label }}
          </div>
          <template v-if="item.new" v-slot:append>
            <span class="bg-primary font-weight-bold text-white new-chip qtm-body-small px-1 ml-10">
              NEW
            </span>
          </template>
        </v-list-item>
        <slot name="items-append" />
      </v-list>
    </v-menu>
  </qtm-btn>
</template>

<script setup lang="ts">
interface Item {
  dataTest?: string
  label: string
  to?: { name: string }
  icon?: string
  value: string
  onClick?: () => void
  new?: boolean
}

export interface Props {
  closeOnContentClick?: boolean
  items?: Item[]
  label: string
}

withDefaults(defineProps<Props>(), {
  closeOnContentClick: true,
  items: () => [],
})

const expanded = ref(false)
</script>

<style lang="scss" scoped>
.new-chip {
  border-radius: 2px;
}
</style>
