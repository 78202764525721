<template>
  <td class="editable-cell pa-2" :class="{ disabled, error, focused }" @click="onClick">
    <component
      :is="component"
      ref="innerComponent"
      v-bind="$attrs"
      v-model:focused="focused"
      background-color="transparent"
      :error="error"
      :menu="focused"
      :readonly="disabled"
      variant="plain"
    >
      <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
        <slot v-bind="slotData" :key="name" :name="name" />
      </template>
    </component>
  </td>
</template>

<script setup lang="ts">
export interface Props {
  component: Component | string
  disabled?: boolean
  error?: boolean
}

const props = defineProps<Props>()

const focused = ref(false)
const innerComponent = ref<any>(null)
const onClick = () => {
  if (props.disabled) {
    return
  }

  innerComponent.value?.focus?.()
}
</script>

<style lang="scss" scoped>
.editable-cell {
  cursor: text;

  &.disabled {
    background: rgb(var(--v-theme-background));
  }

  &.error {
    border-color: rgb(var(--v-theme-error));
  }

  &.focused:not(.disabled) {
    border-color: rgb(var(--v-theme-interactive));
  }

  &:hover:not(.disabled) {
    border-color: rgb(var(--v-theme-dark-grey));
  }
}
</style>
