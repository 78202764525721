<template>
  <qtm-text-field ref="input" v-bind="$attrs" @update:model-value="clean" />
</template>

<script setup lang="ts">
const emit = defineEmits(['update:model-value'])

const clean = (value: string) => {
  const cleanValue = value.replace(/[^0-9.-]/g, '')

  emit('update:model-value', cleanValue)
}

const input = ref<any>(null)
const focus = () => {
  input.value?.focus()
}
defineExpose({ focus })
</script>
