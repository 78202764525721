<template>
  <status :positive="receivedInFull" positive-text="Received" :negative-text="negativeText" />
</template>

<script>
import Status from '@/components/status.vue'

export default {
  name: 'received-status',
  components: { Status },
  props: {
    partiallyReceived: {
      type: Boolean,
      default: false
    },
    receivedInFull: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    negativeText() {
      if (this.partiallyReceived) {
        return 'PARTIALLY RECEIVED'
      }

      return 'NOT RECEIVED'
    },
  }
}
</script>
