<template>
  <div class="d-flex">
    <qtm-select v-bind="$attrs" :item-title="contactName" :items="vendor.contacts" />
    <qtm-btn class="ml-2" size="x-large" @click="showCreateContactDialog = true">
      <v-icon>mdi-plus</v-icon>
      <span v-if="!vendor.contacts.length">Add Contact</span>
    </qtm-btn>
    <create-contact-dialog
      v-model="showCreateContactDialog"
      :email-required="emailRequired"
      :phone-required="phoneRequired"
      :vendor="vendor"
      @new-contact="$emit('new-contact', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import type { Contact, Vendor } from '@quotetome/materials-api'
import CreateContactDialog from '@/components/vendors/create-contact-dialog.vue'

export interface Props {
  emailRequired?: boolean
  phoneRequired?: boolean
  vendor: Vendor
}

const props = defineProps<Props>()
defineEmits(['new-contact'])

const showCreateContactDialog = ref(false)

const contactName = (item?: Contact | number) => {
  let contact = item

  if (typeof contact === 'number') {
    contact = props.vendor.contacts.find(c => c.id === item)
  }

  if (contact?.first_name) {
    return `${contact.first_name} ${contact.last_name}`
  }

  return ''
}
</script>
