<template>
  <status
    :error="!!invoice.date_cancelled"
    error-text="Cancelled"
    :positive="!!invoice.date_approved"
    positive-text="Approved"
    negative-text="Awaiting Approval"
  />
</template>

<script>
import Status from '@/components/status.vue'

export default {
  name: 'invoice-status',
  components: { Status },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
}
</script>
