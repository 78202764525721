<template>
  <qtm-dialog-card :model-value="modelValue" persistent width="410">
    <div class="qtm-h3 text-secondary mb-6 text-center text-uppercase">
      Matching Items
    </div>

    <div class="text-dark-grey qtm-body text-center">
      We're matching {{ matchingTypeFrom }} and {{ matchingTypeTo }} items, this may take a moment
    </div>

    <div class="px-12">
      <v-progress-linear
        color="interactive"
        class="mt-6 mb-1"
        height="6"
        indeterminate
        rounded
      />
    </div>

    <template v-slot:actions>
      <v-spacer />
      <qtm-btn tertiary class="qtm-label" @click="cancel">
        Cancel Automatic Matching
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script setup lang="ts">
export interface Props {
  items: any[]
  loading?: boolean
  modelValue: boolean
  orderItems: any[]
  matchingTypeFrom?: string
  matchingTypeTo?: string
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  matchingTypeFrom: 'invoice',
  matchingTypeTo: 'PO'
})
const emit = defineEmits([
  'cancel',
  'done',
  'update:auto-matched-items-count',
  'update:items',
  'update:loading',
  'update:model-value',
])

let cancelled = false

const cancel = () => {
  cancelled = true
  emit('update:loading', false)
  emit('cancel')
  emit('update:model-value', false)
}

const { $api, $error, $toast } = useNuxtApp()

async function matchItems() {
  let matchedCount = 1

  if (props.items.length === 1 && props.orderItems.length === 1) {
    singleMatch()
  }
  else {
    matchedCount = await multiMatch()

    if (cancelled) {
      return
    }
  }

  emit('done')
  emit('update:model-value', false)

  if (matchedCount) {
    $toast.success(`🎉 ${matchedCount} item${matchedCount > 1 ? 's' : ''} matched.
      Verify if all items matched are correct before requesting approval.`)
  }
  else {
    $toast.warning("0 items matched. You'll need to match the items manually.")
  }

  emit('update:auto-matched-items-count', matchedCount)
}

async function multiMatch(): Promise<number> {
  let count = 0

  emit('update:loading', true)

  try {
    const matchedItems = await $api.v1.matchItems({
      items: props.items.map((item, i) => ({
        item_number: i + 1,
        description: item.description,
        rental_duration: item.rental_duration,
        rental_duration_unit: item.rental_duration_unit,
        unit_price: item.unit_price,
        quantity: item.quantity,
        day_rate: item.day_rate,
        week_rate: item.week_rate,
        month_rate: item.month_rate,
      })),
      items_to_match: props.orderItems.map((sku) => ({
        item_number: sku.id,
        description: sku.description,
        rental_duration: sku.rental_duration,
        rental_duration_unit: sku.rental_duration_unit,
        unit_price: sku.unit_price,
        quantity: sku.quantity,
        day_rate: sku.day_rate,
        week_rate: sku.week_rate,
        month_rate: sku.month_rate,
      })),
    })

    if (!cancelled) {
      const updatedItems = props.items.map((item, i) => ({
        ...item,
        item_number: i + 1,
      }))

      matchedItems?.matched_items.forEach((matchedItem: any) => {
        if (matchedItem.po_id) {
          const matchedPoItem = props.orderItems?.find(poItem => poItem.id === Number(matchedItem.po_id))
          const invoiceItemIndex = updatedItems.findIndex(item => item.item_number === Number(matchedItem.item_number))
          updatedItems[invoiceItemIndex].reference_identifier = matchedPoItem?.internal_identifier
          updatedItems[invoiceItemIndex].cost_code = matchedPoItem?.cost_code ?? ''
          count += 1
        }
      })

      emit('update:items', updatedItems)
    }
  }
  catch (error) {
    $error.report(error)
  }

  emit('update:loading', false)

  return count
}

function singleMatch() {
  const item = { ...props.items[0] }

  item.reference_identifier = props.orderItems[0]?.internal_identifier
  item.cost_code = props.orderItems[0]?.cost_code ?? ''

  emit('update:items', [item])
}

watch(() => props.modelValue, (value) => {
  if (value) {
    cancelled = false
    matchItems()
  }
})
</script>
