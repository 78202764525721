<template>
  <div class="invite-vendor-preview">
    <qtm-skeleton v-if="loading" />
    <iframe ref="previewIFrame" :class="{loading}" :src="url" />
    <p>{{ text }} (All Order Attachments will be attached to the email)</p>
  </div>
</template>

<script setup lang="ts">
import type { Order } from '@quotetome/materials-api'

const props = defineProps<{
  order: Order
  text: string
}>()

const loading = ref(true)
const { $api } = useNuxtApp()
const url = computed(() => $api.v1.getPreviewEmailURL(props.order, 'request_quotes'))

const previewIFrame = ref<HTMLIFrameElement | null>(null)
onMounted(() => {
  previewIFrame.value?.addEventListener('load', () => {
    loading.value = false
  })
})
</script>

<style scoped lang="scss">
iframe {
  border: none;
  height: 500px;
  margin: 0;
  padding: 0;
  width: 790px;

  &.loading {
    display: none;
  }
}
</style>
