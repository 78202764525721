<template>
  <div v-if="hasAttachments" class="text-dark-grey">
    <v-dialog v-model="showAttachments" width="430">
      <template v-slot:activator="{ props: activator }">
        <span
          v-bind="activator"
          class="cursor-pointer text-decoration-underline"
        >Quote & Attachments</span>
      </template>
      <v-card>
        <qtm-icon-btn icon="mdi-close" location="top right" position="absolute" @click="showAttachments = false" />
        <div class="supplier-name" v-text="quote.vendor?.name" />
        <v-card-text>
          <template v-for="(attachment, i) in quote.attachments" :key="attachment.id">
            <attachment-preview :attachment="attachment" />
            <v-divider v-if="lastAttachmentIndex !== i" class="my-4" />
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  <div v-else class="text-dark-grey">
    No Attachments
  </div>
</template>

<script setup lang="ts">
import type { Quote } from '@quotetome/materials-api'
import AttachmentPreview from '@/components/attachments/attachment-preview.vue'

export interface Props {
  quote: Quote
}

const props = defineProps<Props>()

const showAttachments = ref(false)

const hasAttachments = computed(() => !!props.quote.attachments?.length)
const lastAttachmentIndex = computed(() => (hasAttachments.value ? props.quote.attachments!.length - 1 : undefined))
</script>

<style lang="scss" scoped>
.supplier-name {
  font-weight: bold;
  overflow: hidden;
  padding: 1rem 0.5rem;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
