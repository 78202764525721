<template>
  <div class="d-flex align-center flex-grow-1 justify-space-between py-5">
    <div>
      <div class="qtm-label text-secondary invoice-number">
        INVOICE #{{ invoice.invoice_number }}
      </div>
      <div class="qtm-h1 mr-5 invoice-title">
        {{ order.reference_name }} for {{ order.jobsite.name }}
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'invoice-title',
  props: {
    invoice: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
  },
}
</script>

<style lang="scss" scoped>
.invoice-number {
  font-family: "Roboto Condensed", Roboto, sans-serif;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.invoice-title {
  font-family: Roboto, sans-serif;
}
</style>
