<template>
  <div v-if="!hasChanges" class="change-order-diff-value">
    <span v-if="value === null">&mdash;</span>
    <span v-else-if="currency" class="d-inline-block qtm-currency">{{ decimal(value) }}</span>
    <span v-else>{{ value }}</span>
  </div>
  <div v-else class="change-order-diff-value">
    <span class="old-value bg-red-lighten-5 pa-1">
      <span v-if="value.old === ''">&mdash;</span>
      <span v-else-if="currency" class="d-inline-block qtm-currency">{{ decimal(value.old) }}</span>
      <span v-else>{{ value.old }}</span>
    </span>

    <v-icon size="medium">
      mdi-arrow-right
    </v-icon>

    <span class="new-value bg-green-lighten-5 pa-1">
      <span v-if="value.new === ''">&mdash;</span>
      <span v-else-if="currency" class="d-inline-block qtm-currency">{{ decimal(value.new) }}</span>
      <span v-else>{{ value.new }}</span>
    </span>
  </div>
</template>

<script>
import { decimal } from '~/models/filters'

export default {
  name: 'change-order-diff-value',
  props: {
    value: {
      type: [Number, String, Object],
      default: '',
    },
    currency: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    hasChanges() {
      return this.value && typeof this.value === 'object'
    },
  },
  methods: {
    decimal(value) {
      return decimal(value)
    },
  }
}
</script>

<style scoped lang="scss">
.change-order-diff-value {
  display: inline-block;
}
</style>
