<template>
  <span class="qtm-chip qtm-overline-bold" :class="classes">
    <slot />
  </span>
</template>

<script setup lang="ts">
export interface Props {
  color?: string
  textColor?: string
}

const props = withDefaults(defineProps<Props>(), {
  color: 'primary-lighten-1',
  textColor: 'primary-darken-1'
})

const classes = computed(() => [
  `bg-${props.color}`,
  `text-${props.textColor}`
])
</script>

<style lang="scss" scoped>
.qtm-chip {
  border-radius: 2px;
  padding: 2px 4px;
  text-transform: uppercase;
  width: fit-content;
}
</style>
