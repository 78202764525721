<template>
  <v-text-field
    v-bind="$attrs"
    ref="textfield"
    :bg-color="backgroundColor"
    clear-icon="mdi-close"
    :color="color"
    :error-messages="activeErrors"
    :variant="variant"
    @blur="focused = false"
    @focus="focused = true"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
      <slot v-bind="slotData" :key="name" :name="name" />
    </template>
  </v-text-field>
</template>

<script setup lang="ts">
export interface Props {
  backgroundColor?: string
  color?: string
  errorMessages?: string | string[]
  variant?: 'outlined' | 'plain'
}

const props = withDefaults(defineProps<Props>(), {
  backgroundColor: 'white',
  color: 'interactive',
  errorMessages: undefined,
  variant: 'outlined',
})

const focused = ref(false)
const activeErrors = computed(() => (!focused.value ? props.errorMessages : undefined))

const textfield = ref<HTMLInputElement | null>(null)
const focus = () => {
  textfield.value?.focus()
}

defineExpose({ focus })
</script>
