<template>
  <span>
    {{ text }}
    <v-tooltip location="top">
      <template v-slot:activator="{ props: activator }">
        <qtm-icon-btn v-bind="activator" class="mb-n2 mt-n1" color="mid-grey" icon="mdi-content-copy" @click="copy" />
      </template>
      {{ copied ? 'Copied!' : 'Copy' }}
    </v-tooltip>
  </span>
</template>

<script setup lang="ts">
export interface Props {
  text: string
}

const props = defineProps<Props>()

const copied = ref(false)

let copiedTimeout: ReturnType<typeof setTimeout>

const copy = () => {
  navigator.clipboard.writeText(props.text)
  copied.value = true
  clearTimeout(copiedTimeout)
  copiedTimeout = setTimeout(() => {
    copied.value = false
  }, 3000)
}
</script>
