<template>
  <div :class="mainClasses" @click="toggle">
    <svg
      v-if="indeterminate"
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.5 2.5H25.5V25.5H2.5V2.5Z" fill="currentColor" stroke="currentColor" />
      <path
        d="M2.5 1.5H1.5V2.5V25.5V26.5H2.5H25.5H26.5V25.5V2.5V1.5H25.5H2.5Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="2"
      />
      <line
        stroke="white"
        stroke-width="3"
        x1="8"
        x2="20"
        y1="14"
        y2="14"
      />
    </svg>
    <svg
      v-else-if="checked"
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.5 25.5V2.5H25.5V25.5H2.5Z" fill="currentColor" stroke="currentColor" />
      <path
        d="M26.5 2.5V1.5H25.5H2.5H1.5V2.5V25.5V26.5H2.5H25.5H26.5V25.5V2.5Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="2"
      />
      <!-- eslint-disable max-len -->
      <path
        d="M11.9785 18.5718L8.15945 14.8438C8.10905 14.7956 8.06894 14.7377 8.04153 14.6736C8.01413 14.6095 8 14.5405 8 14.4708C8 14.4011 8.01413 14.3321 8.04153 14.268C8.06894 14.2039 8.10905 14.146 8.15945 14.0978L8.93045 13.3518C9.03451 13.2531 9.17249 13.198 9.31595 13.198C9.45941 13.198 9.5974 13.2531 9.70145 13.3518L12.3645 15.9628L18.3005 10.1538C18.4045 10.0551 18.5425 10 18.686 10C18.8294 10 18.9674 10.0551 19.0715 10.1538L19.8405 10.8988C19.8908 10.9469 19.9309 11.0047 19.9583 11.0687C19.9857 11.1328 19.9998 11.2017 19.9998 11.2713C19.9998 11.341 19.9857 11.4099 19.9583 11.4739C19.9309 11.5379 19.8908 11.5957 19.8405 11.6438L12.7495 18.5738C12.6451 18.6723 12.507 18.727 12.3636 18.7266C12.2201 18.7263 12.0823 18.6708 11.9785 18.5718Z"
        fill="white"
      />
    </svg>
    <svg
      v-else
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.5 25.5V2.5H25.5V25.5H2.5Z" />
      <path
        d="M26.5 2.5V1.5H25.5H2.5H1.5V2.5V25.5V26.5H2.5H25.5H26.5V25.5V2.5Z"
        stroke="currentColor"
        stroke-width="2"
      />
    </svg>
    <span v-if="label || $slots.label" class="label pl-1">
      <slot name="label">
        <span
          :class="{ 'text-uppercase': textUppercase, 'text-dark-grey': !disabled, 'text-mid-light-grey': disabled }"
          v-text="label"
        />
      </slot>
    </span>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  color?: string
  defaultColor?: string
  disabled?: boolean
  indeterminate?: boolean
  label?: string
  modelValue?: boolean | number | string | Array<boolean | number | string>
  textUppercase?: boolean
  val?: number | object | string
}

const props = withDefaults(defineProps<Props>(), {
  color: 'interactive',
  defaultColor: 'mid-light-grey',
  disabled: false,
  indeterminate: false,
  label: '',
  modelValue: false,
  textUppercase: false,
  val: undefined,
})
const emit = defineEmits(['change', 'toggled', 'update:model-value'])

const checked = computed(() => {
  if (isArray.value) {
    return props.val && (props.modelValue as Array<any>).includes(props.val)
  }

  return props.modelValue
})
const mainClasses = computed(() => {
  const classes = ['qtm-checkbox', `text-${currentColor.value}`]
  if (props.disabled) {
    classes.push('disabled')
  }
  return classes
})
const currentColor = computed(() => {
  if (props.disabled) {
    return 'light-grey'
  }
  return checked.value || props.indeterminate ? props.color : props.defaultColor
})
const isArray = computed(() => {
  return Array.isArray(props.modelValue)
})

const toggle = () => {
  if (props.disabled) {
    return
  }
  let event

  if (isArray.value) {
    if (checked.value) {
      event = (props.modelValue as Array<any>).filter(val => val !== props.val)
    }
    else {
      event = (props.modelValue as Array<any>).concat([props.val])
    }
  }
  else {
    event = !checked.value
  }

  emit('update:model-value', event)
  emit('change', event)
  emit('toggled', !checked.value)
}
</script>

<style lang="scss" scoped>
$size: 24px;

.label {
  vertical-align: middle;
}

.qtm-checkbox {
  cursor: pointer;
  vertical-align: middle;
}

.qtm-checkbox.disabled {
  cursor: no-drop;
}

.qtm-checkbox > svg {
  height: $size;
  margin: 4px 0;
  vertical-align: middle;
  width: $size;
}
</style>
