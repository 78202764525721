<template>
  <component :is="component" v-bind="componentProps">
    <v-row>
      <v-col>
        <qtm-input-label label="How Will You Get Your Order?">
          <order-delivery-required
            v-model="order.delivery_time"
            v-model:date="order.delivery_date"
            v-model:pickup="order.customer_pickup"
          />
        </qtm-input-label>
      </v-col>
    </v-row>

    <v-slide-y-transition>
      <v-row v-if="deliveryRequired">
        <v-col>
          <qtm-input-label :label="`${deliveryOrPickup} Contact`">
            <qtm-autocomplete
              v-model="order.site_contact"
              hide-details
              :item-title="teamName"
              :items="jobsiteTeam"
              :loading="loading"
              style="max-width: 300px"
            />
          </qtm-input-label>
        </v-col>
      </v-row>
    </v-slide-y-transition>

    <v-slide-y-transition>
      <v-row v-if="deliveryRequired && !order.customer_pickup">
        <v-col cols="12" md="6">
          <qtm-input-label label="Delivery Address">
            <order-delivery-location
              v-model:location="order.location"
              v-model:location-type="order.delivery_location"
              :jobsite="order.jobsite"
            />
          </qtm-input-label>
        </v-col>
      </v-row>
    </v-slide-y-transition>

    <v-slide-y-transition>
      <v-row v-if="deliveryRequired">
        <v-col cols="12" md="6">
          <qtm-input-label :label="`${deliveryOrPickup} Date & Time`" :required="validateDeliveryRequired">
            <order-delivery-date
              v-model="v$.order.delivery_date.$model"
              v-model:delivery-time="order.delivery_time"
              :cutoff="cutoffDate"
              :date-picker-menu-props="datePickerMenuProps"
              :disabled="!deliveryRequired"
              :error-messages="errorMessage || deliveryDateError"
              :pickup="order.customer_pickup"
            />
          </qtm-input-label>
        </v-col>
      </v-row>
    </v-slide-y-transition>

    <slot />
  </component>
</template>

<script>
import { requiredIf } from '@vuelidate/validators'
import OrderDeliveryDate from '@/components/orders/order-delivery-date.vue'
import OrderDeliveryLocation from '@/components/orders/order-delivery-location.vue'
import OrderDeliveryRequired from '@/components/orders/order-delivery-required.vue'
import useValidation from '@/composables/validation'
import { DELIVERY_DATE_REQUIRED_VALUES, DELIVERY_REQUIRED_VALUES } from '@/constants'

export default {
  name: 'order-delivery',
  components: { OrderDeliveryDate, OrderDeliveryLocation, OrderDeliveryRequired },
  props: {
    collapsed: {
      type: Boolean,
      default: false
    },
    cutoffDate: {
      type: Object,
      default: undefined
    },
    datePickerMenuProps: {
      type: Object,
      default: undefined
    },
    errorMessage: {
      type: String,
      default: undefined
    },
    flat: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      required: true,
    },
    validateDeliveryRequired: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['team'],
  setup() {
    const { errors, isValid, v$ } = useValidation()

    return { errors, isValid, v$ }
  },
  validations: {
    order: {
      delivery_date: {
        required: requiredIf(function () {
          return this.validateDeliveryRequired && (
            this.order.delivery_time === '-' || DELIVERY_DATE_REQUIRED_VALUES.includes(this.order.delivery_time)
          )
        }),
      },
    }
  },
  data() {
    return {
      jobsiteTeam: [],
      loading: false,
    }
  },
  computed: {
    component() {
      return this.flat ? 'v-col' : 'qtm-content-block'
    },
    componentProps() {
      if (this.flat) {
        return undefined
      }

      return {
        collapsed: this.collapsed,
        collapsible: true,
        title: 'Delivery Details',
      }
    },
    deliveryDateError() {
      return this.v$.$dirty && this.v$.$invalid ? 'A delivery date is required' : undefined
    },
    deliveryLocation: {
      get() {
        if (this.order.location) {
          return 'other'
        }

        return this.order.delivery_location
      },

      set(value) {
        if (value === 'other') {
          const location = this.order.jobsite.locations[0]

          this.order.location = location.id
          this.order.delivery_location = 'jobsite'
        }
        else {
          this.order.delivery_location = value
          this.order.location = null
        }
      }
    },

    deliveryLocationOptions() {
      const options = [
        {
          label: 'Site',
          value: 'jobsite',
        },
        {
          label: 'Office',
          value: 'office',
        },
      ]

      if (this.order.jobsite && this.order.jobsite.locations.length) {
        options.push({
          label: 'Other',
          value: 'other',
        })
      }

      return options
    },

    deliveryOrPickup() {
      return this.order.customer_pickup ? 'Pick Up' : 'Delivery'
    },

    deliveryRequired() {
      return this.order.delivery_time === '-' || DELIVERY_REQUIRED_VALUES.includes(this.order.delivery_time)
    },
  },
  watch: {
    deliveryRequired() {
      this.order.delivery_required = this.deliveryRequired && !this.order.customer_pickup
    },
    'order.customer_pickup': {
      handler() {
        this.order.delivery_required = this.deliveryRequired && !this.order.customer_pickup
      }
    },
    'order.jobsite': {
      handler() {
        if (!this.order.jobsite?.locations.some(location => location.id === this.order.location)) {
          this.order.location = null
        }
        this.fetchJobsiteTeam()
      }
    }
  },
  mounted() {
    this.fetchJobsiteTeam()
  },
  methods: {
    async fetchJobsiteTeam() {
      if (this.order.jobsite && this.order.jobsite.id) {
        this.loading = true
        try {
          const team = await this.$api.v1.users.list({ jobsite: this.order.jobsite.id })
          this.$emit('team', team)
          this.jobsiteTeam = team
        }
        catch (error) {
          this.$error.report(error)
        }
        this.loading = false
      }
    },
    teamName(user) {
      let entry = user

      if (typeof entry === 'number') {
        entry = this.jobsiteTeam.find(u => u.id === entry) || entry
      }

      if (entry?.first_name) {
        return `${entry.first_name} ${entry.last_name}`
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
