<template>
  <v-card v-bind="$attrs" class="pa-2 bg-background" elevation="0">
    <div class="viewer-buttons">
      <qtm-icon-btn
        icon="mdi-download"
        :href="src"
        size="x-small"
        target="_blank"
        title="Download"
      />
      <span class="text-caption">{{ name }}</span>
      <qtm-icon-btn
        icon="mdi-close-circle"
        size="x-small"
        title="Close document viewer"
        @click="$emit('close')"
      />
    </div>
    <v-card-text ref="el">
      <div v-if="isPDF" class="pdf-container">
        <vue-pdf-embed
          v-for="page in numberOfPages"
          :key="page"
          class="mb-2"
          disable-annotation-layer
          :text-layer="selectTextLayer"
          :source="src"
          :page="page"
          :width="componentWidth"
          @loaded="pdfLoaded"
          @rendered="onRender"
        />
      </div>
      <div v-else-if="isImage">
        <v-img
          max-width="100%"
          :alt="name"
          :src="src"
        />
      </div>
      <div v-else class="mt-16 pt-16 qtm-h1 text-center">
        <div>
          Cannot view file
        </div>
        <v-btn
          :loading="downloading"
          title="Download"
          size="x-large"
          variant="text"
          @click="download(name)"
        >
          <v-icon color="interactive" location="left">
            mdi-download
          </v-icon>
          {{ name }}
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useResizeObserver } from '@vueuse/core'
import VuePdfEmbed from 'vue-pdf-embed'
import useAttachments from '@/composables/attachments'

export interface Props {
  name: string
  selectTextLayer?: boolean
  src: string
}

const props = defineProps<Props>()
defineEmits(['close'])

const el = ref()
const componentWidth = ref(500)
const pendingWidth = ref()
const renderComplete = ref(false)

const improveCopiedText = (textToImprove: string) => {
  const text = textToImprove.trim()
  if (!/[a-zA-Z]/.test(text)) {
    return text.replace(/[^\d./-]/g, '')
  }
  return text
}

const { $toast } = useNuxtApp()

const onRender = () => {
  if (pendingWidth.value) {
    componentWidth.value = pendingWidth.value
    pendingWidth.value = null
    return
  }

  renderComplete.value = true

  if (props.selectTextLayer) {
    Array.from(document.getElementsByClassName('textLayer')).forEach(elem => {
      Array.from(elem.children).forEach(span => {
        span.addEventListener('mouseup', (event) => {
          let selectedText = ''

          if (window.getSelection) {
            selectedText = window.getSelection()?.toString() ?? ''
          }

          let text = selectedText || (event.target as any)?.innerHTML

          text = improveCopiedText(text)
          navigator.clipboard.writeText(text)
          $toast.toast(`📋 ${text}`, { color: 'darkgrey' })
        }, false)
      })
    })
  }
}

useResizeObserver(el, (entries) => {
  const entry = entries[0]
  let { width } = entry.contentRect

  width = Math.trunc(width)

  if (width && width !== componentWidth.value) {
    if (!renderComplete.value) {
      pendingWidth.value = width
    }
    else {
      renderComplete.value = false
      componentWidth.value = width
    }
  }
})

const { isImage, isPDF, downloading, download } = useAttachments(toRef(props, 'src'))

const numberOfPages = ref(1)

const pdfLoaded = (pdfProxy: any) => {
  numberOfPages.value = pdfProxy.numPages
}
</script>

<style lang="scss">
.textLayer {
  margin: auto;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1;

  ::selection {
    background-color: rgb(var(--v-theme-primary)) !important;
  }
}

.textLayer > span {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  transform-origin: 0% 0%;
}

.textLayer > span:hover {
  background-color: yellow;
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
.pdf-container {
  position: relative;
}

.viewer-buttons {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  padding: 1px 4px 3px 4px;
  position: absolute;
  right: 10px;
  z-index: 999;
}
</style>
