<template>
  <qtm-content-block collapsible title="Supplier Information">
    <div class="d-flex justify-space-between">
      <div class="qtm-body text-dark-grey">
        <div class="font-weight-medium" v-text="vendor.name" />
        <div v-text="vendor.address" />
        <div>
          <qtm-address :city="vendor.city" :province="vendor.province" />
        </div>

        <qtm-input-label class="mt-3 contact-select" label="Contact" required>
          <div v-if="contact">
            <div>{{ contact.first_name }} {{ contact.last_name }}</div>
            <div>{{ contact.phone }}</div>
            <div>{{ contact.email }}</div>
          </div>
          <div v-else>
            <contact-select
              :error-messages="contactError"
              :model-value="contact"
              :vendor="vendor"
              @update:model-value="setContact"
              @new-contact="setContact"
            />
          </div>
        </qtm-input-label>
      </div>

      <div v-if="showChangeVendorButton" class="align-self-end">
        <qtm-btn secondary @click="showDialog = true">
          <v-icon size="small">
            mdi-pencil
          </v-icon>
          Change Supplier
        </qtm-btn>
        <change-vendor-dialog v-model="showDialog" :order="order" :quote="quote" :user="order.owner.id" />
      </div>
    </div>
  </qtm-content-block>
</template>

<script>
import { required } from '@vuelidate/validators'
import ChangeVendorDialog from '@/components/vendors/change-vendor-dialog.vue'
import ContactSelect from '@/components/vendors/contact-select.vue'
import useValidation from '@/composables/validation'

export default {
  name: 'quote-vendor-info',
  components: { ChangeVendorDialog, ContactSelect },
  validations: {
    contact: { required },
  },
  props: {
    changeSupplier: {
      type: Boolean,
      default: false
    },
    contact: {
      type: Object,
      default: undefined
    },
    order: {
      type: Object,
      required: true,
    },
    quote: {
      type: Object,
      default: undefined
    },
    vendor: {
      type: Object,
      required: true
    },
  },
  emits: ['update:contact'],
  setup() {
    const { errors, isValid, v$ } = useValidation()

    return { errors, isValid, v$ }
  },
  data() {
    return {
      showDialog: false
    }
  },
  computed: {
    contactError() {
      return typeof this.errors.contact === 'string' ? this.errors.contact : undefined
    },
    showChangeVendorButton() {
      return this.changeSupplier && this.quote && this.order.state.can_change_quote_vendor
    },
  },
  methods: {
    setContact(contact) {
      if (contact.id && !this.vendor.contacts.some(c => c.id === contact.id)) {
        this.vendor.contacts.push(contact)
      }

      if (contact.id) {
        this.$emit('update:contact', contact)
      }
      else {
        this.$emit('update:contact', this.vendor.contacts.find(c => c.id === contact))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-select {
  max-width: 500px;
}
</style>
