<template>
  <qtm-dialog-card v-bind="$attrs" :title="title" width="500px">
    <div>
      <div v-if="failed">
        <div v-if="submittedBy" class="mb-5">
          <span class="text-interactive" v-text="header" />
          submitted by {{ submittedBy.first_name }} {{ submittedBy.last_name }}
          failed to Sync with your accounting software due to the following error message:
        </div>
        <div class="mb-5 qtm-h4 text-secondary">
          <div>
            {{ subject.accounting_message }}
          </div>
        </div>
        <div>
          Please take the necessary action and sync again.
        </div>
      </div>
      <div v-else-if="pending" class="mb-5 text-secondary">
        This {{ type }} has been approved and is now being sent to your accounting software.
        This may take a few minutes.
      </div>
      <div v-else class="mb-5 text-secondary">
        Successfully synced with your accounting software.
      </div>
    </div>
    <clear-accounting-error-dialog
      v-model="clearError"
      :endpoint="endpoint"
      :object-id="subject.id"
      @cleared="errorCleared"
    />
    <template v-if="failed" v-slot:actions>
      <v-spacer />
      <qtm-btn :loading="loading" tertiary size="x-large" @click="$emit('update:model-value', false)">
        Close
      </qtm-btn>
      <qtm-btn v-if="isStaff" :loading="loading" secondary size="x-large" @click="clearError = true">
        Clear Error
      </qtm-btn>
      <qtm-btn :loading="loading" size="x-large" @click="syncAgain">
        Sync Again
      </qtm-btn>
    </template>
    <template v-else v-slot:actions>
      <v-spacer />
      <qtm-btn x-large @click="$emit('update:model-value', false)">
        Ok
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script setup lang="ts">
import type { User } from '@quotetome/materials-api'
import ClearAccountingErrorDialog from '@/components/accounting/clear-accounting-error-dialog.vue'

export interface Props {
  endpoint: 'expenses' | 'invoices' | 'purchaseOrders' | 'receipts'
  header: string
  subject: any
  submittedBy?: User
  type: string
}

const props = defineProps<Props>()
const emit = defineEmits(['update:accounting-success', 'update:model-value'])

const clearError = ref(false)
const loading = ref(false)

const authStore = useAuthStore()
const isStaff = authStore.isStaff

const failed = computed(() => props.subject.accounting_success === false)
const pending = computed(() => props.subject.accounting_success === null)
const title = computed(() => {
  if (pending.value) {
    return 'Accounting Sync Pending'
  }
  if (props.subject.accounting_success) {
    return 'Accounting Sync Successful'
  }
  return 'Accounting Sync Failed'
})

const errorCleared = () => {
  props.subject.accounting_success = true
  emit('update:accounting-success', true)
  emit('update:model-value', false)
}
const { $api, $error } = useNuxtApp()
const syncAgain = async () => {
  loading.value = true
  try {
    await $api.v1[props.endpoint].resolveAccountingFailure(props.subject.id)
    props.subject.accounting_success = null
    emit('update:model-value', false)
    emit('update:accounting-success', null)
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}
</script>
