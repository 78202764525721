<template>
  <qtm-content-block collapsible title="Purchase Order Info">
    <qtm-input-label label="Project" required>
      <jobsite-select v-model="v$.po.jobsite.$model" :error-messages="jobsiteError" :user="user" />
    </qtm-input-label>
    <qtm-input-label label="PO Number">
      <qtm-text-field
        v-model="po.po_number"
        maxlength="48"
      />
    </qtm-input-label>
  </qtm-content-block>
</template>

<script>
import { required } from '@vuelidate/validators'
import JobsiteSelect from '@/components/jobsites/jobsite-select.vue'
import useValidation from '@/composables/validation'

export default {
  name: 'purchase-order-info',
  components: { JobsiteSelect },
  props: {
    po: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      default: undefined
    }
  },
  setup() {
    const { errors, isValid, v$ } = useValidation()

    return { errors, isValid, v$ }
  },
  validations: {
    po: {
      jobsite: { required }
    }
  },
  computed: {
    jobsiteError() {
      return typeof this.errors.po.jobsite === 'string' ? this.errors.po.jobsite : undefined
    },
  }
}
</script>

<style lang="scss" scoped>
.jobsite-select {
  margin-bottom: 1.5rem;
}
</style>
