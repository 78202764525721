import { createApi, OrderState } from '@quotetome/materials-api'
import orderStatesExtension from '@/models/order-states-extension'
import { useAuthStore } from '~/stores/auth'
import { axiosInstance } from '~/plugins/axios'

let authStore: any

OrderState.extendStates(orderStatesExtension)

export const api = createApi(axiosInstance, () => authStore.authToken)

export default defineNuxtPlugin(() => {
  authStore = useAuthStore()

  return {
    provide: { api }
  }
})
