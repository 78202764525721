<template>
  <div class="document-icon-wrapper" :class="{ dense }">
    <v-img
      v-if="isImage"
      cover
      class="document-image"
      :alt="attachment.name"
      :src="attachment.url"
    />
    <div v-else class="document-icon ext-bg" :class="`ext-bg--${extension}`" v-text="extension" />
  </div>
</template>

<script setup lang="ts">
import type { Attachment } from '@quotetome/materials-api'
import useAttachments from '@/composables/attachments'

export interface Props {
  attachment: Attachment
  dense?: boolean
}

const props = defineProps<Props>()

const extension = computed(() => props.attachment.name.split('.').pop()?.toLowerCase())
const { isImage } = useAttachments(props.attachment.url)
</script>

<style lang="scss" scoped>
.document-icon-wrapper {
  --size: 40px;
  display: inline-block;
  font-size: 11px;
  height: var(--size);
  width: var(--size);
  max-width: var(--size);
  min-width: var(--size);

  &.dense {
    --size: 28px;
    font-size: 10px;
  }
}

.document-icon {
  --radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: white;
  border-radius: var(--radius);
  height: 100%;
  width: 80%;
  margin: 0 auto;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 133%;
  padding-bottom: var(--radius);
  overflow: hidden;
  clip-path: polygon(calc(100% - 0.3 * var(--size)) 0, 100% calc(0.3 * var(--size)), 100% 100%, 0 100%, 0 0);

  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-width: calc(0.15 * var(--size));
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.35);
  }
}

.document-image {
  display: inline-block;
  height: 100%;
  width: 100%;
}

.ext-bg {
  --ext-bg-color: #6F7A88;

  background-color: var(--ext-bg-color);

  &--csv {
    --ext-bg-color: #52AFA0;
  }

  &--doc,
  &--docx {
    --ext-bg-color: #33518A;
  }

  &--pdf {
    --ext-bg-color: #C4584A;
  }

  &--xls,
  &--xlsx {
    --ext-bg-color: #517144;
  }
}
</style>
