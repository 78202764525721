<template>
  <qtm-dialog-card
    v-bind="$attrs"
    closable
    :model-value="modelValue"
    title="Join Project"
    width="502"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <template v-slot:subtitle>
      <qtm-text-field
        v-model="search"
        class="mt-4"
        clearable
        hide-details
        :loading="loading"
        prepend-inner-icon="mdi-magnify"
        placeholder="Search name, address, number"
      />
    </template>
    <v-radio-group v-model="selected" color="interactive">
      <v-radio
        v-for="jobsite in filteredJobsites"
        :key="jobsite.id"
        class="joinable py-4"
        :value="jobsite.id"
        :label="jobsite.name"
      >
        <template v-slot:label>
          <div>
            <div class="qtm-body text-dark-grey">
              {{ jobsite.project_number }} - {{ jobsite.name }}
            </div>
            <div class="qtm-body-small text-mid-dark-grey">
              <qtm-address :address="jobsite.address" :city="jobsite.city" :province="jobsite.province" />
            </div>
          </div>
        </template>
      </v-radio>
    </v-radio-group>
    <template v-slot:actions>
      <qtm-btn class="my-1 w-100" :disabled="!selected" size="x-large" @click="join">
        Join
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script setup lang="ts">
import type { Jobsite } from '@quotetome/materials-api'

export interface Props {
  modelValue: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['joined', 'update:model-value'])

const loading = ref(false)
const search = ref('')
const jobsites = ref<Jobsite[]>([])
const selected = ref<number | null>(null)

const filteredJobsites = computed(() => {
  const cleanSearch = search.value?.trim()?.toLowerCase()

  if (!cleanSearch) {
    return jobsites.value
  }

  return jobsites.value.filter(jobsite => jobsite.name?.toLowerCase().includes(cleanSearch)
    || jobsite.project_number?.toLowerCase().includes(cleanSearch)
    || jobsite.address?.toLowerCase().includes(cleanSearch)
    || jobsite.city?.toLowerCase().includes(cleanSearch)
    || jobsite.province?.toLowerCase().includes(cleanSearch))
})

const authStore = useAuthStore()
const user = authStore.user
const { $api, $error } = useNuxtApp()
const fetchJobsites = async () => {
  loading.value = true
  try {
    jobsites.value = await $api.v1.jobsites.list({ joinable: user?.id })
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}
const join = async () => {
  loading.value = true
  try {
    const jobsite = await $api.v1.jobsites.join(selected.value as number, { team: [user?.id] })

    emit('joined', jobsite)
    emit('update:model-value', false)
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

watchEffect(() => {
  selected.value = null

  if (props.modelValue) {
    fetchJobsites()
  }
})
</script>

<style lang="scss" scoped>
.joinable:not(:last-child) {
  border-bottom: 1px solid rgb(var(--v-theme-light-grey));
}
</style>
