<template>
  <v-avatar
    class="vendor-avatar text-white"
    :class="avatarClass"
    :color="color"
    :size="avatarSize"
  >
    <div v-if="vendor.logo">
      <v-img :alt="vendor.name" :src="vendor.logo" width="40" />
    </div>
    <span v-else>
      {{ initials }}
    </span>
  </v-avatar>
</template>

<script>
const sizes = {
  lg: 40,
  xl: 48,
}

export default {
  name: 'vendor-avatar',
  props: {
    size: {
      type: String,
      default: 'xl'
    },
    vendor: {
      type: Object,
      required: true,
    },
  },
  computed: {
    avatarClass() {
      const cls = { 'qtm-border': !!this.vendor.logo }
      cls[`vendor-avatar--${this.size}`] = true

      return cls
    },
    avatarSize() {
      return sizes[this.size] || sizes.xl
    },
    color() {
      return this.vendor.logo ? 'transparent' : 'mid-light-grey'
    },
    initials() {
      return this.vendor.name.split(' ').slice(0, 2).map((word) => word[0]).join('')
    },
  },
}
</script>

<style lang="scss" scoped>
.vendor-avatar {
  font-weight: 700;

  &--lg {
    font-size: 16px;
  }
  &--xl {
    font-size: 20px;
  }
}
</style>
