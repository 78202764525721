<template>
  <v-menu :content-class="fullContentClass" max-width="300" offset="10" open-on-hover>
    <template v-slot:activator="activatorProps">
      <slot name="activator" v-bind="activatorProps" />
    </template>

    <v-card>
      <v-card-text class="text-dark-grey">
        <div class="qtm-body">
          <div class="font-weight-bold" v-text="name" />
          <div v-if="mobile">
            Mobile: <qtm-phone-number :phone="mobile" />
          </div>
          <div v-if="phone">
            Office: <qtm-phone-number :phone="phone" :ext="ext" />
          </div>
          <div>
            <a :href="`mailto:${email}`" class="text-decoration-none" v-text="email" />
          </div>
        </div>
        <slot />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script setup lang="ts">
export interface Props {
  contentClass?: string
  email?: string
  ext?: string
  mobile?: string
  name?: string
  phone?: string
}

const props = withDefaults(defineProps<Props>(), {
  contentClass: '',
  email: '',
  ext: undefined,
  mobile: undefined,
  name: '',
  phone: undefined,
})

const fullContentClass = computed(() => `elevation-5 qtm-border rounded-lg ${props.contentClass}`)
</script>
