<template>
  <div>
    <qtm-input-label label="User who cancels the order" required>
      <qtm-select
        v-model="cancelledBy"
        :item-title="teamMemberName"
        :items="projectTeam"
        :loading="loading"
        persistent-hint
        @update:model-value="$emit('update-action', { cancelledBy: $event })"
      />
    </qtm-input-label>

    <qtm-input-label label="Why is the order being cancelled?">
      <v-radio-group
        v-model="cancellationReason"
        hide-details
        @update:model-value="$emit('update-action', { cancellationReason: $event })"
      >
        <qtm-radio
          v-for="reason in order.cancellationReasons"
          :key="reason"
          :label="reason"
          :true-value="reason"
          data-test="cancel-reason"
        />
      </v-radio-group>
    </qtm-input-label>

    <qtm-text-field
      v-if="cancellationReason === 'Other'"
      v-model="otherCancellationReason"
      class="mt-1"
      data-test="cancellation-reason-other"
      dense
      hide-details
      placeholder="Please enter your other reason"
      :maxlength="CANCELLATION_REASON_MAX_LENGTH"
      @update:model-value="$emit('update-action', { cancellationReason: $event })"
    />

    <qtm-input-label label="Additional information" class="mt-4">
      <qtm-textarea
        v-model="cancellationMessage"
        auto-grow
        rows="2"
        hide-details
        placeholder="Leave a note for your team and your supplier..."
        data-test="cancellation-message"
        @update:model-value="$emit('update-action', { cancellationMessage: $event })"
      />
    </qtm-input-label>

    <qtm-checkbox
      v-if="showDistribute"
      v-model="distribute"
      class="mt-8"
      label="Send the cancellation notice to the supplier?"
      @update:model-value="$emit('update-action', { distribute: $event })"
    />
  </div>
</template>

<script setup lang="ts">
import { OrderState } from '@quotetome/materials-api'
import type { Order, User } from '@quotetome/materials-api'
import { CANCELLATION_REASON_MAX_LENGTH } from '@/constants'

const props = defineProps<{
  order: Order
}>()
const emit = defineEmits(['update-action'])

const loading = ref(false)
const projectTeam = ref<User[]>([])
const cancellationMessage = ref('')
const cancellationReason = ref(null)
const cancelledBy = ref<number | null>(null)
const distribute = ref(false)
const otherCancellationReason = ref(null)

const showDistribute = computed(() => {
  if (props.order.isInStorePO) {
    return false
  }

  if (props.order.state.id === OrderState.ORDER_SUBMITTED.id && props.order.isFromCallToVendor) {
    return false
  }

  return ![OrderState.ON_ORDER, OrderState.COMPLETE, OrderState.ARCHIVED]
    .map(state => state.id)
    .includes(props.order.state.id)
})

const { $api, $error } = useNuxtApp()
onMounted(async () => {
  loading.value = true
  if (props.order.jobsite) {
    try {
      projectTeam.value = await $api.v1.users.list({ jobsite: props.order.jobsite.id })
    }
    catch (error) {
      $error.report(error)
    }
  }

  if (props.order.owner && !projectTeam.value.some(member => member.id === props.order.owner?.id)) {
    projectTeam.value.push(props.order.owner)
  }

  cancelledBy.value = props.order.owner?.id ?? null
  emit('update-action', { cancelledBy: cancelledBy.value })
  loading.value = false
})

const teamMemberName = (member: number | User) => {
  let user: number | User | undefined = member

  if (typeof user === 'number') {
    user = projectTeam.value.find(m => m.id === member)
  }

  if (!user) {
    return ''
  }

  return `${user.first_name} ${user.last_name}`
}
</script>
