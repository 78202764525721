<template>
  <v-btn v-bind="attrs" :class="btnClass">
    <slot />
  </v-btn>
</template>

<script setup lang="ts">
const props = defineProps<{
  defaultWidth?: boolean
  secondary?: boolean
  tertiary?: boolean
}>()

interface BtnAttrs {
  color: string
  variant: 'flat' | 'outlined' | 'text'
  width?: number
}

const primaryAttrs: BtnAttrs = {
  color: 'primary',
  variant: 'flat',
}
const secondaryAttrs: BtnAttrs = {
  ...primaryAttrs,
  variant: 'outlined',
}
const tertiaryAttrs: BtnAttrs = {
  color: 'interactive',
  variant: 'text',
}

const $attrs: { width?: number } = useAttrs()
const attrs: ComputedRef<BtnAttrs> = computed(() => {
  let btnAttrs

  if (props.secondary) {
    btnAttrs = secondaryAttrs
  }
  else if (props.tertiary) {
    btnAttrs = tertiaryAttrs
  }
  else {
    btnAttrs = primaryAttrs
  }

  return {
    ...btnAttrs,
    ...$attrs,
    width: props.defaultWidth ? 300 : $attrs.width,
  }
})
const btnClass = computed(() => {
  if (props.tertiary) {
    return 'qtm-label'
  }

  return 'qtm-cta text-uppercase'
})
</script>
