<template>
  <span v-text="decimal(estimate, 2)" />
</template>

<script setup lang="ts">
import cloneDeep from 'lodash.clonedeep'
import debounce from 'lodash.debounce'
import isEqual from 'lodash.isequal'
import { decimal } from '@/models/filters'

export interface Props {
  item: any
}

const props = defineProps<Props>()

const estimate = ref(0)

if (props.item.quantity) {
  const quantity = Number(props.item.quantity)
  let price = 0

  if (props.item.rental_price) {
    price = Number(props.item.rental_price.price || 0)
  }
  else if (props.item.unit_price) {
    price = Number(props.item.unit_price || 0)
  }

  estimate.value = quantity * price
}

let previousData = null

const { $api, $error } = useNuxtApp()
const getEstimate = debounce(async () => {
  const itemData = {
    day_rate: props.item.day_rate || null,
    month_rate: props.item.month_rate || null,
    quantity: props.item.quantity || null,
    rental_duration: props.item.rental_duration || null,
    rental_duration_unit: props.item.rental_duration_unit || null,
    tax: props.item.tax || null,
    unit_price: props.item.unit_price || null,
    week_rate: props.item.week_rate || null,
  }

  if (isEqual(itemData, previousData)) {
    return
  }

  try {
    const result = await $api.v1.purchaseOrders.estimate({ poskus: [itemData] })

    estimate.value = result.estimate
    previousData = cloneDeep(itemData)
  }
  catch (error) {
    $error.report(error, false)
  }
}, 350)

watch(() => props.item, getEstimate, { deep: true })
</script>
