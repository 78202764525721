import { defineStore } from 'pinia'

interface State {
  toastOptions: any | null
}

export default defineStore('toast', {
  state: (): State => ({
    toastOptions: null
  }),
  actions: {
    toast(options: any) {
      this.toastOptions = options
    }
  }
})
