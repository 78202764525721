<template>
  <div v-if="show" class="qtm-body text-dark-grey">
    {{ deliveryOrPickup }} Date:
    <span v-if="unspecifiedDeliveryDate">not specified</span>
    <span v-else-if="deliveryDateObject" v-text="deliveryDate" />
  </div>
</template>

<script>
import { objectDeliveryDate } from '~/models/filters'

export default {
  name: 'quote-delivery-status',
  props: {
    quote: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    deliveryDate() {
      return objectDeliveryDate(this.deliveryDateObject)
    },
    deliveryDateObject() {
      if (this.quote.delivery_available) {
        return this.quote.delivery_date ? this.quote : this.order
      }
      return undefined
    },
    deliveryOrPickup() {
      return this.order.customer_pickup ? 'Pick-up' : 'Delivery'
    },
    show() {
      return (this.order.delivery_required || this.order.customer_pickup) && this.quote.delivery_available
    },
    unspecifiedDeliveryDate() {
      return this.deliveryDateObject && !this.deliveryDateObject.delivery_date
    },
  },
}
</script>
