<template>
  <div class="d-flex flex-column align-center justify-center flex-grow-1">
    <v-spacer />
    <div class="qtm-h1" :class="color" v-text="status" />
    <v-spacer />
    <div v-if="comments" class="text-dark-grey qtm-body w-100">
      Supplier comment:
      <div :class="{ compact }" v-text="comments" />
    </div>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  comments?: string | null
  compact?: boolean
  response?: string
}

const props = defineProps<Props>()

const color = computed(() => {
  if (props.response === 'declined') {
    return 'text-error'
  }
  return 'text-mid-light-grey'
})

const status = computed(() => {
  if (props.response === 'declined') {
    return 'Declined to quote'
  }

  return 'Waiting for quote'
})
</script>

<style scoped>
.compact {
  display: -webkit-box;
  line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
