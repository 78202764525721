<template>
  <td>
    <div class="ml-3 text-left">
      <div v-text="Number(item.quantity)" />
      <div class="text-mid-light-grey text-uppercase" v-text="item.unit" />
    </div>
  </td>
  <td>
    {{ currency(quotePrice) }}
  </td>
  <td>
    {{ bom?.preferred_supplier?.name ?? '-' }}
  </td>
</template>

<script setup lang='ts'>
import type { LineItem } from '@quotetome/materials-api'
import { currency } from '@/models/filters'

interface Props {
  boms: any[]
  item: LineItem
  quotes?: any[]
}

const props = withDefaults(defineProps<Props>(), {
  boms: () => [],
  quotes: () => []
})

const bom = computed(() => (props.boms.find(bo => bo.order_item_identifier === props.item.internal_identifier)))

const quote = computed(() => {
  if (bom.value) {
    return props.quotes.find(q => q.vendor.id === bom.value.preferred_supplier?.id)
  }
  return null
})

const quotePrice = computed(() => {
  return quote.value?.skus.find(sku => sku.reference_identifier === props.item.internal_identifier)?.unit_price
})

</script>
