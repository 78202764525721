<template>
  <qtm-content-block v-bind="$attrs">
    <template v-slot:title>
      <div class="qtm-h1 text-secondary text-capitalize pl-4 mb-n4">
        {{ title }}
      </div>
    </template>
    <v-list>
      <v-list-group
        v-if="formattedApprovals.length > 3"
        v-model="expanded"
        append-icon=""
        color="interactive"
      >
        <template v-slot:activator="{ props: activator }">
          <v-list-item v-bind="activator">
            <v-list-item-title class="text-interactive">
              {{ expanded ? "View Less" : "View More" }}
              <v-icon color="interactive">
                {{ expanded ? "mdi-chevron-up" : "mdi-chevron-down" }}
              </v-icon>
            </v-list-item-title>
          </v-list-item>
        </template>
        <approval-activity-item
          v-for="approval in formattedApprovals.slice(0, -3)"
          :key="approval.id"
          :approval="approval"
        />
      </v-list-group>
      <approval-activity-item
        v-for="approval, i in abridged"
        :key="approval.id"
        :approval="approval"
        :final="i + 1 === abridged.length"
      />
    </v-list>
  </qtm-content-block>
</template>

<script setup lang="ts">
import type { Order, PurchaseOrder } from '@quotetome/materials-api'
import ApprovalActivityItem from '@/components/orders/approval-activity-item.vue'

export interface Props {
  order: Order
  po?: PurchaseOrder
  title?: string
}

const props = withDefaults(defineProps<Props>(), {
  po: undefined,
  title: 'PO Activity',
})

const approvals = ref<any[]>([])
const loading = ref(true)
const expanded = ref(false)

const abridged = computed(() => {
  if (formattedApprovals.value.length <= 3) {
    return formattedApprovals.value
  }

  return formattedApprovals.value.slice(-3)
})

const filteredApprovals = computed(() => {
  if (!props.order.requiresAdjustments && props.po?.pending_revision) {
    const revision = props.po!.latest_change_order!.id

    return approvals.value.filter((approval) => approval.revision === revision)
  }

  return approvals.value
})

const formattedApprovals = computed(() => {
  if (!filteredApprovals.value.length) {
    return []
  }

  const app = filteredApprovals.value[0]
  const first = {
    ...filteredApprovals.value[0],
    id: 'first',
    title: 'Created',
    tag: 'by',
    actionUser: `${app.user.first_name} ${app.user.last_name}`,
  }

  return [first].concat(filteredApprovals.value.map(approval => {
    let title = 'Reviewed'
    let tag = 'by'
    let actionUser = `${approval.user.first_name} ${approval.user.last_name}`

    if (approval.entry_type === 'APR') {
      title = 'Approved'
    }
    else if (approval.entry_type === 'REQ') {
      title = 'Waiting for review/approval'
      tag = 'from'

      if (approval.requested_approver) {
        actionUser = `${approval.requested_approver.first_name} ${approval.requested_approver.last_name}`
      }
      else {
        actionUser = 'a team member'
      }
    }

    return {
      ...approval,
      title,
      tag,
      actionUser,
    }
  }))
})

const { $api, $error } = useNuxtApp()

const fetchApprovals = async () => {
  try {
    approvals.value = await $api.v1.rfqs.approvals(props.order)
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

onMounted(fetchApprovals)
</script>
