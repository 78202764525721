<template>
  <div class="align-center attachment-preview d-flex justify-space-between">
    <component
      :is="componentType"
      v-bind="componentProps"
      class="d-flex align-center cursor-pointer text-decoration-none text-break-word"
    >
      <div v-if="allowSelect" class="mr-2 text-center">
        <qtm-checkbox
          :model-value="modelValue"
          :val="attachment.id"
          @click.stop.prevent
          @update:model-value="$emit('update:model-value', $event)"
        />
      </div>
      <document-icon class="mr-2" :attachment="attachment" :dense="dense" />
      <div class="text-left">
        <div>
          <span class="qtm-body text-dark-grey" v-text="attachment.name" />
          <slot name="name-append" />
        </div>
        <div v-if="!dense" class="qtm-body-small text-mid-dark-grey" v-text="dateUploaded" />
      </div>
    </component>
    <div class="text-no-wrap">
      <slot name="action" :attachment="attachment" />
      <qtm-icon-btn
        v-if="allowPreview"
        icon="mdi-eye"
        title="Preview"
        @click.prevent="$emit('preview', attachment)"
      />
      <delete-btn v-if="withDelete" @click="$emit('delete', attachment)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Attachment } from '@quotetome/materials-api'
import { isoDate } from '~/models/filters'
import DeleteBtn from '@/components/delete-btn.vue'
import DocumentIcon from '@/components/attachments/document-icon.vue'

export interface Props {
  allowPreview?: boolean
  allowSelect?: boolean
  attachment: Attachment
  dense?: boolean
  modelValue?: number[]
  previewOnClick?: boolean
  withDelete?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  allowPreview: false,
  allowSelect: false,
  dense: false,
  modelValue: () => [],
  previewOnClick: false,
  withDelete: false,
})
const emit = defineEmits(['delete', 'preview', 'update:model-value'])

const dateUploaded = computed(() => isoDate(props.attachment.date_uploaded))
const canPreview = computed(() => {
  return props.previewOnClick && /\.(jpeg|jpg|gif|png|pdf)$/.test(props.attachment.url.toLowerCase())
})
const componentProps = computed(() => {
  if (canPreview.value) {
    return {
      onClick: () => emit('preview', props.attachment),
    }
  }

  return {
    download: props.attachment.name,
    href: props.attachment.url,
    target: '_blank',
  }
})
const componentType = computed(() => (canPreview.value ? 'div' : 'a'))
</script>

<style lang="scss" scoped>
.attachment-preview {
  width: 100%;
}
</style>
