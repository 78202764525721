<template>
  <qtm-content-block v-bind="$attrs" title="RFQ Details">
    <v-row no-gutters>
      <v-col class="pr-4" cols="6">
        <details-container :details="orderDetails" label-size="8em">
          <template v-slot:location>
            <div class="text-capitalize" v-text="order.delivery_location" />
            <qtm-address :address="order.address" :city="order.city" :province="order.province" />
          </template>
        </details-container>
      </v-col>

      <v-divider class="my-3" vertical />

      <v-col class="pl-4" cols="6">
        <details-container :details="quoteDetails" label-size="8em" />
      </v-col>
    </v-row>
  </qtm-content-block>
</template>

<script setup lang="ts">
import type { Order } from '@quotetome/materials-api'
import DetailsContainer from '~/components/details-container.vue'
import { conciseDate, objectDeliveryDate } from '~/models/filters'

export interface Props {
  order: Order
}

const props = defineProps<Props>()

const orderDetails = computed(() => [
  { label: 'Delivery Date', text: objectDeliveryDate(props.order), value: 'date' },
  { label: 'Delivery Location', value: 'location' },
])

const quoteDetails = computed(() => [
  { label: 'Quote Due Date', text: conciseDate(props.order.date_closes), value: 'date' },
])
</script>
