<template>
  <v-table>
    <thead class="vendor-table-header">
      <tr>
        <th />
        <th>
          VENDOR
        </th>
        <th>
          CONTACT
        </th>
        <th>
          CITY
        </th>
        <th>
          PROVINCE
        </th>
      </tr>
    </thead>
    <tbody>
      <vendor-invite-item
        v-for="vendor in vendors"
        :key="vendor.id"
        :loading="loading"
        :vendor="vendor"
        @add="$emit('add', $event)"
        @contact-selected="contactSelectedHandler(vendor.quote, $event)"
        @remove="$emit('remove', $event)"
      />
    </tbody>
  </v-table>
</template>

<script>
import VendorInviteItem from '@/components/vendors/vendor-invite-item.vue'

export default {
  name: 'vendor-invite-table',
  components: { VendorInviteItem },
  props: {
    vendors: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    contactSelectedHandler: {
      type: Function,
      default: () => {}
    }
  },
  emits: ['add', 'remove'],
}
</script>

<style lang="scss" scoped>
.vendor-table-header {
  background-color: rgb(var(--v-theme-background));
}

.vendor-table-header > th {
  padding: 0.2rem;
}
</style>
