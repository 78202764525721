<template>
  <span v-text="formattedAddress" />
</template>

<script setup lang="ts">
const props = defineProps<{
  address?: string
  city?: string
  postalCode?: string
  province?: string
}>()

const formattedAddress = computed(() => {
  return [props.address, props.city, props.province, props.postalCode].filter(value => value).join(', ')
})
</script>
