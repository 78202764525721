<template>
  <div
    class="cursor-pointer d-flex flex-column quote-card qtm-border py-4 px-3 text-secondary h-100"
    @click="$emit('click', quote)"
  >
    <quote-awarded-chip v-if="quote.is_winning_quote && !order.requiresAdjustments" :quote="quote" />
    <quote-outdated-chip v-else class="mb-2 me-auto" :quote="quote" />
    <div class="background qtm-h4 pb-4 text-uppercase text-truncate" v-text="quote.vendor?.name" />
    <div v-if="priceGiven" class="d-flex flex-column flex-grow-1">
      <div class="qtm-title-bold">
        {{ totalPrice }}
      </div>
      <quote-attachments-link class="pb-1" :quote="quote" />
      <quote-delivery-status :quote="quote" :order="order" />
      <quote-delivery-warning class="mt-4" :quote="quote" />
      <v-spacer />
      <div v-if="showActions && order.canAwardQuote" class="mt-6 mb-4">
        <quote-items-btn
          block
          :order="order"
          :quote="quote"
          size="x-large"
          @update:order="$emit('update:order', $event)"
        />
      </div>
      <div>
        <qtm-btn
          class="px-0"
          data-test="view-quote"
          density="compact"
          tertiary
          @click="$emit('click', quote)"
        >
          View Quote
        </qtm-btn>
      </div>
    </div>
    <quote-response
      v-else
      :comments="quote.comments"
      compact
      :response="responseReceived"
    />
  </div>
</template>

<script setup lang="ts">
import type { Order, Quote } from '@quotetome/materials-api'
import { currency } from '@/models/filters'
import QuoteAttachmentsLink from '@/components/quotes/quote-attachments-link.vue'
import QuoteAwardedChip from '@/components/quotes/quote-awarded-chip.vue'
import QuoteDeliveryStatus from '@/components/quotes/quote-delivery-status.vue'
import QuoteDeliveryWarning from '@/components/quotes/quote-delivery-warning.vue'
import QuoteItemsBtn from '@/components/quotes/quote-items-btn.vue'
import QuoteOutdatedChip from '@/components/quotes/quote-outdated-chip.vue'
import QuoteResponse from '@/components/quotes/quote-response.vue'

export interface Props {
  order: Order
  quote: Quote
  showActions?: boolean
}

const props = defineProps<Props>()
defineEmits(['click', 'update:order'])

const priceGiven = computed(() => props.quote.response_received === 'price given')
const responseReceived = computed(() => props.quote.response_received as string)
const totalPrice = computed(() => currency(props.quote.total_price))
</script>

<style lang="scss" scoped>
.quote-card {
  min-height: 285px;
}
</style>
