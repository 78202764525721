<template>
  <qtm-dialog-card
    v-bind="$attrs"
    max-width="750"
    title="Change vendor"
    width="750"
    @update:model-value="close"
  >
    <p>Choose a supplier or create a new one</p>
    <v-row class="no-gutters">
      <v-col>
        <supplier-search
          :append-items="vendorItems"
          :model-value="vendor"
          :user="user"
          @update:model-value="vendorSelected"
        />
      </v-col>
      <v-col cols="auto ml-2">
        <qtm-btn size="x-large" @click="showCreateVendorDialog = true">
          <v-icon>mdi-plus</v-icon>
        </qtm-btn>
      </v-col>
    </v-row>
    <create-vendor-dialog
      v-model="showCreateVendorDialog"
      email-required
      vendor-details-required
      with-contact
      @vendor-created="vendorSelected"
    />
    <div v-if="vendor" class="mt-5">
      <p>Now, choose a contact or create a new one</p>
      <v-row class="no-gutters">
        <v-col>
          <contact-select
            v-model="contact"
            email-required
            :vendor="vendor"
            return-object
            @new-contact="contactCreated"
            @update:model-value="modified = true"
          />
        </v-col>
        <v-col v-if="contact" cols="auto ml-2">
          <qtm-icon-btn icon="mdi-pencil" size="x-large" @click="openEditContactModal" />
        </v-col>
      </v-row>
    </div>
    <template v-slot:actions>
      <v-spacer />
      <qtm-btn :loading="loading" tertiary @click="close">
        Cancel
      </qtm-btn>
      <qtm-btn
        size="x-large"
        class="my-3"
        :loading="loading"
        :disabled="!modified"
        @click="changeVendor"
      >
        Change vendor
      </qtm-btn>
    </template>
    <qtm-dialog-card
      v-model="showSendInvitationEmailModal"
      title="Send invitation email"
    >
      <p>
        Send the invitation email to the new vendor?
        (Email address: {{ contact ? contact.email : 'None' }})
      </p>
      <p v-if="quote.date_invited">
        An invitation email for this quote was sent on {{ dateYear(quote.date_invited) }}
      </p>
      <template v-slot:actions>
        <qtm-btn :loading="loading" tertiary @click="showSendInvitationEmailModal = false">
          Cancel
        </qtm-btn>
        <v-spacer />
        <qtm-btn :loading="loading" secondary @click="saveAndClose(false)">
          No
        </qtm-btn>
        <qtm-btn :loading="loading" @click="saveAndClose(true)">
          Yes
        </qtm-btn>
      </template>
    </qtm-dialog-card>
    <edit-contact-dialog
      v-if="contactCopy"
      v-model="showEditContactModal"
      :contact="contactCopy"
      email-required
      :vendor="vendor"
      @update:contact="onContactUpdate"
    />
  </qtm-dialog-card>
</template>

<script setup lang="ts">
import type { Contact, Order, Quote, Vendor } from '@quotetome/materials-api'
import { dateYear } from '~/models/filters'
import ContactSelect from '@/components/vendors/contact-select.vue'
import CreateVendorDialog from '@/components/vendors/create-vendor-dialog.vue'
import EditContactDialog from '@/components/vendors/edit-contact-dialog.vue'
import SupplierSearch from '@/components/search/supplier-search.vue'

export interface Props {
  order: Order
  quote: Quote
}

const props = defineProps<Props>()
const emit = defineEmits(['update:model-value'])

const contact = ref<Contact | null>()
const contactCopy = ref<Contact>()
const loading = ref(false)
const modified = ref(false)
const showCreateVendorDialog = ref(false)
const showEditContactModal = ref(false)
const showSendInvitationEmailModal = ref(false)

const vendor = ref<Vendor>()
const vendorItems = ref<Vendor[]>([])

const user = computed(() => props.order.owner?.id)

const { $api, $error, $toast } = useNuxtApp()

const changeVendor = () => {
  if (!vendor.value || !contact.value) {
    $toast.error('Please choose a vendor and a contact.')
    return
  }

  if (props.order.isRFQ && props.order.state.can_manually_invite_vendor) {
    showSendInvitationEmailModal.value = true
  }
  else {
    saveAndClose(false)
  }
}

const close = () => {
  resetForm()
  emit('update:model-value', false)
}

const contactCreated = (c: Contact) => {
  vendor.value!.contacts.push(c)
  contact.value = c
  modified.value = true
}

const onContactUpdate = (c: Contact) => {
  const index = vendor.value!.contacts.findIndex(c2 => c2.id === c.id)

  vendor.value!.contacts[index] = c
  contact.value = c

  if (props.quote.salesperson?.id === c.id) {
    props.quote.salesperson = c
  }

  modified.value = true
}

const openEditContactModal = () => {
  contactCopy.value = { ...contact.value } as Contact
  showEditContactModal.value = true
}

const resetForm = () => {
  vendorItems.value = [props.quote.vendor as Vendor]
  vendor.value = props.quote.vendor

  if (props.quote.salesperson && !props.quote.vendor!.contacts.length) {
    vendor.value!.contacts.push(props.quote.salesperson)
  }

  contact.value = props.quote.salesperson
  modified.value = false
  showSendInvitationEmailModal.value = false
}

const saveAndClose = async (sendInvitationEmail = false) => {
  loading.value = true

  if (vendor.value && contact.value) {
    try {
      const vendorId = vendor.value.id
      const contactId = contact.value.id

      const updatedQuote = await $api.v1.quotes.changeVendor(
        props.quote.id as number,
        { vendor_id: vendorId, contact_id: contactId, send_invitation_email: sendInvitationEmail }
      )

      props.quote.vendor = updatedQuote.vendor
      props.quote.salesperson = updatedQuote.salesperson
      close()
    }
    catch (error) {
      $error.report(error)
    }
  }
  else {
    $toast.error('Please choose a vendor and a contact.')
  }

  loading.value = false
}

const vendorSelected = async (v: Vendor) => {
  loading.value = true
  try {
    const fullVendor = await $api.v1.vendors.get(v.id)

    vendorItems.value = [fullVendor]
    vendor.value = fullVendor

    if (vendor.value!.contacts.length === 1) {
      contact.value = vendor.value!.contacts[0]
    }
    else {
      contact.value = null
    }

    modified.value = true
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

onMounted(resetForm)
</script>
