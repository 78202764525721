<template>
  <qtm-autocomplete
    v-bind="$attrs"
    ref="input"
    clearable
    hide-details
    :item-title="formatTax"
    :items="taxes"
    :menu-icon="$attrs.modelValue ? '' : undefined"
    placeholder="Select Tax"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
      <slot v-bind="slotData" :key="name" :name="name" />
    </template>
  </qtm-autocomplete>
</template>

<script>

export default {
  name: 'tax-select',
  props: {
    taxes: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    formatTax(item) {
      let tax = item

      if (typeof item === 'number') {
        tax = this.taxes.find(t => t.id === item) || item
      }

      const rate = tax.displayable_rate ?? (tax.rate * 100)
      return `${tax.label} (${rate}%)`
    },
  }
}
</script>
