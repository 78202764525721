<template>
  <qtm-dialog-card v-bind="$attrs" :title="title">
    <slot>
      Are you sure you want to take this action?
    </slot>

    <template v-slot:actions>
      <v-spacer />
      <qtm-btn data-test="confirmation-cancel-button" :loading="loading" size="x-large" tertiary @click="close">
        {{ cancelButtonText }}
      </qtm-btn>
      <qtm-btn
        class="my-2"
        data-test="confirmation-ok-button"
        :loading="loading"
        size="x-large"
        @click="$emit('confirm')"
      >
        {{ okButtonText }}
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script setup lang="ts">
export interface Props {
  title?: string
  okButtonText?: string
  cancelButtonText?: string
  loading?: boolean
}

withDefaults(defineProps<Props>(), {
  title: 'Confirm this Action',
  okButtonText: 'OK',
  cancelButtonText: 'Cancel',
  loading: false
})
const emit = defineEmits(['close', 'confirm', 'update:model-value'])

const close = () => {
  emit('close')
  emit('update:model-value', false)
}
</script>

<!--<style scoped lang="scss">-->
<!--</style>-->
