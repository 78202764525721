import { defineStore } from 'pinia'
import { Order, OrderState } from '@quotetome/materials-api'
import { api } from '~/plugins/materials-api'
import { errorHandler } from '~/plugins/error-handler'

const ORDER_REFRESH_INTERVAL = 60 * 1000 // 1 minute
// request Orders modifed within the last 2 minutes
const MODIFIED_MIN_AGO = 2

interface QBoardOrder extends Order {
  show: boolean
  vendors: any[]
}

function sortOrders(orders: QBoardOrder[]): QBoardOrder[] {
  return orders.sort((a, b) => {
    if (a.priority === 'high' && b.priority === 'high') {
      return Number(a.date_created) - Number(b.date_created)
    }

    if (a.priority === 'high') {
      return -1
    }
    if (b.priority === 'high') {
      return 1
    }

    if (a.delivery_date && b.delivery_date) {
      return Number(a.delivery_date) - Number(b.delivery_date)
    }

    return Number(a.date_created) - Number(b.date_created)
  })
}

let orderRefreshInterval: NodeJS.Timeout | null

interface State {
  activeEmail: any | undefined,
  activeOrder: Order | undefined,
  bouncedEmails: any[],
  emailsFetched: boolean,
  fetchingOrders: boolean,
  globalEmailViewer: {
    show: boolean,
    email: null
  },
  qmails: any,
  qmailsQuery: any,
  orderFetchCount: number,
  orders: QBoardOrder[],
  ordersFetched: boolean,
  unassociatedEmails: any[],
  unreadEmails: any[],
  unreadGeneralInboxEmails: any[],
}

export const useAdminStore = defineStore('admin', {
  state: (): State => ({
    activeEmail: undefined,
    activeOrder: undefined,
    bouncedEmails: [],
    emailsFetched: false,
    fetchingOrders: false,
    globalEmailViewer: {
      show: false,
      email: null
    },
    qmails: { data: [], count: 0 },
    qmailsQuery: { offset: 0, emailType: 'inbound' },
    orderFetchCount: 0,
    orders: [],
    ordersFetched: false,
    unassociatedEmails: [],
    unreadEmails: [],
    unreadGeneralInboxEmails: [],
  }),
  actions: {
    setOrders(orders: QBoardOrder[]) {
      orders.forEach(order => { order.show = true })
      this.orders = sortOrders(orders)
    },

    updateOrder(order: Order) {
      const newOrder = order as QBoardOrder

      newOrder.show = true

      const index = this.orders.findIndex(o => o.id === newOrder.id)

      if (index >= 0) {
        newOrder.vendors = newOrder.vendors || this.orders[index].vendors

        if (newOrder.accounting_success === undefined) {
          if (newOrder.hasPurchaseOrder && newOrder.pos[0].latest_change_order?.accounting_success !== undefined) {
            newOrder.accounting_success = newOrder.pos[0].latest_change_order.accounting_success as boolean
          }
          else {
            newOrder.accounting_success = this.orders[index].accounting_success
          }
        }

        this.orders[index] = newOrder

        if (newOrder.priority === 'high') {
          this.orders = sortOrders(this.orders as QBoardOrder[])
        }
      }
      else if (!this.fetchingOrders) {
        const orders = this.orders
        orders.push(newOrder)
        this.orders = sortOrders(orders as QBoardOrder[])
      }

      if (this.activeOrder && this.activeOrder.id === newOrder.id) {
        this.activeOrder = newOrder
      }
    },

    removeOrder(removedOrder: Order) {
      this.orders = this.orders.filter(order => order.id !== removedOrder.id)
    },

    updateChangedOrders(orders: QBoardOrder[]) {
      const stateOrders = this.orders.filter(order1 => !orders.some(order2 => order1.id === order2.id))
      const updatedOrders = orders.filter(order => order.isArchived === false)
      updatedOrders.forEach(order => { order.show = true })
      this.orders = sortOrders(stateOrders.concat(updatedOrders) as QBoardOrder[])
    },

    activateEmail(email: any) {
      this.activeEmail = email
    },

    activateOrder(order?: Order) {
      this.activeOrder = order
    },

    addOrder(order: Order) {
      const newOrder = order as QBoardOrder

      newOrder.show = true

      const orders = this.orders
      orders.push(newOrder)
      this.orders = sortOrders(orders as QBoardOrder[])
    },

    pushOrders(orders: QBoardOrder[]) {
      orders.forEach(order => { order.show = true })
      this.orders = sortOrders(this.orders.concat(orders) as QBoardOrder[])
    },

    setUnassociatedEmails(emails: any[]) {
      this.unassociatedEmails = emails
    },

    removeUnassociatedEmail(emailToRemove: any) {
      this.unassociatedEmails = this.unassociatedEmails.filter(email => email.id !== emailToRemove.id)
    },

    setUnreadEmails(emails: any[]) {
      this.unreadEmails = emails
    },

    setUnreadGeneralInboxEmails(emails: any[]) {
      this.unreadGeneralInboxEmails = emails
    },

    removeUnreadEmail(emailToRemove: any) {
      if (emailToRemove.is_general_inbox) {
        this.unreadGeneralInboxEmails = this.unreadGeneralInboxEmails.filter(email => email.id !== emailToRemove.id)
      }
      else {
        this.unreadEmails = this.unreadEmails.filter(email => email.id !== emailToRemove.id)
      }
    },

    removeUnreadGeneralInboxEmail(emailToRemove: any) {
      this.unreadGeneralInboxEmails = this.unreadGeneralInboxEmails.filter(email => email.id !== emailToRemove.id)
    },

    setBouncedEmails(emails: any[]) {
      this.bouncedEmails = emails
    },

    removeBouncedEmail(emailToRemove: any) {
      this.bouncedEmails = this.bouncedEmails.filter(email => email.id !== emailToRemove.id)
    },

    markEmailsFetched() {
      this.emailsFetched = true
    },

    markOrdersFetched() {
      this.ordersFetched = true
    },

    setFetchingOrders(value: boolean) {
      this.fetchingOrders = value
    },

    setQmails(qmails: any) {
      this.qmails = qmails
    },

    setQmailsQuery(query: any) {
      this.qmailsQuery[query.name] = query.value
    },

    setOrderFetchCount(count: number) {
      this.orderFetchCount = count
    },

    showGlobalEmailViewer(email: any) {
      this.globalEmailViewer = { show: true, email }
    },

    hideGlobalEmailViewer() {
      this.globalEmailViewer = { show: false, email: null }
    },

    async initializeQBoard() {
      const orderRefresh = () => {
        const FETCH_LIMIT = 30
        const fetchCount = this.orderFetchCount

        if (FETCH_LIMIT && fetchCount >= FETCH_LIMIT) {
          this.setOrderFetchCount(0)
          api.v1.rfqs.list({
            excludeStates: OrderState.adminExcludeStates(),
            output: 'qboard',
          }).then(orders => this.setOrders(orders))
            .catch(errorHandler.report)
        }
        else {
          api.v1.rfqs.list({
            modifiedMinAgo: MODIFIED_MIN_AGO,
            output: 'qboard',
          }).then(orders => {
            this.updateChangedOrders(orders)
            this.setOrderFetchCount(fetchCount + 1)
          }).catch(errorHandler.report)
        }
      }

      if (!this.ordersFetched && !this.fetchingOrders) {
        this.setFetchingOrders(true)
        this.setOrders([])

        const pagination = { limit: 30, offset: 0 }
        let moreOrders = true

        try {
          while (moreOrders) {
            // Allow awaiting in this loop to throttle connections to the API
            // eslint-disable-next-line no-await-in-loop
            const orders = await api.v1.rfqs.list({
              excludeStates: OrderState.adminExcludeStates(),
              ordering: 'state__internal_sort_order,delivery_date,date_created',
              output: 'qboard',
              pagination,
            } as any).then(response => response.data.map((order:any) => new Order(order, { completeData: false })))

            this.pushOrders(orders)
            pagination.offset += orders.length

            moreOrders = orders.length >= pagination.limit
          }

          this.markOrdersFetched()
          this.setFetchingOrders(false)

          if (orderRefreshInterval) {
            clearInterval(orderRefreshInterval)
          }

          orderRefreshInterval = setInterval(orderRefresh, ORDER_REFRESH_INTERVAL)
        }
        catch (error) {
          errorHandler.report(error)
        }
      }
    }
  }
})

export default useAdminStore
