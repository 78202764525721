<template>
  <qtm-content-block collapsible title="Quote Information">
    <v-row no-gutters>
      <v-col v-bind="cols">
        <qtm-input-label label="Quote Valid Until">
          <date-picker clearable :model-value="validUntil" @update:model-value="$emit('update:valid-until', $event)" />
        </qtm-input-label>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col v-bind="cols">
        <qtm-input-label label="Supplier Reference">
          <qtm-text-field
            :maxlength="supplierRefMaxLength"
            :model-value="supplierReference"
            @blur="checkForDuplicates"
            @update:model-value="$emit('update:supplier-reference', $event)"
          />
          <order-reference-duplicates ref="duplicates" :order-id="order.id" :supplier-id="supplierId" />
        </qtm-input-label>
      </v-col>
    </v-row>
  </qtm-content-block>
</template>

<script>
import { SUPPLIER_REFERENCE_MAX_LENGTH } from '@/constants'
import DatePicker from '@/components/date-picker.vue'
import OrderReferenceDuplicates from '@/components/orders/order-reference-duplicates.vue'

export default {
  name: 'quote-order-info',
  components: { DatePicker, OrderReferenceDuplicates },
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      required: true,
    },
    supplierId: {
      type: Number,
      default: undefined
    },
    supplierReference: {
      type: String,
      default: ''
    },
    validUntil: {
      type: Object,
      default: null
    },
  },
  emits: ['update:supplier-reference', 'update:valid-until'],
  data() {
    return {
      supplierRefMaxLength: SUPPLIER_REFERENCE_MAX_LENGTH,
    }
  },
  computed: {
    cols() {
      if (this.fullWidth) {
        return { cols: 12 }
      }

      return { cols: 12, sm: 8, lg: 6 }
    },
  },
  methods: {
    checkForDuplicates() {
      this.$refs.duplicates.checkForDuplicates(this.supplierReference)
    },
  }
}
</script>
