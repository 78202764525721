export default function useCostCodes(jobsiteId?: number) {
  const nuxtApp = useNuxtApp()
  const costCodes = ref<any[]>([])
  const fetchCostCodes = (id: number) => nuxtApp.$api.v1.jobsites.costCodes(id)
    .then((data: any[]) => { costCodes.value = data })
    .catch(nuxtApp.$error.report)

  if (jobsiteId) {
    onMounted(() => { fetchCostCodes(jobsiteId) })
  }

  return { costCodes, fetchCostCodes }
}
