<template>
  <v-icon :color="color" :title="title">
    {{ icon }}
  </v-icon>
</template>

<script setup lang="ts">
import type { Quote } from '@quotetome/materials-api'

const props = defineProps<{
  quote: Quote
}>()

const options: { [key: string]: { color?: string, icon: string, title: string } } = {
  'awaiting price': { icon: 'mdi-update', title: 'Awaiting Price' },
  'declined': { color: 'error', icon: 'mdi-close-circle-outline', title: 'Declined' },
  'price given': { color: 'success', icon: 'mdi-check-circle-outline', title: 'Price Given' },
  'qa': { color: 'primary', icon: 'mdi-magnify-scan', title: 'QA Required' },
}

const color = computed(() => options[props.quote.response_received || '']?.color)
const icon = computed(() => options[props.quote.response_received || '']?.icon || 'mdi-help-circle-outline')
const title = computed(() => options[props.quote.response_received || '']?.title || 'Unknown')
</script>

<!--<style scoped lang="scss">-->
<!--</style>-->
