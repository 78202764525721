<template>
  <div class="bg-grey-lighten-2 printable-po-preview">
    <qtm-skeleton v-if="loading" />
    <div v-else-if="pdf" class="py-1">
      <vue-pdf-embed v-bind="pdfEmbedAttrs" :page="1" @loaded="onLoad" />
      <div v-if="additionalPages">
        <vue-pdf-embed v-for="i in additionalPages" :key="i" v-bind="pdfEmbedAttrs" :page="i + 1" />
      </div>
    </div>
    <v-alert v-else type="error">
      Failed to fetch purchase order PDF
    </v-alert>
  </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  name: 'printable-po-preview',
  components: { VuePdfEmbed },
  props: {
    changeOrderData: {
      type: Object,
      default: undefined
    },
    company: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: undefined
    },
    poData: {
      type: Object,
      default: undefined
    },
    quote: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default: undefined
    },
  },
  data() {
    return {
      loading: true,
      pdf: undefined,
      additionalPages: 0,
    }
  },
  computed: {
    pdfEmbedAttrs() {
      return {
        class: 'elevation-1 ma-3',
        disableAnnotationLayer: true,
        disableTextLayer: true,
        source: this.pdf,
      }
    },
  },
  mounted() {
    this.fetchPdf()
  },
  beforeUnmount() {
    if (this.pdf) {
      window.URL.revokeObjectURL(this.pdf)
    }
  },
  methods: {
    async fetchPdf() {
      let apiMethod = this.$api.v1.purchaseOrders.pdf
      let data
      let id = this.id

      this.loading = true
      if (this.poData) {
        apiMethod = this.$api.v1.previewPurchaseOrders.create
        data = this.poData
      }
      else if (this.changeOrderData) {
        apiMethod = this.$api.v1.previewPurchaseOrders.previewChangeOrderPO
        data = this.changeOrderData
      }
      else if (this.company) {
        apiMethod = this.$api.v1.companies.previewPO
      }
      else if (this.order) {
        id = this.order.winningQuotes[0].purchase_order
      }
      else if (this.quote) {
        apiMethod = this.$api.v1.quotes.previewPO
      }

      try {
        if (apiMethod && id) {
          const pdfBlob = await apiMethod(id, data)

          this.pdf = window.URL.createObjectURL(pdfBlob)
        }
        else if (apiMethod && data) {
          const pdfBlob = await apiMethod(data)

          this.pdf = window.URL.createObjectURL(pdfBlob)
        }
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
    onLoad(pdfProxy) {
      this.additionalPages = pdfProxy.numPages - 1
    },
  }
}
</script>
