<template>
  <qtm-dialog-card
    v-bind="$attrs"
    :model-value="modelValue"
    persistent
    title="Edit Contact"
    width="450"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <contact-form
      v-if="localContact"
      ref="form"
      :contact="localContact"
      :vendor="vendor"
      :email-required="emailRequired"
      :vendor-contacts="vendor?.contacts"
    />

    <template v-slot:actions>
      <v-spacer />
      <qtm-btn :loading="loading" tertiary @click="$emit('update:model-value', false)">
        Cancel
      </qtm-btn>
      <qtm-btn :loading="loading" @click="save">
        Save
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script setup lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import ContactForm from '@/components/vendors/contact-form.vue'

export interface Props {
  contact: any
  modelValue: boolean
  vendor: any
  emailRequired?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['update:contact', 'update:model-value'])

const loading = ref(false)
const localContact = ref<any>(null)

const { $api, $error, $toast } = useNuxtApp()
const form = ref<typeof ContactForm>()

const save = async () => {
  if (!form.value?.isValid()) {
    $toast.error('Please correct the errors')
    return
  }

  loading.value = true
  const data = {
    email: localContact.value.email,
    first_name: localContact.value.first_name,
    last_name: localContact.value.last_name,
    phone_extension: localContact.value.phone_extension,
    phone_number: localContact.value.phone_number,
  }
  try {
    if (typeof props.contact.id === 'number') {
      const contact = await $api.v1.vendors.updateContact(props.contact.id, data)

      emit('update:contact', contact)
    }
    else {
      emit('update:contact', localContact.value)
    }

    emit('update:model-value', false)
  }
  catch (error) {
    $error.report(error)
  }

  loading.value = false
}

watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      localContact.value = cloneDeep(props.contact)
    }
  },
  { immediate: true },
)
</script>
