<template>
  <div class="wrapper" :class="{ 'bg-white document-border': !isImage }">
    <v-hover v-slot="{ isHovering, props: hoverProps }" :disabled="!shouldDownload">
      <div v-bind="hoverProps" class="attachment-preview-download" :title="attachment.name" @click="emitClick">
        <v-img
          v-if="isImage"
          :alt="attachment.name"
          cover
          :height="height"
          :src="attachment.url"
        />
        <div v-else class="pa-1 document-wrapper">
          <div>
            <document-icon :attachment="attachment" dense />
          </div>
          <div class="qtm-overline name mt-2" v-text="attachment.name" />
        </div>
        <v-overlay
          class="align-center justify-center"
          scrim="grey"
          contained
          opacity="0.8"
          :model-value="Boolean(isHovering)"
        >
          <a class="d-inline-block py-7 text-decoration-none" :href="attachment.url" target="_blank">
            <div class="text-center">
              <v-icon color="interactive">
                mdi-download
              </v-icon>
            </div>
            <div class="font-weight-bold text-interactive">
              Download
            </div>
          </a>
        </v-overlay>
      </div>
    </v-hover>
  </div>
</template>

<script setup lang="ts">
import type { Attachment } from '@quotetome/materials-api'
import DocumentIcon from '@/components/attachments/document-icon.vue'
import useAttachments from '@/composables/attachments'

export interface Props {
  attachment: Attachment,
  downloadOnly?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['click'])

const { isImage, isPDF } = useAttachments(props.attachment.url)
const shouldDownload = computed(() => props.downloadOnly || (!isImage.value && !isPDF.value))

const emitClick = () => {
  if (!shouldDownload.value) {
    emit('click')
  }
}

const height = 92
const heightPx = `${height}px`
</script>

<style lang="scss" scoped>
$width: 88px;

.attachment-preview-download {
  cursor: pointer;
  display: inline-block;
  max-height: v-bind(heightPx);
  max-width: $width;
  min-height: v-bind(heightPx);
  min-width: $width;
}

.document-border {
  border: 1px solid rgb(var(--v-theme-light-teal));
}

.name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow-y: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.wrapper {
  display: inline-block;
  max-height: v-bind(heightPx);
  max-width: $width;
  min-height: v-bind(heightPx);
  min-width: $width;
  position: relative;
}
</style>
