<template>
  <qtm-text-field
    v-maska="options"
    v-bind="$attrs"
    :error-messages="errorMessages"
    :placeholder="mask"
  />
</template>

<script setup lang="ts">
import { vMaska } from 'maska/vue'
import { PHONE_MASK } from '@/constants'

export interface Props {
  error?: boolean
  errorMessage?: string
  mask?: string
}

const props = withDefaults(defineProps<Props>(), {
  error: false,
  errorMessage: 'A 10 digit number is required',
  mask: PHONE_MASK,
})

const options = computed(() => ({
  mask: props.mask,
}))
const errorMessages = computed(() => (props.error ? props.errorMessage : undefined))
</script>
