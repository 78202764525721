import { v4 as uuidv4 } from 'uuid'
import { QUANTITY_DECIMAL_PLACES, SKU_NAME_LENGTH, UNIT_PRICE_DECIMAL_PLACES } from '~/constants'

interface Item {
  id: string
  cost_code?: string
  description: string
  has_rental_duration: boolean
  quantity: number
  rental_duration: number | null
  rental_duration_unit: string | null
  total_price: number
  unit: string
  unit_price: number | null
}

export function parseFromSummary(results: any, key: string): string | undefined {
  for (const doc of results.ExpenseDocuments) {
    for (const field of doc.SummaryFields) {
      if (field.Type?.Text === key && field.ValueDetection?.Text) {
        return field.ValueDetection.Text
      }
    }
  }
  return undefined
}

const extractNumber = (text: string): string => {
  const cleanText = text.replace(/[^0-9.\-(]+/g, '').replace('(', '-')

  return cleanText.match(/-?\d+\.?\d*/)?.[0] ?? '0'
}

function removeLastDash(str: string): string {
  return str.replace(/(?: -|\n- -|\n-|-)+$/, '')
}

export default function parseItemAnalysis(
  results: any,
  defaultUnit: string,
  scanItemsSku: boolean,
  defaultCostCode?: string,
): any[] {
  const items: any[] = []

  results.ExpenseDocuments?.forEach((doc: any) => {
    doc.LineItemGroups?.forEach((group: any) => {
      group.LineItems?.forEach((item: any) => {
        const newItem: Item = {
          id: uuidv4(),
          cost_code: defaultCostCode,
          description: '',
          has_rental_duration: false,
          quantity: 1,
          rental_duration: null,
          rental_duration_unit: null,
          total_price: 0,
          unit: defaultUnit,
          unit_price: null,
        }
        let productCode = null

        item.LineItemExpenseFields?.forEach((field: any) => {
          if (!field.ValueDetection?.Text || !field.Type.Text) {
            return
          }

          if (field.Type.Text === 'PRODUCT_CODE') {
            productCode = removeLastDash(field.ValueDetection.Text)
          }

          if (field.Type.Text === 'ITEM') {
            newItem.description = removeLastDash(field.ValueDetection.Text.slice(0, SKU_NAME_LENGTH))
          }
          else if (field.Type.Text === 'QUANTITY') {
            const quantity = extractNumber(field.ValueDetection.Text)
            const floatQuantity = Number.parseFloat(quantity)

            if (!Number.isNaN(floatQuantity)) {
              const intQuantity = Number.parseInt(quantity, 10)
              const decimals = intQuantity === floatQuantity ? 0 : QUANTITY_DECIMAL_PLACES

              newItem.quantity = Number(floatQuantity.toFixed(decimals))
            }
          }
          else if (field.Type.Text === 'PRICE') {
            newItem.total_price = Number(extractNumber(field.ValueDetection.Text))
          }

          if (newItem.quantity && newItem.total_price) {
            const unitPrice = newItem.total_price / newItem.quantity

            if (Number.isFinite(unitPrice)) {
              newItem.unit_price = Number(unitPrice.toFixed(UNIT_PRICE_DECIMAL_PLACES))
            }
          }
        })

        if (newItem.description) {
          if (scanItemsSku && productCode && !newItem.description.includes(productCode)) {
            newItem.description = `${productCode} | ${newItem.description}`
          }
          items.push(newItem)
        }
      })
    })
  })

  return items
}
