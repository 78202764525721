<template>
  <tr>
    <td>
      <delete-btn @click="$emit('remove', vendor)" />
    </td>
    <td>
      {{ vendor.name }}
    </td>
    <td>
      <contact-select
        :model-value="vendor.contact"
        :vendor="vendor"
        @new-contact="$emit('contact-selected', $event)"
        @update:model-value="$emit('contact-selected', $event)"
      />
    </td>
    <td>
      {{ vendor.city }}
    </td>
    <td>
      {{ vendor.province }}
    </td>
  </tr>
</template>

<script>
import ContactSelect from '@/components/vendors/contact-select.vue'
import DeleteBtn from '@/components/delete-btn.vue'

export default {
  name: 'vendor-invite-item',
  components: { ContactSelect, DeleteBtn },
  props: {
    vendor: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['contact-selected', 'remove'],
}
</script>
