const interactiveColor = '#00829b'
const lightTheme = {
  // Vuetify Theme Names
  'primary': '#ff6600',
  'primary-lighten-1': '#FFD1B3',
  'primary-darken-1': '#993E00',
  'secondary': '#003a5d',
  'secondary-lighten-1': '#5c8197',
  'error': '#cc0000',
  'anchor': interactiveColor,

  // QTM Theme Names
  'background': '#f5f6f6',
  'interactive': interactiveColor,
  'interactive-lighten-1': '#e8f0f1',
  'dark-grey': '#111721',
  'mid-dark-grey': '#41454d',
  'mid-grey': '#53565a',
  'mid-light-grey': '#a0a2a6',
  'light-grey': '#ddddde',
  'light-teal': '#cedfd7',
  'sand': '#eadec4',
}
const darkTheme = {
  ...lightTheme,
  'primary': '#007aff',
  'background': '#1e1e1e',
  'anchor': '#a2a2a2',
}

const variables = {
  'high-emphasis-opacity': 1,
  'medium-emphasis-opacity': 1,
}

export default {
  theme: {
    defaultTheme: 'qtmLight',
    themes: {
      qtmLight: {
        dark: false,
        colors: lightTheme,
        variables,
      },
      qtmDark: {
        dark: true,
        colors: darkTheme,
        variables,
      },
    },
  },
  breakpoint: {
    thresholds: {
      lg: 1921, // don't break to xl until after 1080p resolution
    }
  }
}
