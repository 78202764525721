<template>
  <v-menu
    v-model="showUsers"
    :close-on-content-click="false"
    content-class="qtm-border"
  >
    <template v-slot:activator="{ props: menuProps }">
      <v-btn
        v-bind="menuProps"
        color="secondary"
        data-test="change-owner-btn"
        icon="mdi-pencil"
        :loading="loading"
        size="small"
        variant="text"
        @click="fetchTeam"
      />
    </template>
    <qtm-autocomplete
      v-model="altOwner"
      autofocus
      class="owner-autocomplete"
      hide-details
      :items="filteredTeam"
      :item-title="userText"
      :loading="loading"
      placeholder="Select Owner"
      return-object
      @update:model-value="onChange"
    />
  </v-menu>
</template>

<script setup lang="ts">
import type { Jobsite, User } from '@quotetome/materials-api'

export interface Props {
  project?: Jobsite
  owner: User
}

const props = defineProps<Props>()
const emit = defineEmits(['update:owner'])

const altOwner = ref(undefined)
const loading = ref(false)
const showUsers = ref(false)
const team = ref<User[]>([])

const filteredTeam = computed(() => team.value.filter(user => user.id !== props.owner.id))

const userText = (user: User) => `${user.first_name} ${user.last_name}`

const { $api, $error } = useNuxtApp()
const fetchTeam = async () => {
  if (!props.project) {
    return
  }

  loading.value = true
  try {
    team.value = await $api.v1.users.list({ jobsite: props.project?.id })
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

const onChange = (user: User) => {
  showUsers.value = false

  if (user) {
    emit('update:owner', user)
  }

  nextTick(() => {
    altOwner.value = undefined
  })
}
</script>

<style lang="scss" scoped>
.owner-autocomplete {
  width: 400px;
}
</style>
