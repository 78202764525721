<template>
  <tr class="cart-items-row">
    <td class="number-cell px-2 py-4">
      <div class="d-flex justify-space-between flex-nowrap text-no-wrap">
        <v-menu v-if="!disabled" content-class="qtm-border">
          <template v-slot:activator="{ props: activator }">
            <qtm-icon-btn
              v-bind="activator"
              class="item-btn"
              color="mid-light-grey"
              density="compact"
              icon="mdi-plus"
              size="sm"
              tile
            />
          </template>
          <v-list class="py-0 text-interactive">
            <v-list-item v-if="rearrangeable" @click="$emit('insert', 'above')">
              <v-list-item-title>
                Add Item Above
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="rearrangeable" @click="$emit('insert', 'below')">
              <v-list-item-title>
                Add Item Below
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="transform">
              <v-list-item-title>
                <v-icon>
                  {{ `mdi-format-vertical-align-${ rental ? 'top' : 'bottom'}` }}
                </v-icon>
                Move to {{ rental ? 'Purchase': 'Rental' }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="$emit('remove', item)">
              <v-list-item-title>
                <v-icon>
                  mdi-trash-can-outline
                </v-icon>
                Delete
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div class="ms-auto">
          <div v-if="rearrangeable" class="handle">
            <v-icon icon="mdi-dots-vertical" />
            <v-icon class="handle-right mr-n2" icon="mdi-dots-vertical" />
          </div>
          <span :class="{ 'item-number': rearrangeable }" v-text="item[itemNumberField]" />
        </div>
      </div>
    </td>
    <editable-cell
      ref="descriptionCell"
      v-model.trim="v$.item.description.$model"
      auto-grow
      :autofocus="!item.description"
      class="description-cell"
      component="qtm-textarea"
      data-test="description-input"
      density="compact"
      :disabled="disabled || fromBom"
      :error="!!errors.item.description"
      hide-details
      :maxlength="SKU_NAME_LENGTH"
      no-resize
      placeholder="Item Description"
      rows="1"
    >
      <template v-if="fromBom" v-slot:prepend>
        <v-icon class="mr-n2" color="mid-grey" icon="mdi-link" />
      </template>
    </editable-cell>

    <editable-cell
      v-model="v$.item.quantity.$model"
      class="quantity-cell"
      component="qtm-number-field"
      data-test="quantity-input"
      density="compact"
      :disabled="disabled"
      :error="!!errors.item.quantity"
      hide-details
      :maxlength="QUANTITY_MAX_LENGTH"
    />

    <template v-if="rental">
      <editable-cell
        v-model.trim="v$.item.rental_duration.$model"
        class="rental-duration"
        component="qtm-number-field"
        data-test="rental-duration-input"
        density="compact"
        :disabled="disabled"
        :error="!!errors.item.rental_duration"
        hide-details
        :maxlength="DURATION_MAX_LENGTH"
      />

      <editable-cell
        v-model="item.rental_duration_unit"
        class="rental-unit"
        component="qtm-select"
        density="compact"
        :disabled="disabled"
        hide-details
        item-value="value"
        :items="rentalDurationChoices"
      />
    </template>

    <editable-cell
      v-if="!rental || includeUnitsColumnInRentals"
      v-model.trim="v$.item.unit.$model"
      class="unit-cell"
      :component="useTextInputForUnits ? 'qtm-text-field' : 'qtm-select'"
      density="compact"
      :disabled="disabled"
      :error="!!errors.item.unit"
      hide-details
      item-title="description"
      item-value="code"
      :items="units"
      :maxlength="SKU_UNIT_LENGTH"
    />

    <template v-if="withPrices">
      <template v-if="rental">
        <editable-cell
          v-for="(unit, i) in rentalUnits"
          :key="i"
          v-model.trim="v$.item[`${unit}_rate`].$model"
          class="rate-cell"
          component="qtm-number-field"
          :data-test="`${unit}-rate-input`"
          density="compact"
          :disabled="disabled"
          :error="!!errors.item[`${unit}_rate`]"
          hide-details
          :maxlength="PRICE_MAX_LENGTH"
        />
        <td class="bg-background rate-cell px-2 py-4">
          <item-price-estimate :item="item" />
          <v-tooltip activator="parent" location="top" offset="4px">
            Total is calculated based on the cheapest rate
          </v-tooltip>
        </td>
      </template>
      <template v-else>
        <editable-cell
          v-model.trim="v$.item.unit_price.$model"
          class="unit-rate-cell"
          component="qtm-number-field"
          data-test="unit-price-input"
          density="compact"
          :disabled="disabled"
          :error="!!errors.item.unit_price"
          hide-details
          :maxlength="PRICE_MAX_LENGTH"
        />
        <editable-cell
          v-model="v$.item.unit_price.$model"
          class="rate-cell"
          :component="TotalPriceHelper"
          :disabled="disabled"
          :quantity="item.quantity"
        />
      </template>
    </template>

    <editable-cell
      v-if="withCostCode"
      v-model.trim="v$.item.cost_code.$model"
      class="cost-code-cell"
      :component="CostCodeSelect"
      :cost-codes="costCodes"
      density="compact"
      :disabled="disabled"
      :error="!!errors.item.cost_code"
      :force-select="forceCostCodeSelect"
      @blur="$emit('cost-code-changed', item.cost_code)"
    >
      <template v-slot:selection="{ item: costCodeItem }">
        <div v-if="costCodeItem" v-text="costCodeItem.value" />
      </template>
    </editable-cell>

    <editable-cell
      v-if="showTaxes"
      v-model="item.tax"
      class="tax-cell"
      :component="TaxSelect"
      density="compact"
      :disabled="disabled"
      hide-details
      :taxes="taxes"
      @update:model-value="$emit('tax-changed', $event)"
    >
      <template v-slot:selection="{ item: taxItem }">
        <div v-if="taxItem" v-text="taxItem.raw.label" />
      </template>
    </editable-cell>

    <editable-cell
      v-model.trim="item.comment"
      auto-grow
      class="comment-cell"
      component="qtm-textarea"
      density="compact"
      :disabled="disabled"
      hide-details
      :maxlength="SKU_NAME_LENGTH"
      placeholder="Comment"
      rows="1"
    />

    <td v-if="!disabled" class="px-2 py-4 text-center">
      <delete-btn @click="$emit('remove', item)" />
    </td>
  </tr>
</template>

<script setup lang="ts">
import {
  decimal, integer, minValue, required, requiredIf
} from '@vuelidate/validators'
import {
  DURATION_MAX_LENGTH,
  PRICE_MAX_LENGTH,
  QUANTITY_MAX_LENGTH,
  SKU_NAME_LENGTH,
  SKU_UNIT_LENGTH,
  UNIT_PRICE_DECIMAL_PLACES,
} from '@/constants'
import { decimalPlaces } from '@/validators'
import CostCodeSelect from '@/components/cost-codes/cost-code-select.vue'
import DeleteBtn from '@/components/delete-btn.vue'
import EditableCell from '@/components/editable-cell.vue'
import ItemPriceEstimate from '@/components/items/item-price-estimate.vue'
import TaxSelect from '@/components/orders/tax-select.vue'
import TotalPriceHelper from '@/components/skus/total-price-helper.vue'
import useValidation from '@/composables/validation'

export interface Props {
  boms?: any[]
  costCodes?: any[]
  disabled?: boolean
  forceCostCodeSelect?: boolean
  includeUnitsColumnInRentals?: boolean
  item: any
  itemNumberField?: string
  rearrangeable?: boolean
  rental?: boolean
  requireCostCodes?: boolean
  taxes?: any[]
  units?: any[]
  validateUnits?: boolean
  withCostCode?: boolean
  withPrices?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  boms: () => [],
  costCodes: () => [],
  disabled: false,
  forceCostCodeSelect: false,
  includeUnitsColumnInRentals: false,
  itemNumberField: 'index',
  rearrangeable: false,
  rental: false,
  requireCostCodes: false,
  taxes: () => [],
  units: () => [],
  validateUnits: false,
  withCostCode: false,
  withPrices: false,
})
defineEmits(['cost-code-changed', 'insert', 'remove', 'tax-changed'])

const rules = {
  item: {
    cost_code: {
      required: requiredIf(() => props.withCostCode && props.requireCostCodes),
      validChoice: (costCode: string) => {
        return !costCode || !props.forceCostCodeSelect || props.costCodes.some((code) => code.cost_code === costCode)
      },
    },
    day_rate: {
      decimalPlaces: decimalPlaces(2),
      minValue: minValue(0),
      required: requiredIf(() => {
        return props.rental && props.withPrices && !props.item.week_rate && !props.item.month_rate
      }),
    },
    description: {
      required,
    },
    month_rate: {
      decimalPlaces: decimalPlaces(2),
      minValue: minValue(0),
      required: requiredIf(() => {
        return props.rental && props.withPrices && !props.item.day_rate && !props.item.week_rate
      }),
    },
    quantity: {
      decimal,
      decimalPlaces: decimalPlaces(2),
      required,
    },
    rental_duration: {
      integer,
      minValue: minValue(0),
      required: requiredIf(() => props.rental),
    },
    unit: {
      required: requiredIf(() => !props.rental),
      validChoice: (item: string) => {
        return props.units.length === 0 || props.units.some(unit => unit.code === item)
      },
    },
    unit_price: {
      decimal,
      decimalPlaces: decimalPlaces(UNIT_PRICE_DECIMAL_PLACES),
      minValue: minValue(0),
      required: requiredIf(() => !props.rental && props.withPrices),
    },
    week_rate: {
      decimalPlaces: decimalPlaces(2),
      minValue: minValue(0),
      required: requiredIf(() => {
        return props.rental && props.withPrices && !props.item.day_rate && !props.item.month_rate
      }),
    },
  },
}

const { errors, isValid: checkIsValid, v$ } = useValidation({ rules, state: toRefs(props) })

const isValid = () => {
  const valid = checkIsValid()

  if (!valid) {
    document.querySelector('.editable-cell.error')?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }

  return valid
}

const rentalDurationChoices = [
  { text: 'Days', value: 'days' },
  { text: 'Weeks', value: 'weeks' },
  { text: '28-Day Months', value: 'months' },
]
const rentalUnits = ['day', 'week', 'month']

const fromBom = computed(() => {
  return props.item.fromBom || props.item.bom_item || props.boms.some(b => (
    b.purchase_order_item?.id === props.item.id || b.order_item_identifier === props.item.internal_identifier
  ))
})
const useTextInputForUnits = computed(() => !props.units || !props.units.length)
const showTaxes = computed(() => !!props.taxes.length)

watch(() => props.units, (newUnitsArray) => {
  if (!newUnitsArray || !newUnitsArray.length || !props.item.unit) { return }

  // Convert description to code if applicable
  const unitByCode = newUnitsArray.find(u => u.code === props.item.unit)

  if (!unitByCode) {
    const unitByDescription = newUnitsArray.find(u => u.description === props.item.unit)

    if (unitByDescription) {
      props.item.unit = unitByDescription.code
    }
  }
})

const descriptionCell = ref<InstanceType<typeof EditableCell>>()
const focusDescription = () => {
  descriptionCell.value?.$el.scrollIntoView({
    behavior: 'auto',
    block: 'center',
    inline: 'end',
  })
}
const transform = () => {
  let unitPrice = null

  if (props.rental) {
    unitPrice = props.item.unit_price || props.item.month_rate
  }
  else if (props.item.month_rate) {
    unitPrice = props.item.month_rate
  }
  else if (props.item.unit_price) {
    unitPrice = Number.parseFloat(props.item.unit_price).toFixed(2)
  }

  if (props.rental) {
    props.item.rental_duration_unit = null
    props.item.unit_price = unitPrice
    props.item.day_rate = null
    props.item.week_rate = null
    props.item.month_rate = null
    props.item.rental_duration = null
  }
  else {
    props.item.rental_duration_unit = 'days'
    props.item.month_rate = unitPrice
    props.item.rental_duration = props.item.rental_duration ?? 1
    props.item.unit_price = null
    props.item.rental_price = { price: unitPrice }
  }
}

defineExpose({ focusDescription, isValid })
</script>

<style lang="scss" scoped>
td {
  padding: 0.25rem;
}

tr.cart-items-row > td {
  background-color: white;
}

.number-cell {
  min-width: 80px;
  max-width: 80px;
  width: 80px;
}

.description-cell {
  min-width: 290px;
  max-width: 290px;
}

.quantity-cell {
  min-width: 100px;
  max-width: 100px;
}

.rate-cell {
  min-width: 100px;
  max-width: 100px;
}

.unit-rate-cell {
  min-width: 120px;
  max-width: 120px;
}

.rental-duration {
  min-width: 55px;
  max-width: 55px;
}

.rental-unit {
  min-width: 120px;
  max-width: 120px;
}

.cost-code-cell {
  min-width: 184px;
  max-width: 184px;
}

.tax-cell {
  min-width: 140px;
  max-width: 140px;
}

.comment-cell {
  min-width: 259px;
  max-width: 259px;
}

.unit-cell {
  min-width: 127px;
  max-width: 127px;
}

.handle {
  color: rgb(var(--v-theme-mid-grey));
  cursor: grab;
  display: inline-block;
  font-size: 0.8rem;
  visibility: hidden;
}

.handle i::before {
  margin-top: -3px;
}

.handle:active {
  cursor: grabbing;
  color: rgb(var(--v-theme-interactive));
}

tr:hover .handle {
  visibility: visible;
}

tr:hover .item-number {
  display: none;
}

tr:hover .item-btn {
  color: rgb(var(--v-theme-mid-grey)) !important;
}

tr.cart-items-row.ghost > td {
  background-color: rgb(var(--v-theme-light-grey)) !important;
  opacity: 0.5;
}

.no-handle .handle {
  visibility: hidden !important;
}

.handle-right {
  margin-left: -14px;
}
</style>
