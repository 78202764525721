<template>
  <items-btn
    v-bind="$attrs"
    :items="items"
    label="Create"
    :loading="loading"
    :secondary="items.length === 1"
  >
    <bom-from-items-dialog
      v-model="createBom"
      :attachments="quote.attachments"
      :items="quote.skus"
      :project="order.jobsite"
      :quote="quote"
    />
  </items-btn>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import type { Order, Quote } from '@quotetome/materials-api'
import BomFromItemsDialog from '@/components/boms/bom-from-items-dialog.vue'
import ItemsBtn from '@/components/items-btn.vue'

export interface Props {
  order: Order
  quote: Quote
}

const props = defineProps<Props>()
const emit = defineEmits(['update:order'])

const createBom = ref(false)
const loading = ref(false)

const router = useRouter()
const { $api, $error } = useNuxtApp()
const makeAdjustments = async () => {
  loading.value = true
  try {
    const order = await $api.v1.rfqs.transition(props.order, 'make_final_adjustments', {
      context: { quote: props.quote.id },
    })
    emit('update:order', order)
    router.push({ name: 'orders-id-change-order', params: { id: order.id } })
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

const items = computed(() => [
  {
    label: 'Create PO',
    icon: 'mdi-shopping-outline',
    value: 'create-po',
    include: props.order.canAwardQuote,
    onClick: makeAdjustments,
  },
  {
    label: 'Create BoM',
    icon: 'mdi-playlist-plus',
    value: 'create-bom',
    include: true,
    onClick: () => { createBom.value = true },
  },
].filter(item => item.include))
</script>
