<template>
  <div ref="container">
    <div class="d-flex justify-space-between align-center mb-6">
      <slot name="label">
        <div class="qtm-label mt-4 text-uppercase text-dark-grey">
          Comments
        </div>
      </slot>
      <v-menu content-class="qtm-border">
        <template v-slot:activator="{ props: menu, isActive }">
          <v-btn v-if="comments.length" class="mb-n6 mr-n3 mt-2" :ripple="false" variant="plain" v-bind="menu">
            <span class="qtm-body text-dark-grey">Show: </span>
            <span class="qtm-body text-mid-grey">{{ sort.label }} First</span>
            <v-icon size="small">
              {{ isActive ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-btn>
        </template>
        <v-list class="py-0">
          <v-list-item class="py-0 px-6 text-dark-grey" active-color="interactive" @click="sortNewest">
            Newest first
          </v-list-item>
          <v-list-item class="py-0 px-6 text-dark-grey" @click="sortOldest">
            Oldest first
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div
      class="d-flex flex-column"
      :class="{ 'flex-column-reverse': sort.key === Sort.Oldest.key, 'separated': comments.length > 0 }"
    >
      <new-comment-box
        v-if="comments.length || showInput || !withEmpty"
        :autofocus="showInput"
        class="py-4"
        :content-id="props.contentId"
        :content-type="props.contentType"
        :mentionable-users="mentionableUsers"
        @add-comment="addComment"
        @preview="$emit('preview', $event)"
      />

      <div v-else class="qtm-body text-mid-grey text-center" :class="{ 'center-empty': centerEmpty }">
        Start discussion with your teammates
        <div>
          <qtm-btn prepend-icon="mdi-message-outline" tertiary @click="showInput = true">
            Add Comment
          </qtm-btn>
        </div>
      </div>

      <comment-row
        v-for="comment in sortedComments"
        :key="comment.id"
        class="py-4"
        :comment="comment"
        :download-only="downloadOnly"
        @preview="$emit('preview', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import CommentRow from '@/components/comments/comment-row.vue'
import NewCommentBox from '@/components/comments/new-comment-box.vue'

const { $api, $error } = useNuxtApp()

defineEmits(['preview'])

export interface Props {
  centerEmpty?: boolean
  contentId: number
  contentType: string
  downloadOnly?: boolean
  mentionableUsers: any[]
  withEmpty?: boolean
}
const props = defineProps<Props>()

const comments = ref<any[]>([])
const showInput = ref(false)

interface Sorting {
  key: number
  label: string
  icon: string
}
const Sort = {
  Newest: { key: 0, label: 'Newest', icon: 'mdi-arrow-up-bold' },
  Oldest: { key: 1, label: 'Oldest', icon: 'mdi-arrow-down-bold' },
} as const
const sort = ref<Sorting>({ ...Sort.Newest })
const sortKey = localStorage.getItem('comments.sort')

if (sortKey) {
  const sortValue = Object.values(Sort).find((s) => s.key === Number(sortKey))

  if (sortValue) {
    sort.value = { ...sortValue }
  }
}

const sortComments = (sorting: Sorting) => {
  sort.value = { ...sorting }
  localStorage.setItem('comments.sort', sort.value.key.toString())
}

const sortOldest = () => sortComments(Sort.Oldest)

const sortNewest = () => sortComments(Sort.Newest)

const sortedComments = computed(() => {
  return comments.value.sort((a, b) => new Date(b.date_created).getTime() - new Date(a.date_created).getTime())
})

const container = ref<any>(null)
const route = useRoute()

onMounted(async () => {
  if (route.query.comment) {
    container.value?.scrollIntoView({ behavior: 'smooth' })
  }

  try {
    comments.value = await $api.v1.comments.list(props.contentId, props.contentType)
  }
  catch (error) {
    $error.report(error)
  }
})

const addComment = (createdComment: any) => {
  comments.value.push(createdComment)
}
</script>

<style lang="scss" scoped>
.center-empty {
  margin-top: 50%;
}

$border: 1px solid rgb(var(--v-theme-light-grey));

.separated > *:not(:last-child) {
  border-bottom: $border;
}

.separated.flex-column-reverse > *:first-child {
  border-bottom: none;
}

.separated.flex-column-reverse > *:last-child {
  border-bottom: $border;
}
</style>

<style lang="scss">
.v-list-item:hover {
  color: rgb(var(--v-theme-interactive)) !important;
  background-color: rgb(var(--v-theme-interactive-lighten-1));
}
</style>
