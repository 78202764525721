<template>
  <v-dialog
    v-bind="$attrs"
    height="95vh"
    max-width="933"
    scrollable
    @update:model-value="$emit('update:model-value', $event)"
  >
    <quote-detail-card
      :order="order"
      :quote="quote"
      :show-actions="showActions"
      @update:order="$emit('update:order', $event)"
    >
      <template v-slot:title-prepend>
        <div
          v-if="quotes.length"
          class="bg-background d-flex align-center justify-space-between mt-n4 mx-n6 pb-2 pt-4 px-6"
        >
          <div class="qtm-overline-bold text-mid-dark-grey">
            QUOTE {{ index }}/{{ props.quotes.length }}
          </div>
          <qtm-icon-btn
            class="mr-n2"
            color="mid-light-grey"
            icon="mdi-close"
            @click="$emit('update:model-value', false)"
          />
        </div>
      </template>
    </quote-detail-card>
    <qtm-icon-btn
      v-if="quotes.length"
      class="quote-arrow-btn --left"
      color="white"
      :disabled="index <= 1"
      icon="mdi-chevron-left"
      @click="prev"
    />
    <qtm-icon-btn
      v-if="quotes.length"
      class="quote-arrow-btn --right"
      color="white"
      :disabled="index >= props.quotes.length"
      icon="mdi-chevron-right"
      @click="next"
    />
  </v-dialog>
</template>

<script setup lang="ts">
import type { Order, Quote } from '@quotetome/materials-api'
import QuoteDetailCard from '@/components/quotes/quote-detail-card.vue'

export interface Props {
  order: Order
  quote: Quote
  quotes: Quote[]
  showActions?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['update:model-value', 'update:order', 'update:quote'])

const index = computed(() => props.quotes.findIndex(q => q.id === props.quote.id) + 1)

const next = () => {
  const nextQuote = props.quotes[index.value]

  if (nextQuote) {
    emit('update:quote', nextQuote)
  }
}

const prev = () => {
  const prevQuote = props.quotes[index.value - 2]

  if (prevQuote) {
    emit('update:quote', prevQuote)
  }
}
</script>

<style lang="scss" scoped>
$size: 60px;
$offset: -$size;

.quote-arrow-btn {
  font-size: 40px;
  height: $size;
  width: $size;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &.--left {
    left: $offset;
  }

  &.--right {
    right: $offset;
  }
}
</style>
