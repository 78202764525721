export const MAX_POSITIVE_INT_VAL = 2147483647

export const ADDRESS_MAX_LENGTH = 128
export const CANCELLATION_REASON_MAX_LENGTH = 50
export const CONTACT_MAX_NAME_LENGTH = 100
export const DURATION_MAX_LENGTH = MAX_POSITIVE_INT_VAL.toString().length
export const EMAIL_MAX_LENGTH = 254
export const INLINE_IMAGE_CATEGORY = 5
export const JOBSITE_NAME_MAX_LENGTH = 128
export const JOBTITLE_MAX_LENGTH = 256
export const MAX_PASSWORD_LENGTH = 200
export const MAX_TEXTAREA_LENGTH = 5000
export const MIN_PASSWORD_LENGTH = 8
export const POSTAL_MAX_LENGTH = 20
export const PRICE_MAX_LENGTH = 14
export const PROJECT_NUMBER_MAX_LENGTH = 64
export const QUANTITY_DECIMAL_PLACES = 2
export const QUANTITY_MAX_LENGTH = 14
export const SEARCH_MAX_LENGTH = 77
export const SKU_NAME_LENGTH = 255
export const SKU_UNIT_LENGTH = 50
export const SUPPLIER_REFERENCE_MAX_LENGTH = 50
export const UNIT_PRICE_DECIMAL_PLACES = 5
export const USER_NAME_MAX_LENGTH = 30
export const VENDOR_NAME_MAX_LENGTH = 254
export const ORDER_NAME_MAX_LENGTH = 48

export const SITE_CONTACT_NAME_MAX_LENGTH = 2 * USER_NAME_MAX_LENGTH + 1

export const PHONE_MASK = '###-###-####'
export const phoneNumber = (value) => !value?.length || value?.length === PHONE_MASK.length

export const QTM_SUPPORT_EMAIL = 'support@quotetome.com'

export const DELIVERY_DATE_REQUIRED_VALUES = ['am', 'pm', 'sp']

export const DELIVERY_REQUIRED_VALUES = DELIVERY_DATE_REQUIRED_VALUES.concat(['tbd'])

export const addressFields = ['address', 'city', 'country', 'postal_code', 'province']

export const vendorFields = [
  'address',
  'city',
  'country',
  'name',
  'notes',
  'phone',
  'phone_extension',
  'postal_code',
  'province',
  'public',
  'website',
]

export const contactFields = [
  'email', 'first_name', 'last_name', 'organization', 'phone_extension', 'phone_number'
]
