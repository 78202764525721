import mixpanel from 'mixpanel-browser'
import { useAuthStore } from '@/stores/auth'

interface Tracker {
  logIn: (userId: number | string) => void
  logOut: () => void
  track: (eventName: string, eventProperties?: any) => void
}

export const tracker: Tracker = {
  track: (event, data) => console.info('Tracking stub: ', event, data),
  logIn: (id) => console.info('Tracking login stub: ', id),
  logOut: () => console.info('Tracking logout stub'),
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const authStore = useAuthStore()

  if (config.public.MIXPANEL_TOKEN) {
    mixpanel.init(config.public.MIXPANEL_TOKEN)

    tracker.track = (eventName, eventProperties) => {
      const properties = eventProperties || {}
      const user = authStore.user

      if (user) {
        properties.distinct_id = user.id
      }

      if (user && user.company) {
        const companyId = user.company.id
        mixpanel.track_with_groups(eventName, properties, { company_id: companyId })
      }
      else {
        mixpanel.track(eventName, properties)
      }
    }
    tracker.logIn = (userId: number | string) => {
      mixpanel.identify(userId.toString())
    }
    tracker.logOut = () => {
      mixpanel.reset()
    }
  }

  return {
    provide: { tracker }
  }
})
