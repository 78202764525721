<template>
  <qtm-content-block :collapsed="collapsed" collapsible title="Order Info">
    <v-row no-gutters>
      <v-col v-bind="cols">
        <qtm-input-label v-if="isStaff" label="Order Owner" required>
          <user-select
            placeholder="Select an owner for this order"
            :readonly="readonlyOwner"
            class="mb-4"
            clearable
            :disabled="readonlyOwner"
            :error-messages="!owner ? errors.owner : undefined"
            hide-details
            return-object
            :model-value="owner"
            @update:model-value="$emit('update:owner', $event)"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col v-bind="cols">
        <qtm-input-label label="Order Name" required>
          <qtm-text-field
            class="mb-4"
            data-test="reference-name-input"
            :disabled="readonlyName"
            :error-messages="errors.referenceName"
            hide-details="auto"
            maxlength="48"
            :model-value="referenceName"
            placeholder="Reference name for this order"
            @update:model-value="$emit('update:reference-name', $event)"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col v-bind="cols">
        <qtm-input-label label="Project" :required="jobsiteRequired">
          <jobsite-select
            :disabled="!!jobsite && lockJobsite"
            :error-messages="!jobsite ? errors.jobsite : undefined"
            :model-value="jobsite"
            :user="projectUser"
            @update:model-value="$emit('update:jobsite', $event)"
          />
        </qtm-input-label>
      </v-col>
    </v-row>
  </qtm-content-block>
</template>

<script setup lang="ts">
import { required, requiredIf, maxLength } from '@vuelidate/validators'
import type { Jobsite, User } from '@quotetome/materials-api'
import UserSelect from '@/components/users/user-select.vue'
import JobsiteSelect from '@/components/jobsites/jobsite-select.vue'
import useValidation from '@/composables/validation'

export interface Props {
  collapsed?: boolean
  fullWidth?: boolean
  jobsite?: Jobsite | null
  jobsiteRequired?: boolean
  lockJobsite?: boolean
  owner?: User | null
  readonlyName?: boolean
  readonlyOwner?: boolean
  referenceName?: string
}

const props = withDefaults(defineProps<Props>(), {
  collapsed: false,
  fullWidth: false,
  jobsite: null,
  jobsiteRequired: true,
  lockJobsite: false,
  owner: null,
  readonlyOwner: false,
  referenceName: '',
})
defineEmits(['update:jobsite', 'update:owner', 'update:reference-name'])

const authStore = useAuthStore()
const user = authStore.user
const isStaff = authStore.isStaff

const { errors, isValid } = useValidation({
  rules: {
    jobsite: { required: requiredIf(() => props.jobsiteRequired) },
    owner: { required: requiredIf(!!isStaff) },
    referenceName: { required, maxLength: maxLength(48) },
  },
  state: toRefs(props),
})

const cols = computed(() => {
  if (props.fullWidth) {
    return { cols: 12 }
  }

  return { cols: 12, sm: 8, lg: 6 }
})
const projectUser = computed(() => (isStaff ? props.owner : user) || undefined)

defineExpose({ isValid })
</script>
