<template>
  <qtm-content-block title="Delivery Information">
    <div class="qtm-body">
      <v-row v-if="order.delivery_required || order.customer_pickup">
        <v-col>
          {{ deliveryOrPickup }} requested on:
          <span class="font-weight-medium">
            {{ orderDeliveryDate }}
          </span>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          Delivery not required
        </v-col>
      </v-row>

      <v-row v-if="order.delivery_required || order.customer_pickup" align="center" class="mt-0">
        <v-col cols="auto" class="py-0">
          <v-switch v-model="quote.delivery_available" color="interactive" dense hide-details />
        </v-col>
        <v-col class="ml-n3">
          {{ deliveryOrPickupIncluded }}
        </v-col>
      </v-row>

      <v-row v-if="order.delivery_required || order.customer_pickup" align="center" class="mt-0">
        <v-col cols="auto" class="py-0">
          <v-switch
            v-model="quote.deliver_in_time"
            color="interactive"
            dense
            :disabled="!order.delivery_date || !quote.delivery_available"
            hide-details
          />
        </v-col>
        <v-col class="ml-n3">
          {{ deliveryOrPickupAvailableOnTime }}
        </v-col>
      </v-row>
      <template v-if="!quote.deliver_in_time && quote.delivery_available">
        <v-row align="center" class="mt-0">
          <v-col cols="4" class="ml-13">
            <date-picker
              v-model="v$.deliveryDate.$model"
              close-on-click
              :error-messages="errors.deliveryDate"
              hide-details="auto"
              placeholder="Supplier's delivery time"
              density="compact"
            />
          </v-col>
        </v-row>
      </template>
    </div>
  </qtm-content-block>
</template>

<script>
import { requiredIf } from '@vuelidate/validators'
import { objectDeliveryDate } from '~/models/filters'
import DatePicker from '@/components/date-picker.vue'
import useValidation from '@/composables/validation'

export default {
  name: 'quote-delivery',
  components: { DatePicker },
  props: {
    quote: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { errors, isValid, v$ } = useValidation()

    return { errors, isValid, v$ }
  },
  validations: {
    deliveryDate: {
      required: requiredIf(function () {
        return this.quote.delivery_available && !this.quote.deliver_in_time
      })
    }
  },
  data() {
    return {
      freeDelivery: false,
    }
  },
  computed: {
    deliveryDate: {
      get() {
        return this.quote.delivery_date
      },
      set(value) {
        this.quote.delivery_date = value
      }
    },
    deliveryOrPickup() {
      return this.order.customer_pickup ? 'Pick up' : 'Delivery'
    },
    deliveryOrPickupIncluded() {
      return this.order.customer_pickup ? 'Pick up included' : 'Supplier included delivery'
    },
    deliveryOrPickupAvailableOnTime() {
      return this.order.customer_pickup
        ? 'Pick up can be done at requested time'
        : 'Supplier can deliver at requested time'
    },
    orderDeliveryDate() {
      return this.order.delivery_date ? objectDeliveryDate(this.order) : 'Not specified'
    },
  },
  watch: {
    'quote.delivery_available': {
      handler() {
        if (this.quote.delivery_available) {
          this.quote.deliver_in_time = true
        }
        else {
          this.quote.deliver_in_time = false
          this.quote.delivery_date = null
          this.quote.delivery_charge = null
          this.quote.pickup_charge = null
        }
      }
    },

    'quote.deliver_in_time': {
      handler() {
        if (this.quote.deliver_in_time) {
          this.quote.delivery_date = null
        }
      }
    },

    'quote.delivery_charge': {
      handler() {
        if (this.quote.delivery_charge) {
          this.freeDelivery = false
        }
      }
    },

    'quote.pickup_charge': {
      handler() {
        if (this.quote.pickup_charge) {
          this.freeDelivery = false
        }
      }
    },

    freeDelivery() {
      if (this.freeDelivery) {
        this.quote.delivery_charge = null
        this.quote.pickup_charge = null
      }
    }
  }
}
</script>
