<template>
  <v-btn
    v-bind="$attrs"
    class="py-8"
    :color="color"
    :dark="active"
    :variant="!active ? 'outlined' : 'flat'"
    @click="$emit('update:model-value', val)"
  >
    <v-icon v-if="icon" :color="iconColor">
      {{ icon }}
    </v-icon>
    <span :class="{ 'text-mid-grey': !active }" v-text="label" />
  </v-btn>
</template>

<script setup lang="ts">
export interface Props {
  icon?: string
  label?: string
  val: string
  modelValue?: string
}

const props = withDefaults(defineProps<Props>(), {
  icon: undefined,
  label: '',
  modelValue: undefined,
})
defineEmits(['update:model-value'])

const active = computed(() => props.modelValue === props.val)
const color = computed(() => (active.value ? 'interactive' : 'light-grey'))
const iconColor = computed(() => (active.value ? 'white' : 'primary'))
</script>
