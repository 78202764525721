<template>
  <v-expansion-panels v-model="openPanels" multiple>
    <v-expansion-panel class="qtm-border" elevation="0">
      <v-expansion-panel-title class="font-weight-bold">
        RFQ Items
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <purchase-order-items-table
          :boms="boms"
          from-bom
          :items="order.skus"
          :quotes="order.quotes"
        />
      </v-expansion-panel-text>
    </v-expansion-panel>
    <v-expansion-panel class="qtm-border" elevation="0">
      <v-expansion-panel-title class="font-weight-bold">
        Quotes
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <quotes-review :order="order" />
      </v-expansion-panel-text>
    </v-expansion-panel>

    <v-expansion-panel class="qtm-border" elevation="0">
      <v-expansion-panel-title class="font-weight-bold">
        Invited Vendors
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <enter-vendor-quotes-table :order="order" />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup lang="ts">
import type { Order } from '@quotetome/materials-api'
import EnterVendorQuotesTable from '@/components/vendors/enter-vendor-quotes-table.vue'
import PurchaseOrderItemsTable from '@/components/purchase-orders/purchase-order-items-table.vue'
import QuotesReview from '@/components/quotes/quotes-review.vue'

export interface Props {
  order: Order
}

const props = defineProps<Props>()

const boms = ref([])
const openPanels = ref([0])

const { $api, $error } = useNuxtApp()

const fetchBoms = async () => {
  try {
    boms.value = await $api.v1.rfqs.bomItems(props.order.id as number)
  }
  catch (error) {
    $error.report(error)
  }
}

onMounted(fetchBoms)
</script>
