<template>
  <div class="bg-white container qtm-body">
    <div class="bg-light-teal px-3 py-4 d-flex justify-space-between qtm-h1">
      <div>
        Total
      </div>
      <div>
        {{ currency(estimatedTotal) }}
      </div>
    </div>
    <div class="px-3 py-2 qtm-border">
      <div v-for="section in sections" :key="section.label" class="d-flex justify-space-between py-2 total-section">
        <div v-text="section.label" />
        <div v-text="currency(section.value)" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import cloneDeep from 'lodash.clonedeep'
import debounce from 'lodash.debounce'
import isEqual from 'lodash.isequal'
import type { LineItem } from '@quotetome/materials-api'
import { currency } from '~/models/filters'

export interface Props {
  deliveryCharge?: number | string | null
  deliveryRequired?: boolean
  freightTax?: number | null
  modelValue?: number | string | null
  pickupCharge?: number | string | null
  skus?: LineItem[]
}

const props = withDefaults(defineProps<Props>(), {
  deliveryCharge: 0,
  deliveryRequired: false,
  freightTax: undefined,
  modelValue: 0,
  pickupCharge: 0,
  skus: () => [],
})
const emit = defineEmits(['change', 'update:model-value'])

const deliveryTotal = ref(0)
const estimatedTotal = ref(props.modelValue)
const previousData = ref<any>(null)
const itemSubtotal = ref(0)
const taxTotal = ref(0)

const sections = computed(() => {
  const arr = [
    { label: 'Items subtotal', value: itemSubtotal.value },
  ]

  if (deliveryTotal.value) {
    arr.push({ label: 'Delivery', value: deliveryTotal.value })
  }

  if (taxTotal.value) {
    arr.push({ label: 'Taxes', value: taxTotal.value })
  }

  return arr
})

const { $api, $error } = useNuxtApp()

const estimate = debounce(async () => {
  const deliveryCharge = props.deliveryRequired ? props.deliveryCharge : null
  const pickupCharge = props.deliveryRequired ? props.pickupCharge : null
  const estimateData = {
    delivery_charge: deliveryCharge || null,
    freight_tax: props.freightTax,
    pickup_charge: pickupCharge || null,
    poskus: props.skus.map((sku) => ({
      day_rate: sku.day_rate || null,
      month_rate: sku.month_rate || null,
      quantity: sku.quantity || null,
      rental_duration: sku.rental_duration || null,
      rental_duration_unit: sku.rental_duration_unit || null,
      tax: sku.tax || null,
      unit_price: sku.unit_price || null,
      week_rate: sku.week_rate || null,
    })),
  }

  if (isEqual(estimateData, previousData.value)) {
    return
  }

  try {
    const result = await $api.v1.purchaseOrders.estimate(estimateData)

    taxTotal.value = result.tax_line_items.reduce((acc: number, item: any) => acc + Number(item.total_price), 0) || null
    estimatedTotal.value = Number(result.estimate) + Number(taxTotal.value)
    deliveryTotal.value = Number(deliveryCharge) + Number(pickupCharge)
    itemSubtotal.value = Number(result.estimate) - Number(deliveryTotal.value)
    emit('change', result.estimate)
    emit('update:model-value', result.estimate)
    previousData.value = cloneDeep(estimateData)
  }
  catch (error) {
    $error.report(error, false)
  }
}, 350)

watchEffect(estimate)
watch(() => props.skus, estimate, { deep: true })
</script>

<style lang="scss" scoped>
.container {
  display: inline-block;
  width: 358px;
  text-align: left;
}

.total-section:not(:last-child) {
  border-bottom:1px solid rgb(var(--v-theme-light-grey));
}
</style>
