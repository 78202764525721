<template>
  <v-alert v-show="duplicates.length" border="start" variant="outlined" type="warning">
    <div v-for="duplicate in duplicates" :key="`${duplicate.type}-${duplicate.id}`">
      <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
      <nuxt-link target="_blank" :to="{ query: { activeOrder: duplicate.id } }">Order</nuxt-link>
      with matching {{ duplicate.type }} reference found
    </div>
  </v-alert>
</template>

<script>
export default {
  name: 'order-reference-duplicates',
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    supplierId: {
      type: Number,
      default: undefined
    },
  },
  data() {
    return {
      duplicates: [],
    }
  },
  methods: {
    async checkForDuplicates(reference) {
      if (reference) {
        try {
          const [quoteOrders, invoiceOrders] = await Promise.all([
            this.$api.v1.rfqs.list({
              supplier: this.supplierId,
              supplierReference: reference,
            }),
            this.$api.v1.rfqs.list({
              invoiceNumber: reference,
              supplier: this.supplierId,
            }),
          ])

          this.duplicates = quoteOrders.map(order => ({ id: order.id, type: 'supplier' }))
            .concat(invoiceOrders.map(order => ({ id: order.id, type: 'invoice' })))
            .filter(order => order.id !== this.orderId)
        }
        catch (error) {
          this.$error.report(error)
        }
      }
    },
  }
}
</script>
