<template>
  <tbody class="attachments-row">
    <tr>
      <td class="py-1" colspan="3" />
    </tr>
    <tr>
      <td class="label-cell pt-2 white-space-nowrap" :rowspan="labelRowSpan">
        <slot name="label">
          {{ label }}
        </slot>
      </td>
    </tr>
    <slot name="attachments">
      <tr v-for="attachment in sortedAttachments" :key="attachment.id">
        <td class="px-4 py-2">
          <attachment-preview :attachment="attachment" dense />
        </td>
        <td class="py-2 text-no-wrap">
          {{ dateUploaded(attachment) }}
        </td>
      </tr>
    </slot>
    <tr v-if="bottomDivider">
      <td class="pt-2" colspan="3">
        <v-divider />
      </td>
    </tr>
  </tbody>
</template>

<script setup lang="ts">
import AttachmentPreview from '@/components/attachments/attachment-preview.vue'
import { dateYear } from '~/models/filters'

export interface Props {
  attachments?: any[]
  bottomDivider?: boolean
  label?: string
  spanOffset?: number
}

const props = withDefaults(defineProps<Props>(), {
  attachments: () => [],
  bottomDivider: false,
  label: '',
  spanOffset: 0,
})

const sortedAttachments = computed(() => {
  const localAttachments = props.attachments

  localAttachments.sort((a: any, b: any) => b.date_uploaded - a.date_uploaded)
  return localAttachments
})
const labelRowSpan = computed(() => {
  return sortedAttachments.value.length + 1 + props.spanOffset
})

const dateUploaded = (a: any) => dateYear(a.date_uploaded)
</script>

<style lang="scss" scoped>
.attachments-row {
  vertical-align: top;
}

.attachments-row tr td:last-child {
  width: 120px;
}

.label-cell {
  width: 200px;
}
</style>
