<template>
  <qtm-content-block
    :collapsible="!removeTitle"
    :flat="removeTitle"
    :padding="removeTitle ? 0 : undefined"
    :title="removeTitle ? undefined : title"
  >
    <template v-slot:top-right>
      <slot name="top-right" />
    </template>
    <file-dropper
      v-if="!noUpload"
      :accept="accept"
      :color="color"
      :error-messages="fileError"
      :expanded="fileDropperExpanded"
      :highlight-color="highlightColor"
      :loading="loading"
      :multiple="multiple"
      :progress="progress"
      @change="attachFiles"
    >
      <template v-slot:label="labelProps">
        <slot v-bind="labelProps" name="label" />
      </template>
    </file-dropper>
    <div v-if="!hideAttachments" class="separated">
      <div v-for="attachment in uploadingFiles" :key="attachment.id" class="py-4">
        <attachment-preview :attachment="attachment" :dense="dense">
          <template v-slot:action>
            <v-progress-circular class="mr-1" color="interactive" indeterminate :size="24" :width="3" />
          </template>
        </attachment-preview>
      </div>

      <slot name="preview-prepend" />
      <div v-for="(attachment, i) in modelValue" :key="attachment?.id ?? `index-${i}`" class="py-4">
        <attachment-preview
          :allow-preview="allowPreview"
          :allow-select="allowSelect"
          :attachment="attachment"
          :dense="dense"
          :model-value="selectedAttachments"
          :with-delete="!noDelete"
          @delete="removeFile(attachment, i)"
          @preview="$emit('preview', attachment)"
          @update:model-value="emit('update:selected-attachments', $event)"
        >
          <template v-slot:action="{ attachment: attachmentToPreview }">
            <slot name="action" :attachment="attachmentToPreview" />
          </template>
        </attachment-preview>
      </div>
    </div>

    <slot />

    <confirmation-dialog
      v-model="deleteAttachmentConfirmation"
      cancel-button-text="Cancel"
      :loading="loading"
      ok-button-text="Delete attachment"
      title="Are you sure you want to delete?"
      @close="closeConfirmaitonDialog"
      @confirm="confirmDelete"
    >
      <span v-if="confirmDeleteModalText">
        {{ confirmDeleteModalText }}
      </span>
      <span v-else>
        {{ deleteAttachment ? deleteAttachment.name : "this file" }}
      </span>
    </confirmation-dialog>
  </qtm-content-block>
</template>

<script lang="ts">
import type { AxiosProgressEvent } from 'axios'
import type { Attachment } from '@quotetome/materials-api'
import AttachmentPreview from '@/components/attachments/attachment-preview.vue'
import ConfirmationDialog from '@/components/confirmation-dialog.vue'
import FileDropper from '@/components/file-dropper/file-dropper.vue'

const ACCEPTED_FILE_EXTENSIONS = [
  '.csv',
  '.doc',
  '.docx',
  '.heic',
  '.pdf',
  '.txt',
  '.xls',
  '.xlsx',
]
const ACCEPTED_FILE_TYPES = ['image/*']
</script>

<script setup lang="ts">
export interface Props {
  accept?: string
  allowPreview?: boolean
  allowSelect?: boolean
  analyze?: boolean
  color?: string
  confirmDeleteModalText?: string
  contentType?: string | null
  dense?: boolean
  fileDropperExpanded?: boolean
  hideAttachments?: boolean
  highlightColor?: string
  modelValue?: Attachment[]
  multiple?: boolean
  noDelete?: boolean
  noUpload?: boolean
  objectId?: number | null
  poReceipt?: boolean
  removeTitle?: boolean
  selectedAttachments?: number[]
  title?: string
  uploadingFiles?: Attachment[]
}

const props = withDefaults(defineProps<Props>(), {
  accept: ACCEPTED_FILE_TYPES.concat(ACCEPTED_FILE_EXTENSIONS).join(','),
  color: undefined,
  confirmDeleteModalText: undefined,
  contentType: null,
  highlightColor: undefined,
  modelValue: () => [],
  objectId: null,
  selectedAttachments: () => [],
  title: 'Attachments',
  uploadingFiles: () => [],
})
const emit = defineEmits(['attachment-added', 'deleted', 'preview', 'update:loading', 'update:selected-attachments'])

// 100MB maximum file size
const MAX_FILE_SIZE_MB = 100
// Use 1000 instead of 1024 to account for Cloudflare limit of 100MB for the whole request
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1000 * 1000

const validFileType = (file: File) => !file
  || ACCEPTED_FILE_TYPES.some(type => file.type.startsWith(type.replace('*', '')))
  || ACCEPTED_FILE_EXTENSIONS.some(ext => file.name.toLowerCase().endsWith(ext))

const fileError = ref<string>()
const progress = ref(0)
const loading = ref(false)
const deleteAttachmentConfirmation = ref(false)
const deleteAttachment = ref<Attachment | null>(null)
const deleteIndex = ref<number | null>(null)

const { $api, $error, $toast } = useNuxtApp()

const attachFiles = async (fileOrFiles: File | File[]) => {
  if (!fileOrFiles) {
    return
  }

  let files: File[] = []

  if (Array.isArray(fileOrFiles)) {
    files = fileOrFiles
  }
  else {
    files.push(fileOrFiles)
  }

  if (!isValid(files)) {
    $toast.error(fileError.value as string)
    return
  }

  progress.value = 0
  loading.value = true
  emit('update:loading', true)

  const filesUploadProgress = uploadProgress(files.length)

  for (const [index, file] of files.entries()) {
    try {
      const formData = new FormData()
      formData.append('analyze', String(props.analyze))
      formData.append('file', file)
      const uploadMethod = props.poReceipt ? uploadPOReceipt : uploadFile
      // eslint-disable-next-line no-await-in-loop
      const attachment = await uploadMethod(formData, filesUploadProgress(index))
      props.modelValue.push(attachment)
      emit('attachment-added', attachment)
    }
    catch (error) {
      $error.report(error)
    }
  }

  loading.value = false
  emit('update:loading', false)
}

const isValid = (files: File[]) => {
  for (const file of files) {
    if (file.size > MAX_FILE_SIZE) {
      fileError.value = `File size can be at most ${MAX_FILE_SIZE_MB}MB`
      return false
    }
    if (!validFileType(file)) {
      fileError.value = `Accepted file types are: ${props.accept}`
      return false
    }
  }

  return true
}

const uploadPOReceipt = (formData: FormData, uploadProgress: (progressEvent: AxiosProgressEvent) => void) => {
  return $api.v1.purchaseOrders.addReceipt(props.objectId as number, formData, uploadProgress)
}

const uploadFile = (formData: FormData, uploadProgress: (progressEvent: AxiosProgressEvent) => void) => {
  if (props.contentType && props.objectId) {
    formData.append('content_type', props.contentType)
    formData.append('object_id', props.objectId.toString())
  }

  return $api.v1.attachments.create(formData, uploadProgress)
}

const uploadProgress = (fileCount: number) => {
  const progresses: number[] = []

  return (i: number) => (progressEvent: AxiosProgressEvent) => {
    progresses[i] = Math.round((progressEvent.loaded / (progressEvent.total || 1)) * 100)
    progress.value = progresses.reduce((acc, val) => acc + val, 0) / fileCount
  }
}

const removeFile = (attachment: Attachment, index: number) => {
  deleteAttachment.value = attachment
  deleteIndex.value = index
  deleteAttachmentConfirmation.value = true
}

const closeConfirmaitonDialog = () => {
  deleteAttachmentConfirmation.value = false
  deleteAttachment.value = null
  deleteIndex.value = null
}

const confirmDelete = async () => {
  loading.value = true
  try {
    emit('deleted')
    props.modelValue.splice(deleteIndex.value as number, 1)
    await $api.v1.attachments.delete(deleteAttachment.value?.id as number)
    closeConfirmaitonDialog()
  }
  catch (error) {
    props.modelValue.splice(deleteIndex.value as number, 0, deleteAttachment.value as Attachment)
    $error.report(error)
  }
  loading.value = false
}

defineExpose({
  attachFiles,
  removeFile,
})
</script>

<style lang="scss">
.separated > *:not(:last-child) {
  border-bottom: 1px solid rgb(var(--v-theme-light-grey));
}
</style>
