<template>
  <qtm-content-block v-if="po || draftQuote" title="Purchase Order Details">
    <template v-slot:top-right>
      <sync-status v-if="showSync" class="mr-3" :order="order" :po="po!" />
      <slot name="action" />
    </template>
    <v-row class="qtm-body text-dark-grey" no-gutters>
      <v-col class="pr-4" cols="6">
        <details-container :details="poDetails" label-size="8em">
          <template v-slot:po-number>
            <copy-text v-if="po?.po_number" :text="po.po_number" />
          </template>
          <template v-slot:location>
            <div class="text-capitalize" v-text="order.delivery_location" />
            <qtm-address v-if="po" :address="po.shipto_address" :city="po.shipto_city" :province="po.shipto_province" />
          </template>
        </details-container>
      </v-col>
      <v-divider class="my-3" vertical />
      <v-col class="pl-4" cols="6">
        <details-container :details="supplierDetails" label-size="4em">
          <template v-slot:supplier>
            <div v-if="supplier">
              <div class="qtm-label" v-text="supplier.name" />
              <qtm-address :address="supplier.address" :city="supplier.city" :province="supplier.province" />
            </div>
          </template>
          <template v-slot:contact>
            <div v-if="contact">
              <div>
                {{ contact.first_name }} {{ contact.last_name }}
              </div>
              <div>
                <qtm-phone-number :ext="contact.phone_extension" :phone="contact.phone_number" />
              </div>
              <div>
                <a class="text-decoration-none" :href="`mailto:${contact.email}`" v-text="contact.email" />
              </div>
            </div>
          </template>
        </details-container>
      </v-col>
    </v-row>
  </qtm-content-block>
</template>

<script setup lang="ts">
import type { Order, PurchaseOrder } from '@quotetome/materials-api'
import CopyText from '@/components/copy-text.vue'
import DetailsContainer from '@/components/details-container.vue'
import SyncStatus from '@/components/purchase-orders/sync-status.vue'
import { objectDeliveryDate } from '@/models/filters'

export interface Props {
  order: Order
  po?: PurchaseOrder
}

const props = defineProps<Props>()

const poDetails = computed(() => [
  { label: 'PO Number', value: 'po-number' },
  { label: 'Delivery Date', text: objectDeliveryDate(props.po), value: 'date' },
  { label: 'Delivery Location', value: 'location' },
])
const showSync = computed(() => !!props.po?.jobsite?.accounting_id && props.order.showAccountingSync)
const supplierDetails = [
  { label: 'Supplier', value: 'supplier' },
  { label: 'Contact', value: 'contact' },
]

const draftQuote = computed(() => (props.order.isDraft ? props.order.quotes[0] : undefined))
const contact = computed(() => {
  if (props.po) {
    return {
      email: props.po.supplier_contact_email,
      first_name: props.po.supplier_contact_first_name,
      last_name: props.po.supplier_contact_last_name,
      phone_number: props.po.supplier_contact_phone,
      phone_extension: props.po.supplier_contact_phone_extension,
    }
  }

  return draftQuote.value?.salesperson
})

const supplier = computed(() => {
  if (props.po) {
    return {
      address: props.po.supplier_address,
      city: props.po.supplier_city,
      name: props.po.supplier_name,
      province: props.po.supplier_province,
    }
  }

  return draftQuote.value?.vendor
})
</script>
