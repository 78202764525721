<template>
  <qtm-dialog-card
    v-bind="$attrs"
    persistent
    title="Add a New Contact"
    width="450"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <contact-form
      ref="contactForm"
      :contact="contact"
      :vendor="vendor"
      :email-required="emailRequired"
      :phone-required="phoneRequired"
      :vendor-contacts="vendor?.contacts"
    />
    <template v-slot:actions>
      <v-spacer />
      <qtm-btn size="x-large" tertiary @click="$emit('update:model-value', false)">
        Cancel
      </qtm-btn>
      <qtm-btn class="my-3" size="x-large" @click="saveAndClose">
        Add
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import ContactForm from '@/components/vendors/contact-form.vue'

export default {
  name: 'create-contact-dialog',
  components: { ContactForm },
  props: {
    emailRequired: {
      type: Boolean,
      default: false
    },
    phoneRequired: {
      type: Boolean,
      default: false
    },
    emitOnly: {
      type: Boolean,
      default: false
    },
    vendor: {
      type: Object,
      default: undefined
    },
  },
  emits: ['new-contact', 'update:model-value'],
  data() {
    return {
      loading: false,
      contact: {
        id: uuidv4(),
        first_name: '',
        last_name: '',
        email: '',
        phone_extension: '',
        phone_number: '',
      }
    }
  },
  methods: {
    clean() {
      this.contact = {
        id: uuidv4(),
        first_name: '',
        last_name: '',
        email: '',
        phone_extension: '',
        phone_number: '',
      }
    },
    async saveAndClose() {
      if (!this.$refs.contactForm.isValid()) {
        this.$toast.error('Please correct the errors')
        return
      }

      if (this.emitOnly) {
        this.$emit('new-contact', this.contact)
        this.$emit('update:model-value', false)
        this.clean()

        return
      }

      this.loading = true
      try {
        const payload = {
          ...this.contact,
          id: undefined,
          organization: this.vendor?.id,
        }

        const contact = await this.$api.v1.vendors.addContact(payload)
        this.$emit('new-contact', contact)
        this.$emit('update:model-value', false)
        this.clean()
      }
      catch (error) {
        this.$error.report(error)
      }
      this.loading = false
    },
  },
}
</script>
