<template>
  <div>
    <div class="d-flex align-center justify-space-between qtm-h1 text-uppercase text-white" :class="titleClass">
      <slot name="title">
        {{ title }}
      </slot>
      <qtm-icon-btn
        :color="buttonColor"
        :disabled="disabled"
        :icon="expandIcon"
        size="large"
        @click.stop="expanded = !expanded"
      />
    </div>
    <v-expand-transition>
      <div v-show="expanded">
        <slot />
      </div>
    </v-expand-transition>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  buttonColor?: string
  collapsed?: boolean
  color?: string
  disabled?: boolean
  paddingLeft?: string
  title?: string
}

const props = withDefaults(defineProps<Props>(), {
  buttonColor: 'light-teal',
  collapsed: false,
  color: 'mid-grey',
  disabled: false,
  paddingLeft: '6',
  title: '',
})

const expanded = ref(!props.collapsed)

const expandIcon = computed(() => (expanded.value ? 'mdi-chevron-up' : 'mdi-chevron-down'))
const titleClass = computed(() => `bg-${props.color} pl-${props.paddingLeft}`)

const collapse = () => {
  expanded.value = false
}
const expand = () => {
  expanded.value = true
}

defineExpose({
  collapse,
  expand,
})
</script>
