<template>
  <v-dialog v-bind="$attrs" scrollable :width="width" @update:model-value="$emit('update:model-value', $event)">
    <template v-slot:activator="activatorProps">
      <slot name="activator" v-bind="activatorProps" />
    </template>

    <v-card color="background">
      <v-card-title class="overflow-force-visible pt-6 px-6 qtm-h3 text-secondary text-uppercase" :class="titleClasses">
        <slot name="title">
          {{ title }}
        </slot>
      </v-card-title>
      <qtm-icon-btn
        v-if="closable"
        class="close-btn"
        color="mid-light-grey"
        icon="mdi-close"
        @click="$emit('update:model-value', false)"
      />
      <v-card-subtitle class="overflow-force-visible text-mid-grey" :class="subtitleClasses">
        <slot name="subtitle">
          {{ subtitle }}
        </slot>
      </v-card-subtitle>
      <v-card-text class="pb-6 pt-0 px-6 text-mid-dark-grey" :class="paddingClass">
        <slot />
      </v-card-text>
      <v-card-actions v-if="$slots.actions" class="bg-white px-6" :class="`${paddingClass} ${actionsClass}`">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
export interface Props {
  actionsClass?: string
  closable?: boolean
  padding?: number | string
  subtitle?: string
  title?: string
  titleCentered?: boolean
  headerBackground?: string
  width?: number | string
}
const props = withDefaults(defineProps<Props>(), {
  actionsClass: '',
  closable: false,
  padding: undefined,
  subtitle: '',
  title: '',
  titleCentered: false,
  headerBackground: 'background',
  width: 400
})
defineEmits(['update:model-value'])

const paddingClass = computed(() => (props.padding ? `px-${props.padding}` : ''))
const titleClasses = computed(() => {
  const classes = []
  if (props.padding) {
    classes.push(`px-${props.padding}`)
  }
  if (props.titleCentered) {
    classes.push('justify-center')
  }
  if (props.headerBackground) {
    classes.push(`bg-${props.headerBackground}`)
  }
  return classes
})
const subtitleClasses = computed(() => {
  const classes = []
  if (props.padding) {
    classes.push(`px-${props.padding}`)
  }
  if (props.headerBackground) {
    classes.push(`bg-${props.headerBackground}`)
  }
  return classes
})
</script>

<style scoped>
  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>
