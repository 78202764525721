export const decimalPlaces = (places) => (value) => {
  /*
    ^              # start of input
    -?             # optional negative sign
    \\d*           # optional digits before decimal
    \\.?           # optional decimal point
    \\d{0,places}  # zero to places digits after decimal
    $              # end of input
  */
  return !value || !!value.toString().match(new RegExp(`^-?\\d*\\.?\\d{0,${places}}$`))
}
