<template>
  <tr
    class="cost-summary-item-row"
    :class="{ 'bg-interactive-lighten-1': isMatch }"
    @mouseleave="$emit('hover-end', item)"
    @mouseover="$emit('hover-start', item)"
  >
    <td :colspan="rental ? 1 : 2">
      <div>
        <span v-if="withReceived" class="ml-n5">
          <v-icon v-if="partialReceived" color="primary" small>
            mdi-exclamation
          </v-icon>
          <v-icon v-else color="teal" small>
            mdi-check
          </v-icon>
        </span>
        {{ item.description }}
      </div>
      <div v-if="item.comment" class="qtm-body-small text-dark-grey py-2" v-text="item.comment" />
      <div>
        <cost-code-menu
          v-model="item.cost_code"
          :cost-codes="costCodes"
          :disabled="readonly || readonlyItems.includes(item.id)"
          @all="$emit('update-all-cost-codes', $event)"
        />
      </div>
    </td>
    <td>
      <span v-if="withReceived && partialReceived">
        <span class="text-primary" v-text="Number(item.received_quantity)" /> /
      </span>
      {{ Number(item.quantity) }} {{ item.unit }}
      <div v-if="withReceived && partialReceived" class="text-primary received">
        received
      </div>
    </td>
    <td v-if="rental">
      {{ item.rental_duration }} {{ item.rental_duration_unit }}
    </td>
    <td v-if="rental">
      {{ currency(item.rental_price.rate) }}/{{ item.rental_price.per }}
    </td>
    <td v-else>
      {{ currency(item.unit_price) }}
    </td>
    <td>
      {{ currency(total) }}
    </td>
  </tr>
</template>

<script>
import { computed } from 'vue'
import { currency } from '@/models/filters'
import CostCodeMenu from '@/components/cost-codes/cost-code-menu.vue'

export default {
  name: 'cost-summary-item-row',
  components: { CostCodeMenu },
  props: {
    costCodes: {
      type: Array,
      default: () => [],
    },
    hoverItem: {
      type: Object,
      default: undefined,
    },
    item: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    readonlyItems: {
      type: Array,
      default: () => [],
    },
    rental: {
      type: Boolean,
      default: false,
    },
    withReceived: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['hover-end', 'hover-start', 'update-all-cost-codes'],
  setup(props) {
    const isMatch = computed(() => {
      if (!props.hoverItem) {
        return false
      }

      return props.item.id === props.hoverItem.id
        || props.item.internal_identifier === props.hoverItem.reference_identifier
        || props.item.reference_identifier === props.hoverItem.internal_identifier
    })
    const partialReceived = computed(() => (
      Number(props.item.received_quantity) !== Number(props.item.quantity)
    ))
    const total = computed(() => {
      let price = props.item.unit_price

      if (props.rental) {
        price = props.item.rental_price.price
      }

      return props.item.quantity * price
    })

    return {
      currency,
      isMatch,
      partialReceived,
      total,
    }
  },
}
</script>

<style lang="scss" scoped>
.cost-summary-item-row {
  transition: background-color 0.2s ease-in-out;
}

.received {
  font-size: 0.875rem;
}
</style>
