import axios from 'axios'

const apiHostByEnvironment = {
  'dev': window.location.hostname,
  'staging': 'api-staging.quotetome.com',
  'demo': 'api-demo.quotetome.com',
  'prod': 'api.quotetome.com',
}

export const axiosInstance = axios.create()

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()
  let host = 'localhost'
  let port
  let protocol = 'https'

  if (process.client) {
    host = config.public.CLIENT_API_HOST
      || apiHostByEnvironment[config.public.ENV_TYPE as keyof typeof apiHostByEnvironment]
    port = config.public.CLIENT_API_PORT || (process.env.NODE_ENV === 'development' ? 8000 : undefined)

    const useHttps = process.env.NODE_ENV === 'production' && host !== 'localhost'
    protocol = config.public.CLIENT_API_PROTOCOL || (useHttps ? 'https' : 'http')
  }

  let baseURL
  if (port) {
    baseURL = `${protocol}://${host}:${port}/`
  }
  else {
    baseURL = `${protocol}://${host}/`
  }

  axiosInstance.defaults.baseURL = baseURL
  axiosInstance.defaults.xsrfCookieName = 'csrftoken'
  axiosInstance.defaults.xsrfHeaderName = 'X-CSRFToken'
  axiosInstance.defaults.headers['X-Platform'] = 'WEB'

  nuxtApp.provide('axios', axiosInstance)
})
