export default function useUnits(companyId?: number) {
  const nuxtApp = useNuxtApp()
  const units = ref<any[]>([])
  const fetchUnits = (id: number) => nuxtApp.$api.v1.companies.units(id)
    .then(data => { units.value = data })
    .catch(nuxtApp.$error.report)

  if (companyId) {
    onMounted(() => { fetchUnits(companyId) })
  }

  return { fetchUnits, units }
}
