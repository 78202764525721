<template>
  <span class="px-2 q-label status text-uppercase text-no-wrap" :class="colors" v-text="text" />
</template>

<script setup lang="ts">
export interface Props {
  error?: boolean
  errorText?: string
  negativeText?: string
  positive?: boolean
  positiveColor?: string
  positiveText?: string
}

const props = withDefaults(defineProps<Props>(), {
  error: false,
  errorText: '',
  negativeText: '',
  positive: false,
  positiveColor: 'light-teal',
  positiveText: '',
})

const colors = computed(() => {
  if (props.error) {
    return ['bg-red-lighten-4', 'text-red-darken-4']
  }

  return props.positive
    ? [`bg-${props.positiveColor}`, 'text-secondary']
    : ['bg-primary-lighten-1', 'text-primary-darken-1']
})
const text = computed(() => {
  if (props.error) {
    return props.errorText
  }

  return props.positive ? props.positiveText : props.negativeText
})
</script>

<style lang="scss" scoped>
.status {
  display: inline-block;
  line-height: 1.5rem;
}
</style>
