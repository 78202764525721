<template>
  <quote-order-form
    v-if="localOrder"
    ref="quoteOrderForm"
    :full-width="halfWidth"
    :order="localOrder"
    @preview="$emit('preview', $event)"
  />
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import QuoteOrderForm from '@/components/quotes/quote-order-form.vue'

export default {
  name: 'edit-rfq-quote',
  components: { QuoteOrderForm },
  props: {
    halfWidth: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      required: true
    }
  },
  emits: ['preview'],
  data() {
    return {
      localOrder: undefined,
    }
  },
  mounted() {
    this.cloneObjects()
  },
  methods: {
    beforeTransition(_action, abort) {
      if (this.$refs.quoteOrderForm && !this.order.cancelled) {
        if (!this.$refs.quoteOrderForm.isValid()) {
          abort()
          return undefined
        }
        return this.$refs.quoteOrderForm.submit(true)
      }
      return undefined
    },
    cloneObjects() {
      this.localOrder = cloneDeep(this.order)
    },
    async refresh() {
      this.cloneObjects()

      if (this.$refs.quoteOrderForm) {
        // Wait for cloned objects to propagate before setting items
        await this.$nextTick()

        this.$refs.quoteOrderForm.setItems()
      }
    },
  }
}
</script>
