<template>
  <div>
    <div class="qtm-table-wrapper" :class="{ 'overflow-x-auto': !noScroll }" tabindex="-1" @scroll="onScroll">
      <table
        class="qtm-table text-dark-grey"
        :class="{ 'qtm-table-scrolled': scrolled, [fixedClass]: Number(fixedColumns) > 0 }"
      >
        <thead v-if="!noHeader" class="qtm-table-header qtm-h4 text-secondary text-no-wrap">
          <tr>
            <th
              v-for="(header, i) in headers"
              :key="header.value"
              class="pa-2"
              :class="header.cellClass"
              :colspan="header.colspan"
              :style="cellStyles[i]"
            >
              <slot :name="`header.${header.value}`" :header="header">
                {{ header.title }}
              </slot>
            </th>
          </tr>
        </thead>
        <slot>
          <tr
            v-for="item in items"
            :key="item.id"
            :class="{ 'qtm-table-active': activeValues.includes(item.id) }"
          >
            <slot v-for="(header, i) in headers" :key="header.value" :name="`item-cell.${header.value}`" :item="item">
              <td
                :key="header.value"
                class="px-2 py-4"
                :class="header.cellClass"
                :style="cellStyles[i]"
              >
                <slot :name="`item.${header.value}`" :item="item">
                  {{ item[header.value] }}
                </slot>
              </td>
            </slot>
          </tr>
        </slot>
      </table>
    </div>
    <slot v-if="!items.length" name="empty">
      <div class="text-center py-4" v-text="noItemsText" />
    </slot>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  activeValues?: number[]
  fixedColumns?: '0' | '1' | '2' | 0 | 1 | 2
  headers: { title: string; value: string; colspan?: number, width?: number, cellClass?: string, minWidth?: number }[]
  items?: any[]
  noHeader?: boolean
  noItemsText?: string
  noScroll?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  activeValues: () => [],
  fixedColumns: 1,
  items: () => [],
  noItemsText: 'No items',
  noScroll: false,
})

const leftOffsets = computed(() => {
  const offsets = []
  let offset = 0
  let i = 0

  while (i < Number(props.fixedColumns)) {
    offsets.push(`${offset}px`)
    offset += props.headers[i]?.width ?? 0
    i += 1
  }

  return offsets
})

const cellStyles = computed(() => props.headers.map((header, i) => {
  const styles = []
  const offset = leftOffsets.value[i]

  if (offset !== undefined) {
    styles.push(`left: ${offset}`)
  }

  if (header.width) {
    ['max-', 'min-', ''].forEach(prefix => {
      styles.push(`${prefix}width: ${header.width}px`)
    })
  }
  else if (header.minWidth) {
    styles.push(`min-width: ${header.minWidth}px`)
  }

  return styles.join(';')
}))

const scrolled = ref(false)
const fixedClass = computed(() => `qtm-table-fixed-${props.fixedColumns}`)

const onScroll = (event: any) => {
  scrolled.value = Number(event?.target?.scrollLeft) > 0
}
</script>

<style lang="scss">
.qtm-table-wrapper {
  scrollbar-width: auto;

  &::-webkit-scrollbar {
    height: 8px;
  }
}

.qtm-table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  text-align: left;

  td,th {
    background-color: white;
    border: 1px solid rgb(var(--v-theme-mid-light-grey));
    text-overflow: ellipsis;
    vertical-align: top;
    overflow: hidden;
  }

  th {
    border: 1px solid rgb(var(--v-theme-light-grey));
  }

  .qtm-table-active td {
    background-color: rgb(var(--v-theme-interactive-lighten-1));
  }
}

.qtm-table-header > tr > th {
  background-color: rgb(var(--v-theme-background));
}

$columns: 1, 2;

@each $column in $columns {
  .qtm-table-scrolled.qtm-table-fixed-#{$column} {
    tr > th:nth-child(-n + #{$column}), tr > td:nth-child(-n + #{$column}) {
      position: sticky;
      left: 0;
      z-index: 1;
    }

    tr > th:nth-child(#{$column}), tr > td:nth-child(#{$column}) {
      box-shadow: 5px 0 5px -2px rgba(1.0, 1.0, 1.0, 0.2);
    }
  }
}
</style>
