<template>
  <accounting-sync-status v-slot="{ open, close }" :success="po.latest_change_order!.accounting_success">
    <sync-dialog
      v-model:accounting-success="po.latest_change_order!.accounting_success"
      :accounting-message="po.latest_change_order!.accounting_message"
      :order-owner="orderOwner"
      :order-reference-name="order.reference_name!"
      :po-id="po.id!"
      :po-number="po.po_number!"
      :revision-id="po.latest_change_order!.id"
      :revision-number="po.change_orders.length"
      :show-dialog="open"
      @update:show-dialog="close"
    />
  </accounting-sync-status>
</template>

<script setup lang="ts">
import type { Order, PurchaseOrder } from '@quotetome/materials-api'
import AccountingSyncStatus from '@/components/accounting/accounting-sync-status.vue'
import SyncDialog from '@/components/purchase-orders/sync-dialog.vue'

export interface Props {
  order: Order
  po: PurchaseOrder
}

const props = defineProps<Props>()

const orderOwner = computed(() => `${props.order.owner?.first_name} ${props.order.owner?.last_name}`)
</script>
