<template>
  <v-pagination
    v-if="numPages > 1 || showIfEmpty"
    v-bind="$attrs"
    color="secondary"
    :length="numPages"
    :model-value="modelValue"
  />
</template>

<script setup lang="ts">
export interface Props {
  modelValue?: number
  showIfEmpty?: boolean
  perPage: number
  count: number
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: 1,
  showIfEmpty: false,
})

const numPages = computed(() => Math.ceil(props.count / props.perPage))
</script>

<style lang="scss">
.v-pagination__item,
.v-pagination__navigation {
  border: 1px solid rgb(var(--v-theme-light-grey));
  box-shadow: none;
}
</style>
