<template>
  <div>
    <order-cart
      ref="cart"
      v-model:delivery-charge="quote.delivery_charge"
      v-model:items="quote.skus"
      v-model:pickup-charge="quote.pickup_charge"
      delivery-required
      rearrangeable
      :taxes="taxes"
      :units="units"
      :validate="priceRequired"
      validate-units
      :with-cost-codes="false"
      with-prices
    >
      <template v-slot:title:append>
        <slot name="order-cart-title-append" />
      </template>
      <template v-slot:items:prepend-left>
        <slot name="order-cart-items-prepend-left" />
      </template>
      <template v-slot:items>
        <slot name="order-cart-items" />
      </template>
      <v-row align="center" class="mt-3" justify="end">
        <v-col class="text-dark-grey text-right">
          <order-total-price-estimate
            :delivery-charge="quote.delivery_charge"
            :delivery-required="order.delivery_required"
            :freight-tax="order.freight_tax"
            :pickup-charge="quote.pickup_charge"
            :skus="quote.skus"
            :model-value="quote.total_price"
          />
        </v-col>
      </v-row>
    </order-cart>
    <v-row v-if="order.orderType.fromBom" class="mt-3">
      <v-col class="text-right">
        <qtm-btn
          :secondary="quote.skus.every(item => item.reference_identifier)"
          size="x-large"
          @click="showMatchItemsDialog = true"
        >
          Match items
        </qtm-btn>
      </v-col>
    </v-row>
    <auto-match-items
      v-if="order.orderType.fromBom"
      v-model="showMatchItemsDialog"
      v-model:items="quote.skus"
      matching-type-from="quote"
      matching-type-to="BOM"
      :order-items="order.skus"
      @cancel="showManualMatchItemsDialog = true"
      @done="showManualMatchItemsDialog = true"
    />
    <match-items-dialog
      v-if="order.orderType.fromBom"
      v-model="showManualMatchItemsDialog"
      v-model:items-to-match="quote.skus"
      item-type="Quote"
      match-item-type="RFQ"
      :match-items="order.skus"
      single-match
    />
  </div>
</template>

<script setup lang="ts">
import type { Order, Quote } from '@quotetome/materials-api'
import MatchItemsDialog from '@/components/items/match-items-dialog.vue'
import OrderCart from '@/components/orders/order-cart.vue'
import OrderTotalPriceEstimate from '@/components/orders/order-total-price-estimate.vue'
import AutoMatchItems from '@/components/items/auto-match-items.vue'

export interface Props {
  order: Order
  quote: Quote
  taxes?: any[]
  units?: any[]
}

const props = withDefaults(defineProps<Props>(), {
  taxes: () => [],
  units: () => [],
})

const showManualMatchItemsDialog = ref(false)
const showMatchItemsDialog = ref(false)
const priceRequired = computed(() => props.quote.response_received === 'price given')
const cart = ref<typeof OrderCart>()

const clear = () => cart.value?.clear()
const isValid = () => cart.value?.isValid()

defineExpose({ clear, isValid })
</script>
