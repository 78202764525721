<template>
  <component
    :is="Component"
    v-bind="$attrs"
    class="qtm-data-table"
    :class="`bg-${backgroundColor}`"
    :headers="processedHeaders"
    hover
    :items-per-page="itemsPerPage"
    :mobile="false"
    sort-asc-icon="mdi-menu-up"
    sort-desc-icon="mdi-menu-down"
  >
    <template v-slot:loading>
      <v-progress-circular color="secondary" indeterminate />
    </template>
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
      <slot v-bind="slotData" :key="name" :name="name" />
    </template>
    <template v-if="!showDefaultFooter" v-slot:bottom>
      <div />
    </template>
  </component>
</template>

<script setup lang="ts">
export interface Props {
  backgroundColor?: string
  headers?: any[]
  showDefaultFooter?: boolean
  itemsPerPage?: number | string
  serverSide?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  backgroundColor: 'white',
  headers: () => [],
  showDefaultFooter: false,
  itemsPerPage: 10000,
  serverSide: false,
})

const processedHeaders = computed(() => props.headers.map((header: any) => ({
  ...header,
  minWidth: header.minWidth ?? header.width,
})))

const Component = computed(() => (props.serverSide ? 'v-data-table-server' : 'v-data-table'))
</script>

<style lang="scss" scoped>
.qtm-data-table {
  overflow-wrap: anywhere;
  word-break: normal;
}
</style>

<style lang="scss">
.qtm-data-table th {
  color: rgb(var(--v-theme-mid-grey)) !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  white-space: nowrap !important;
  height: 65px !important;  // For some reason this gives a height of 64px
}

.qtm-data-table td {
  font-size: 1rem !important;
  color: rgb(var(--v-theme-dark-grey)) !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 1px;  // Hack to respect the headers width attribute
  height: 69px !important;  // For some reason this gives a height of 68px
}

.qtm-data-table th,
.qtm-data-table td {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.qtm-data-table th:first-child,
.qtm-data-table td:first-child{
  padding-left: 16px !important;
}

.qtm-data-table th:last-child,
.qtm-data-table td:last-child{
  padding-right: 16px !important;
}
</style>
