<template>
  <div>
    <v-list v-if="items.length" density="compact" class="qtm-border mention-list">
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        color="primary"
        class="item pa-3"
        :class="{ 'is-selected': index === selectedIndex }"
        @click="selectItem(index)"
      >
        <template v-slot:prepend>
          <user-avatar use-color-by-user size="lg" :user="item" />
        </template>

        <v-list-item-title class="mb-1" v-text="item.label" />
        <v-list-item-subtitle v-text="item.email" />
      </v-list-item>
    </v-list>
    <v-list v-else density="compact" class="qtm-border mention-list">
      <v-list-item
        color="primary"
        class="item pa-3"
      >
        <v-list-item-title>
          No results.
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import UserAvatar from '@/components/users/user-avatar.vue'

export default {
  components: {
    UserAvatar,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },

    command: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      selectedIndex: 0,
    }
  },

  watch: {
    items() {
      this.selectedIndex = 0
    },
  },

  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },

    upHandler() {
      this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
    },

    enterHandler() {
      this.selectItem(this.selectedIndex)
    },

    selectItem(index) {
      const item = this.items[index]

      if (item) {
        this.command({ id: item })
      }
    },
  },
}
</script>

<style lang="scss">
.item.is-selected {
  background-color: rgb(var(--v-theme-background))
}
.mention-list {
  max-height: 400px;
  overflow-y: auto;
}
</style>
