<template>
  <qtm-input-label label="Notification Recipient">
    <qtm-select
      v-model="recipient"
      density="compact"
      :item-title="teamMemberName"
      :items="projectTeam"
      :loading="loading"
      persistent-hint
      placeholder="Select the recipient of the Notification"
      @update:model-value="$emit('update-action', { actions: { send_quotes_ready: { ADDRESSEE: $event } } })"
    />
  </qtm-input-label>
</template>

<script setup lang="ts">
import type { Order, User } from '@quotetome/materials-api'

const props = defineProps<{
  order: Order
}>()
const emit = defineEmits(['update-action'])

const loading = ref(true)
const projectTeam = ref<User[]>([])
const recipient = ref<number | undefined>(undefined)

const { $api, $error } = useNuxtApp()
onMounted(async () => {
  loading.value = true

  if (props.order.jobsite) {
    try {
      projectTeam.value = await $api.v1.users.list({ jobsite: props.order.jobsite.id })
    }
    catch (error) {
      $error.report(error)
    }
  }

  if (props.order.owner && !projectTeam.value.some(member => member.id === props.order.owner?.id)) {
    projectTeam.value.push(props.order.owner)
  }

  recipient.value = props.order.owner?.id
  emit('update-action', { actions: { send_quotes_ready: { ADDRESSEE: recipient.value } } })
  loading.value = false
})

const teamMemberName = (item?: number | User) => {
  let member = item

  if (typeof member === 'number') {
    member = projectTeam.value.find(m => m.id === item)
  }

  if (member?.first_name) {
    return `${member.first_name} ${member.last_name}`
  }

  return ''
}
</script>
