<template>
  <qtm-chip v-if="outdated">
    Waiting for update
  </qtm-chip>
</template>

<script setup lang="ts">
import type { Quote } from '@quotetome/materials-api'

export interface Props {
  quote: Quote
}

const props = defineProps<Props>()

const showResponses = ['declined', 'price given']
const outdated = computed(() => props.quote.outdated && showResponses.includes(props.quote.response_received))
</script>
