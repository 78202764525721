<template>
  <qtm-text-field
    v-maska="options"
    v-bind="$attrs"
  />
</template>

<script>
import { vMaska } from 'maska/vue'

const masks = {
  CA: '@#@ #@#',
  US: '##########',
}

export default {
  name: 'postal-code-input',
  directives: { maska: vMaska },
  props: {
    country: {
      type: String,
      required: true
    },
  },
  computed: {
    options() {
      return {
        mask: masks[this.country] ?? undefined,
        postProcess: (value) => value?.toUpperCase(),
      }
    },
  },
}
</script>
