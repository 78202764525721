<template>
  <div>
    <qtm-dialog-card v-model="showSuccessDialog" title="Accounting Sync Successful" width="500">
      <div class="mb-5 text-secondary">
        <div v-for="(message, i) in messages" :key="i" v-text="message" />
      </div>
      <template v-slot:actions>
        <v-spacer />
        <qtm-btn size="x-large" @click="$emit('update:show-dialog', false)">
          Ok
        </qtm-btn>
      </template>
    </qtm-dialog-card>
    <qtm-dialog-card v-model="showErrorDialog" title="Accounting Sync Failed" width="500">
      <div class="mb-5">
        <span v-if="receiptLogId" class="text-interactive">
          Receipt Log for
        </span>
        <span class="text-interactive">
          PO {{ poNumber }}-{{ orderReferenceName }}-{{ revisionNumber }}
        </span>
        submitted by {{ orderOwner }} failed to Sync with your accounting software due to the following error message:
      </div>
      <div class="mb-5 qtm-h4 text-secondary">
        <div v-if="currentProblem">
          {{ currentProblem.message }}
        </div>
        <div v-else>
          <div v-for="(error, i) in messages" :key="i" v-text="error" />
        </div>
      </div>
      <div v-if="currentProblem">
        Click below to resolve the issue.
      </div>
      <div v-else>
        Please take the necessary action and sync again.
      </div>
      <template v-slot:actions>
        <v-spacer />
        <qtm-btn :loading="loading" tertiary size="x-large" @click="$emit('update:show-dialog', false)">
          Close
        </qtm-btn>
        <qtm-btn v-if="isStaff" :loading="loading" secondary size="x-large" @click="clearAccountingError = true">
          Clear Error
        </qtm-btn>
        <qtm-btn v-if="currentProblem" :loading="loading" size="x-large" @click="showResolutionDialog = true">
          Resolve
        </qtm-btn>
        <qtm-btn v-else :loading="loading" size="x-large" @click="syncAgain">
          Sync Again
        </qtm-btn>
      </template>
    </qtm-dialog-card>
    <qtm-dialog-card v-model="showPendingDialog" title="Accounting Sync Pending" width="500">
      <div class="mb-5 text-secondary">
        <p>
          This {{ revisionNumber ?'change order':'PO' }} has been issued and
          is now being sent to your accounting software.
          This may take a few minutes.
        </p>
        <p>Further change orders cannot be issued until the sync is successfully completed.</p>
      </div>
      <template v-slot:actions>
        <v-spacer />
        <qtm-btn size="x-large" @click="$emit('update:show-dialog', false)">
          Ok
        </qtm-btn>
      </template>
    </qtm-dialog-card>
    <clear-account-error-dialog
      v-model="clearAccountingError"
      :endpoint="receiptLogId ? 'receipts' : 'purchaseOrders'"
      :object-id="receiptLogId || poId"
      @cleared="errorCleared"
    />
    <resolve-config-problem-dialog
      v-if="showResolutionDialog"
      :problem="currentProblem"
      @update:model-value="showResolutionDialog = $event"
      @resolved="resolved"
    />
  </div>
</template>

<script setup lang="ts">
import ClearAccountErrorDialog from '@/components/accounting/clear-accounting-error-dialog.vue'
import ResolveConfigProblemDialog from '@/components/accounting/resolve-config-problem-dialog.vue'

export interface Props {
  accountingMessage: string
  accountingSuccess: boolean | null
  orderOwner: string
  orderReferenceName: string
  poId: number
  poNumber: string
  receiptLogId?: number
  revisionId: number
  revisionNumber: number
  showDialog?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['update:accounting-success', 'update:show-dialog'])

const clearAccountingError = ref(false)
const problems = ref<any[]>([])
const loading = ref(false)
const showResolutionDialog = ref(false)

const authStore = useAuthStore()
const isStaff = authStore.isStaff

const currentProblem = computed(() => problems.value[0])
const messages = computed(() => props.accountingMessage.split('\n'))
const showSuccessDialog = computed({
  get: () => props.showDialog && props.accountingSuccess === true,
  set: (value) => emit('update:show-dialog', value)
})
const showErrorDialog = computed({
  get() {
    return props.showDialog && props.accountingSuccess === false
  },
  set(value) {
    emit('update:show-dialog', value)
  }
})
const showPendingDialog = computed({
  get() {
    return props.showDialog && props.accountingSuccess === null
  },
  set(value) {
    emit('update:show-dialog', value)
  }
})

const { $api, $error, $toast } = useNuxtApp()
const fetchConfigProblems = async () => {
  if (!props.poId) { return }

  loading.value = true
  try {
    problems.value = await $api.v1.accountingConfigProblems.list({ purchase_order: props.poId })
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

watch(() => props.poId, (newPoId, oldPoId) => {
  if (newPoId && (newPoId !== oldPoId)) {
    fetchConfigProblems()
  }
})

onMounted(fetchConfigProblems)

const errorCleared = () => {
  emit('update:accounting-success', true)
  emit('update:show-dialog', false)
}
const resolved = (problem: any) => {
  problems.value = problems.value.filter(p => p.id !== problem.id)

  if (problems.value.length === 0) {
    emit('update:accounting-success', null)
    $toast.success('Sync attempt in progress')
    emit('update:show-dialog', false)
  }
}
const syncAgain = async () => {
  loading.value = true
  try {
    if (props.receiptLogId) {
      await $api.v1.receipts.resolveAccountingFailure(props.receiptLogId, {})
    }
    else {
      await $api.v1.purchaseOrders.resolveAccountingFailure(props.poId, { change_order: props.revisionId })
    }
    emit('update:accounting-success', null)
    $toast.success('Sync attempt in progress')
  }
  catch (error) {
    $error.report(error)
  }
  emit('update:show-dialog', false)
  loading.value = false
}
</script>
