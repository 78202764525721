<template>
  <v-dialog
    v-bind="$attrs"
    content-class="d-flex flex-row justify-center split-dialog"
    :max-width="maxWidth"
    scrollable
  >
    <attachment-viewer
      v-if="attachment"
      class="attachment-viewer mr-8"
      :class="{ 'image-attachment': isImage }"
      :name="attachment.name"
      :src="attachment.url"
      @close="$emit('update:attachment', undefined)"
    />
    <slot />
  </v-dialog>
</template>

<script>
import { computed } from 'vue'
import AttachmentViewer from '@/components/attachments/attachment-viewer.vue'
import useAttachments from '@/composables/attachments'

export default {
  name: 'attachment-viewer-dialog',
  components: { AttachmentViewer },
  props: {
    attachment: {
      type: Object,
      default: undefined
    },
    wide: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:attachment'],
  setup(props) {
    const src = computed(() => {
      return props.attachment?.url
    })
    const { isImage } = useAttachments(src)
    const maxWidth = computed(() => {
      let width = 8.5

      if (props.attachment || props.wide) {
        width *= 2
      }

      return `${width}in`
    })

    return { isImage, maxWidth }
  },
}
</script>

<style lang="scss" scoped>
.attachment-viewer {
  max-width: 8.5in !important;
}
</style>

<style lang="scss">
.split-dialog {
  box-shadow: none !important;
  height: 90vh;
}
</style>
