<template>
  <v-hover v-slot="{ isHovering, props: activator }" open-delay="200">
    <v-snackbar
      v-bind="activator"
      v-model="showSnackbar"
      class="mt-0"
      :color="colors"
      location="top"
      :max-height="lgAndUp ? '40px' : undefined"
      max-width="inherit"
      min-height="40px"
      min-width="0"
      :multi-line="!lgAndUp"
      rounded="t-0 b-lg"
      style="--v-layout-top: 0px"
      timeout="-1"
    >
      <div class="align-end" :class="{ 'd-flex': lgAndUp }">
        <div class="qtm-h4" v-text="mainText" />
        <template v-if="collapsed || isHovering">
          <div v-for="section in sectionContent" :key="section.key" :class="{ 'ml-4': lgAndUp }">
            <v-icon class="rex-icon" :class="colors" :icon="section.icon" small />
            {{ section.text }}
          </div>
        </template>
      </div>
    </v-snackbar>
  </v-hover>
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify'
import type { Invoice, LineItem, Order, PurchaseOrder } from '@quotetome/materials-api'
import { currency, monthDay } from '~/models/filters'

interface BlockingError {
  key: string
  text: string
  msg: string
}

export interface Props {
  blockingErrors?: BlockingError[]
  canApprove?: boolean
  collapsed?: boolean
  invoice: Invoice
  invoices?: Invoice[]
  order: Order
  po: PurchaseOrder
  show?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  blockingErrors: undefined,
  collapsed: true,
  invoices: () => [],
  show: true,
})
const emit = defineEmits(['update:blocking-errors'])

const accountingIntegration = ref()

const { lgAndUp } = useDisplay()

const accountingErrors = computed<BlockingError>(() => {
  const errors = []

  if (syncInvoices.value) {
    if (props.invoice.line_items?.some(item => !item.reference_identifier)) {
      errors.push({
        key: 'map',
        text: 'Not all invoice items are mapped to PO items',
        msg: 'Invoice cannot be approved if not all invoice items are mapped to PO items. '
          + 'To proceed, please edit the invoice and map all items to their respective PO items.',
      })
    }

    const allInvoiceItems = props.invoices
      .filter(inv => !inv.date_cancelled && !inv.date_approved)
      .flatMap(inv => inv.line_items)
      .filter(Boolean) as LineItem[]
    const releventPoItems = props.po.poskus
      .filter(item => props.invoice.line_items?.some(i => i.reference_identifier === item.internal_identifier))

    for (const item of releventPoItems) {
      const itemPrice = getItemTotal(item)
      const invoiceItems = allInvoiceItems.filter(i => i.reference_identifier === item.internal_identifier)
      const invoicedPrice = invoiceItems.reduce((sum, i) => sum + getItemTotal(i as LineItem), 0)

      if (invoicedPrice > itemPrice) {
        errors.push({
          key: 'over',
          text: '1 or more PO items have been over-invoiced',
          msg: 'Invoice cannot be approved if one or more items are over-invoiced. '
            + 'To proceed, please issue a change order or edit the invoice.'
        })
        break
      }
    }
  }

  return errors
})
const colors = computed(() => (warningCount.value
  ? ['primary-lighten-1', 'text-primary-darken-1']
  : ['interactive', 'text-white']).join(' '))
const mainText = computed(() => {
  if (props.order.date_cancelled) {
    return 'Approval Warning'
  }

  return warningCount.value
    ? `${warningCount.value} Approval Warning${warningCount.value === 1 ? '' : 's'}`
    : 'Ready to Pay'
})
const partiallyReceived = computed(() => !!props.po.partiallyReceived)
const poBalance = computed(() => {
  const poTotalPrice = Number(props.po.total_price) + Number(props.po.total_tax || 0)

  return poTotalPrice - props.invoices.reduce(
    (sum, invoice) => sum + Number(invoice.total_price) + Number(invoice.total_tax || 0),
    0
  )
})
const poOk = computed(() => poBalance.value === 0)
const poText = computed(() => {
  if (poOk.value) {
    return 'Invoice and PO totals match'
  }

  let invoiceIs = 'Invoice is'

  if (props.invoices.length > 1) {
    invoiceIs = `1 of ${props.invoices.length} invoices. Invoices are`
  }

  const difference = currency(Math.abs(poBalance.value))
  const underOrOver = poBalance.value > 0 ? 'under' : 'over'

  return `${invoiceIs} ${difference} ${underOrOver} the PO`
})
const receivedInFull = computed(() => {
  return props.po.poskus.every(item => Number(item.received_quantity) === Number(item.quantity))
})
const receivedOk = computed(() => receivedInFull.value)
const receivedText = computed(() => {
  if (receivedInFull.value) {
    return 'Order received'
  }

  if (partiallyReceived.value) {
    return 'Order partially received'
  }

  return props.po?.delivery_date
    ? `Order not received (exp. ${monthDay(props.po.delivery_date)})`
    : 'Order not received'
})
const showSnackbar = computed(() => {
  return props.canApprove && props.show && props.invoice && !props.invoice.approved_by
})
const syncInvoices = computed(() => {
  return accountingIntegration.value?.type === 'ryvit' && accountingIntegration.value.sync_invoices
})
const sectionContent = computed(() => {
  if (props.order.date_cancelled) {
    return [{
      key: 'cancelled',
      icon: 'mdi-close',
      include: true,
      success: false,
      text: 'Order is cancelled'
    }]
  }

  if (props.order.requiresInvoiceApproval) {
    return [{
      key: 'approval',
      icon: 'mdi-check',
      include: true,
      success: false,
      text: 'No PO issued. Approving will send accounting a matching PO.'
    }]
  }

  const sections = [
    ...(accountingErrors.value.map(e => ({ key: e.key, include: true, success: false, text: e.text }))),
    { key: 'po', include: !syncInvoices, success: poOk.value, text: poText.value },
    { key: 'rec', include: true, success: receivedOk.value, text: receivedText.value },
  ]

  return sections.filter(section => section.include).map(section => ({
    ...section,
    icon: section.success ? 'mdi-check' : 'mdi-close',
  }))
})

const warningCount = computed(() => sectionContent.value.filter(section => !section.success).length)

const getItemTotal = (item: LineItem) => {
  if (item.rental_price?.price) {
    return Number(item.rental_price.price) * Number(item.quantity)
  }

  return Number(item.unit_price) * Number(item.quantity)
}

const { $api, $error } = useNuxtApp()

onMounted(async () => {
  if (props.order.jobsite?.accounting_id && !props.order.requiresInvoiceApproval) {
    try {
      accountingIntegration.value = await $api.v1.companies.accountingIntegration(props.order.jobsite.company as number)
    }
    catch (error) {
      $error.report(error)
    }
  }
})

watchEffect(() => {
  emit('update:blocking-errors', accountingErrors.value)
})
</script>

<style scoped lang="scss">
.rex-icon {
  margin-top: -3px;
}
</style>
