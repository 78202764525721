<template>
  <v-row>
    <v-col cols="7">
      <div v-text="vendorName" />
      <div v-if="contact">
        <div>
          {{ contact.first_name }} {{ contact.last_name }}
        </div>
        <div v-text="contact.phone_number" />
        <div>
          <a :href="`mailto:${contact.email}`" v-text="contact.email" />
        </div>
      </div>
    </v-col>
    <v-col cols="5">
      <v-row dense>
        <v-col class="text-mid-grey">
          Issue Date
        </v-col>
        <v-col class="text-right">
          {{ dateYear(dateIssued) }}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-mid-grey">
          Due Date
        </v-col>
        <v-col class="text-right">
          {{ dateYear(dateDue) }}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-mid-grey">
          PO
        </v-col>
        <v-col class="text-mid-light-grey text-right">
          {{ poNumber }}
        </v-col>
      </v-row>
      <div class="heavy-border my-3" />
      <v-row align="center" dense>
        <v-col class="text-mid-grey">
          Subtotal
        </v-col>
        <v-col class="font-weight-bold qtm-h3 text-right">
          {{ currency(totalPrice) }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { currency, dateYear } from '~/models/filters'

export default {
  name: 'invoice-information-table',
  components: { },
  props: {
    contact: {
      type: Object,
      default: undefined
    },
    dateIssued: {
      type: [Object, Number],
      default: undefined
    },
    dateDue: {
      type: [Object, Number],
      default: undefined
    },
    poNumber: {
      type: String,
      default: 'No PO Issued'
    },
    totalPrice: {
      type: [Number, String],
      default: undefined,
    },
    vendorName: {
      type: String,
      default: ''
    },
  },
  methods: {
    currency(value) {
      return currency(value)
    },
    dateYear(value) {
      return dateYear(value)
    },
  }
}
</script>

<style lang="scss" scoped>
.heavy-border {
  border-bottom: 4px solid rgb(var(--v-theme-secondary));
}
</style>
