<template>
  <v-textarea
    ref="input"
    v-bind="$attrs"
    :bg-color="backgroundColor"
    clear-icon="mdi-close"
    :color="color"
    :error-messages="activeErrors"
    :maxlength="maxlength"
    :variant="variant"
    @blur="showErrors = true"
    @focus="hideErrors"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
      <slot v-bind="slotData" :key="name" :name="name" />
    </template>
  </v-textarea>
</template>

<script setup lang="ts">
import { MAX_TEXTAREA_LENGTH } from '@/constants'

export interface Props {
  backgroundColor?: string
  color?: string
  errorMessages?: string | string[]
  maxlength?: number
  variant?: 'outlined' | 'plain'
}

const props = withDefaults(defineProps<Props>(), {
  backgroundColor: 'white',
  color: 'interactive',
  errorMessages: undefined,
  maxlength: MAX_TEXTAREA_LENGTH,
  variant: 'outlined',
})

const showErrors = ref(true)
const activeErrors = computed(() => (showErrors.value ? props.errorMessages : undefined))

const hideErrors = () => {
  if (!props.errorMessages) {
    showErrors.value = false
  }
}

const input = ref<any>(null)
const focus = () => input.value?.focus()

defineExpose({ focus })
</script>
