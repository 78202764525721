<template>
  <v-avatar class="user-avatar" :color="finalColor" :size="avatarSize">
    <span :class="textColorClass" :style="fontSize">
      {{ user.first_name[0] }}{{ user.last_name[0] }}
    </span>
  </v-avatar>
</template>

<script>
const sizes = {
  md: { avatar: 24, font: 10 },
  lg: { avatar: 40, font: 16 },
  xl: { avatar: 48, font: 20 },
}

const colorList = [
  'interactive',
  'light-grey',
  'light-teal',
  'mid-grey',
  'primary',
  'primary-darken-1',
  'primary-lighten-1',
  'sand',
  'secondary',
  'secondary-lighten-1',
]

export default {
  name: 'user-avatar',
  props: {
    color: {
      type: String,
      default: 'secondary'
    },
    size: {
      type: String,
      default: 'md'
    },
    textColor: {
      type: String,
      default: 'white'
    },
    user: {
      type: Object,
      required: true
    },
    useColorByUser: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    avatarSize() {
      const size = sizes[this.size] || sizes.md

      return size.avatar
    },
    finalColor() {
      if (this.useColorByUser) {
        return this.colorByUser
      }

      return this.color
    },
    fontSize() {
      const size = sizes[this.size] || sizes.md

      return `font-size: ${size.font}px`
    },
    colorByUser() {
      const userId = this.user.is_staff ? 0 : this.user.id
      return colorList[userId % colorList.length]
    },
    textColorClass() {
      if (this.useColorByUser) {
        return null
      }

      return `text-${this.textColor}`
    }
  }
}
</script>

<style lang="scss" scoped>
.user-avatar {
  font-weight: 700;
}
</style>
