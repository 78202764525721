<template>
  <scroll-card v-bind="$attrs">
    <template v-slot:title-append>
      <slot name="title-append" />
    </template>
    <template v-slot:subtitle>
      <project-ref v-if="project" class="mt-2" :project="project" />

      <div class="qtm-body-large text-mid-grey">
        <slot name="reference-name">
          {{ referenceName }}
        </slot>
      </div>
    </template>
    <template v-slot:actions>
      <slot name="actions" />
    </template>
    <v-row class="mt-12" dense>
      <v-col cols="7">
        <div v-if="supplier" class="d-flex align-center mb-4">
          <contact-menu-card
            content-class="ml-16"
            :email="contactEmail"
            :ext="contactExt"
            :mobile="contactMobile"
            :name="contactName"
            :phone="contactPhone"
          >
            <template v-slot:activator="{ props }">
              <div v-bind="props" class="d-flex align-center">
                <vendor-avatar :vendor="supplier" />
                <div class="ml-4">
                  <div class="qtm-overline text-mid-grey text-uppercase">
                    Supplier
                  </div>
                  <div class="text-dark-grey mb-2 qtm-body" v-text="supplier.name" />
                </div>
                <slot name="supplier-append" />
              </div>
            </template>
            <qtm-address
              :address="supplier.address"
              :city="supplier.city"
              :province="supplier.province"
            />
          </contact-menu-card>
        </div>
        <div v-if="orderedBy" class="d-flex align-center">
          <user-contact overline="Ordered By" :user="orderedBy" />
        </div>
      </v-col>
      <v-col cols="5">
        <div v-for="item in summaryItems" :key="item.label" class="d-flex justify-space-between mb-1 qtm-body">
          <div class="text-mid-grey" v-text="item.label" />
          <slot :name="`summary-item-${item.label.toLowerCase().replace(' ', '-')}`">
            <div class="text-dark-grey" v-text="item.value" />
          </slot>
        </div>
        <div class="heavy-border my-4" />
        <div class="text-dark-grey d-flex font-weight-bold justify-space-between">
          <div>
            <span class="font-weight-bold qtm-body-large">Total</span>
            <span v-if="!totalTax">(excl. tax)</span>
          </div>
          <div class="font-weight-bold qtm-body-large">
            {{ currency(totalWithTax) }}
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="text-dark-grey qtm-body">
      <div class="heavy-border mb-2 mt-12" />

      <cost-summary-table
        :cost-codes="costCodes"
        :delivery-price="deliveryPrice"
        :force-total-spacer="forceTotalSpacer"
        :freight-cost-code="freightCostCode"
        :freight-tax="freightTax"
        :hover-item="hoverItem"
        :items="items"
        :pickup-price="pickupPrice"
        :readonly="readonly"
        :readonly-items="readonlyItems"
        :total-price="total"
        :total-tax="totalTax"
        :with-received="withReceived"
        @hover-end="$emit('hover-end', $event)"
        @hover-start="$emit('hover-start', $event)"
        @update:freight-cost-code="$emit('update:freight-cost-code', $event)"
      >
        <template v-slot:summary-costs-append="props">
          <slot name="summary-costs-append" v-bind="props" />
        </template>
        <template v-slot:total-cells>
          <slot name="total-cells" />
        </template>
      </cost-summary-table>
    </div>
    <div v-if="comments" class="text-dark-grey">
      <div class="font-weight-bold text-secondary text-uppercase" v-text="commentsTitle" />
      <div v-text="comments" />
    </div>
    <div class="mb-12">
      <attachment-preview
        v-for="attachment in attachments"
        :key="attachment.id"
        :attachment="attachment"
        class="mt-6"
        preview-on-click
        @preview="$emit('preview', attachment)"
      />
    </div>
    <div class="text-dark-grey d-flex">
      <slot name="bottom-append" />
    </div>
  </scroll-card>
</template>

<script>
import { currency } from '~/models/filters'
import AttachmentPreview from '@/components/attachments/attachment-preview.vue'
import ContactMenuCard from '@/components/contact-menu-card.vue'
import CostSummaryTable from '@/components/orders/cost-summary-table.vue'
import ProjectRef from '@/components/jobsites/project-ref.vue'
import ScrollCard from '@/components/scroll-card.vue'
import UserContact from '@/components/users/user-contact.vue'
import VendorAvatar from '@/components/vendors/vendor-avatar.vue'

export default {
  name: 'order-summary-card',
  components: {
    AttachmentPreview,
    ContactMenuCard,
    CostSummaryTable,
    ProjectRef,
    ScrollCard,
    UserContact,
    VendorAvatar,
  },
  props: {
    attachments: {
      type: Array,
      default: () => []
    },
    comments: {
      type: String,
      default: ''
    },
    commentsTitle: {
      type: String,
      default: 'Comments'
    },
    contact: {
      type: Object,
      default: undefined
    },
    costCodes: {
      type: Array,
      default: () => []
    },
    deliveryPrice: {
      type: [Number, String],
      default: 0
    },
    forceTotalSpacer: {
      type: Boolean,
      default: false
    },
    freightCostCode: {
      type: String,
      default: ''
    },
    freightTax: {
      type: Number,
      default: undefined
    },
    hoverItem: {
      type: Object,
      default: undefined
    },
    items: {
      type: Array,
      default: () => []
    },
    orderedBy: {
      type: Object,
      default: undefined
    },
    pickupPrice: {
      type: [Number, String],
      default: 0
    },
    project: {
      type: Object,
      default: undefined
    },
    readonly: {
      type: Boolean,
      default: false
    },
    readonlyItems: {
      type: Array,
      default: () => []
    },
    referenceName: {
      type: String,
      default: ''
    },
    summaryItems: {
      type: Array,
      default: () => []
    },
    supplier: {
      type: Object,
      default: undefined
    },
    total: {
      type: [Number, String],
      default: 0
    },
    totalTax: {
      type: [Number, String],
      default: undefined
    },
    withReceived: {
      type: Boolean,
      default: false
    },
  },
  emits: ['hover-end', 'hover-start', 'preview', 'update:freight-cost-code'],
  computed: {
    contactEmail() {
      return this.contact?.email
    },
    contactExt() {
      return this.contact?.phone_extension
    },
    contactMobile() {
      return this.contact?.phone_cell
    },
    contactName() {
      if (this.contact) {
        return `${this.contact?.first_name} ${this.contact?.last_name}`
      }
      return undefined
    },
    contactPhone() {
      return this.contact?.phone_number || this.contact?.phone
    },
    totalWithTax() {
      return this.total + (this.totalTax || 0)
    },
  },
  methods: {
    currency(value) {
      return currency(value)
    },
  }
}
</script>

<style lang="scss" scoped>
.heavy-border {
  border-bottom: 4px solid rgb(var(--v-theme-light-grey));
}
</style>
