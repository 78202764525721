import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import vuetifyConfig from '~/vuetifyConfig'

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify({
    components,
    directives,
    ...vuetifyConfig,
  })

  nuxtApp.vueApp.use(vuetify)
})
