<template>
  <qtm-content-block v-if="po || quote" title="Order Items">
    <purchase-order-items-table
      :boms="boms"
      :delivery-charge="deliveryCharge"
      :freight-cost-code="freightCostCode"
      :pickup-charge="pickupCharge"
      :items="items"
      :quotes="order?.quotes"
      :tax="tax"
      :total-price="totalPrice"
      :with-received="withReceived"
    />
    <qtm-input-label v-if="po?.comments" class="mt-6" label="Comment for Supplier">
      <div v-text="po.comments" />
    </qtm-input-label>
  </qtm-content-block>
</template>

<script setup lang="ts">
import type { Order, PurchaseOrder } from '@quotetome/materials-api'
import PurchaseOrderItemsTable from '@/components/purchase-orders/purchase-order-items-table.vue'

export interface Props {
  boms?: any[]
  order: Order
  po?: PurchaseOrder
}

const props = withDefaults(defineProps<Props>(), {
  boms: () => [],
  po: undefined,
})

const deliveryCharge = computed(() => {
  return props.po?.latest_change_order?.delivery_charge ?? quote.value?.delivery_charge
})
const quote = computed(() => (props.order.isDraft ? props.order.quotes[0] : undefined))
const freightCostCode = computed(() => {
  return props.po?.latest_change_order?.freight_cost_code ?? props.order.freight_cost_code
})
const items = computed<any[]>(() => props.po?.poskus ?? quote.value?.skus ?? [])
const pickupCharge = computed(() => {
  return props.po?.latest_change_order?.pickup_charge ?? quote.value?.pickup_charge
})
const tax = computed(() => props.po?.total_tax)
const totalPrice = computed(() => props.po?.total_price ?? quote.value?.total_price)
const withReceived = computed(() => props.po?.poskus.some(item => Number(item.received_quantity) !== 0))
</script>
