<template>
  <qtm-autocomplete
    v-bind="$attrs"
    :item-title="userText"
    :items="users"
    :loading="loading"
  />
</template>

<script setup lang="ts">
import { User } from '@quotetome/materials-api'

export interface Props {
  readonly?: boolean
}
const props = defineProps<Props>()

const users = ref<User[]>([])
const loading = ref(false)

const { $api, $error } = useNuxtApp()
const fetchUsers = async () => {
  if (props.readonly) {
    return
  }

  loading.value = true
  try {
    users.value = await $api.v1.users.list()
    users.value.sort(
      (a, b) => {
        if (a.company && b.company) {
          return a.company!.name!.localeCompare(b.company!.name!)
          || a.first_name!.localeCompare(b.first_name!)
          || a.last_name!.localeCompare(b.last_name!)
        }

        return a.first_name!.localeCompare(b.first_name!)
          || a.last_name!.localeCompare(b.last_name!)
      }
    )
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

const userText = (u: User) => {
  const name = `${u.first_name} ${u.last_name}`

  if (u.company) {
    return `${u.company.name} - ${name}`
  }

  return name
}

onMounted(fetchUsers)
</script>
